import React, { useState, useEffect } from "react";
import { Loader } from "../../Elements/Loader/Loader";
import {
  fetchStudentResume,
  updateStudentResumeStatus,
} from "../../../functions/admin/HR Management/hr_management";
import { Checkbox } from "../../Elements/Checkbox";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import toast from "react-hot-toast";
import { useAppSelector } from "../../..";
import { createSearchParams } from "react-router-dom";

export const ViewResume = ({ id }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any =
    useSharedLogic();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({} as any);
  const [checked, setChecked] = useState("Accepted");
  const [textData, setTextData] = useState("");

  useEffect(() => {
    (async () => {
      const response = await fetchStudentResume(id);
      // console.log(signInData?.data[0]?.uid)
      if (response) {
        setData(response);
        setChecked(response?.resume_status===null?checked:response?.resume_status);
        setTextData(response?.resume_rejection_comments===null?textData:response?.resume_rejection_comments);
      }
    })();
  }, []);

  const CheckValue = (label: any) => {
    setChecked(label);
  };

  const sendAPICall = async () => {
    setIsLoading(true);
    const resume_status = checked;
    const resume_rejection_comments = textData;
    const logs_data = {
      user_id: signInData?.data[0]?.uid,
      training_id: id,
      comments: resume_rejection_comments,
    }
    const apiCallResponse = await updateStudentResumeStatus(id, resume_status, resume_rejection_comments, logs_data);
    if (apiCallResponse) {
      navigate({
        pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        search: createSearchParams({
          id: `training_data`,
        }).toString(),
      });
      toast.success("Resume Status Updated Successfully");
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <div className="bg-white w-[95%] mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6 px-2 flex flex-col items-center justify-center space-y-6">
          <div className="w-full">
            <h1 className="text-2xl font-bold mb-4 px-1 text-blue-400">
              Resume Preview
            </h1>
            {data?.student?.resume?.url ? (
              <iframe
                src={data.student.resume.url}
                title="Resume Preview"
                className="w-full h-[600px] border rounded"
                loading="lazy"
              ></iframe>
            ) : (
              <p className="text-red-500 px-1">
                No resume available for preview.
              </p>
            )}
          </div>
          <div className="flex items-center gap-2 px-2">
            <p className="text-[#707070] text-[18px] font-Roboto font-medium">
              Resume Status:
            </p>
            <div className="flex items-center justify-around gap-2">
              <Checkbox
                label={"Accepted"}
                checked={checked}
                CheckValue={CheckValue}
                textStyle={"text-[#707070] text-[15px]"}
                show={true}
              />
              <Checkbox
                label={"Rejected"}
                checked={checked}
                CheckValue={CheckValue}
                textStyle={"text-[#707070] text-[15px]"}
                show={true}
              />
            </div>
          </div>
          {checked==="Rejected" && <div className="space-y-0.5 flex flex-col w-full relative">
            <label className="text-[#707070] text-[20px] font-Roboto font-[500]">
              Reason For Rejection
            </label>
            <textarea
              name="textData"
              onChange={(e:any)=>setTextData(e.target.value)}
              placeholder="Here you can write"
              value={textData}
              autoFocus
              className="min-h-[150px] px-2 py-2 border-[#707070] border-[0.5px] rounded-[3px]"
            >
              {textData}
            </textarea>
          </div>}
          <button
            type={"button"}
            onClick={sendAPICall}
            className={`cursor-pointer bg-[#036AD1] rounded-[23px] p-1 px-6 py-1 text-white text-[17px] font-Roboto font-normal`}
          >
            Proceed
          </button>
        </div>
      )}
    </>
  );
};
