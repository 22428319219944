import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { CardDesign } from "./CardDesign";

export const Batch = ({ DashboardData }: any) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },

      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  const batchChart = {
    labels: (DashboardData[0].batchChart || []).map((d: any) => d.name),
    datasets: [
      {
        label: "Count",
        backgroundColor: "#036AD1",
        data: (DashboardData[0].batchChart || []).map((d: any) => d.count),
      },
    ],
  };

  return (
    <div className="w-full">
      <div className="bg-[#F9FCFF] w-full shadow-[#00000029_0px_3px_8px] rounded-[8px]">
        {DashboardData.map((item: any, index: number) => (
          <div key={index}>
            {/* Title */}
            <h1 className="font-Roboto font-[500] text-[18px] md:text-[24px] bg-[#FBFBFB] py-4 px-4">
              {item.batchTitle}
            </h1>

            {/* Responsive Layout */}
            <div className="flex flex-col md:flex-row items-center gap-4 p-4 p-4 bg-white">
              {/* Left Section: Bar Chart */}
              <div className="w-full md:w-[30%] flex items-center justify-center">
                <div className="w-full md:w-8/12 mx-auto py-2">
                  <Bar data={batchChart} options={options} height="200px" />
                </div>
              </div>

              {/* Right Section: Cards Grid */}
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-2 w-full md:w-[70%]">
                {item.Batch.map((data: any, cardIndex: number) => (
                  <CardDesign key={cardIndex} data={data} />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
