import React from "react";
import { CardDesign } from "./CardDesign";
import { Bar } from "react-chartjs-2";

// export const Admissions = ({ DashboardData, response }: any) => {
//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         display: false,
//       },
//     },

//     scales: {
//       x: {
//         grid: {
//           display: false,
//         },
//       },

//       y: {
//         grid: {
//           display: false,
//         },
//       },
//     },
//   };

//   const data = {
//     labels: (DashboardData[0].graph || []).map((d: any) => d.name),
//     datasets: [
//       {
//         label: "Count",
//         backgroundColor: "#036AD1",
//         data: (DashboardData[0].graph || []).map((d: any) => d.count),
//       },
//     ],
//   };

//   return (
//     <div className="">
//       <div></div>
//       <div className="bg-[#F9FCFF] w-11/12 mx-auto shadow-[#00000029_0px_3px_8px] rounded-[8px]">
//         {DashboardData.map((item: any) => {
//           return (
//             <div className="py-4">
//               <div className="w-11/12 mx-auto space-y-4">
//                 <h1 className="font-Roboto font-[500] text-[24px]">
//                   {item.title}
//                 </h1>
//                 <div className="flex items-center justify-between">
//                   <div className="w-[50%]">
//                     <Bar data={data} options={options} height="150px" />
//                   </div>
//                   <div className="w-[42%] grid grid-cols-2 gap-3">
//                     {item.admissionDetails.map((item: any) => {
//                       return <CardDesign data={item} />;
//                     })}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

export const Admissions = ({ DashboardData, response }: any) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: (DashboardData[0].graph || []).map((d: any) => d.name),
    datasets: [
      {
        label: "Count",
        backgroundColor: "#036AD1",
        data: (DashboardData[0].graph || []).map((d: any) => d.count),
      },
    ],
  };

  return (
    <div className="w-full">
      <div className="bg-[#F9FCFF] w-full shadow-[#00000029_0px_3px_8px] rounded-[8px]">
        {DashboardData.map((item: any, index: number) => (
          <div key={index} className="py-4">
            <div>
              <h1 className="font-Roboto font-[500] text-[18px] md:text-[24px] bg-[#FBFBFB] py-4 px-4">
                {item.title}
              </h1>
              {/* Responsive Layout */}
              <div className="flex flex-col md:flex-row items-center md:items-start justify-between gap-4 p-4 bg-white">
                {/* Bar Chart - Stacked on small screens, side-by-side on large screens */}
                <div className="w-full md:w-11/12" style={{ height: "300px" }}>
                  {data && <Bar data={data} options={options} />}
                </div>

                {/* Cards - Stacked below graph on small screens */}
                <div className="w-full md:w-[42%] grid grid-cols-2 gap-3">
                  {item.admissionDetails.map(
                    (cardItem: any, cardIndex: number) => (
                      <CardDesign key={cardIndex} data={cardItem} />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
