import { useAppSelector } from "../..";
import { useParams } from "react-router-dom";

export const FunctionForPrivileges = () => {
  const params = useParams();
  const routeNameForHome = params.dashboardScreen;
  const routeNameForSubSection = params.subsection;
  const { userRoles } = useAppSelector(({ roles }: any) => roles);

  const PageOrderData = userRoles?.selectedRoles?.filter(
    (pre: any) => pre.idkey === routeNameForHome
  );
  const ForSubDomains = PageOrderData?.[0]?.submenus?.filter(
    (sub: any) => sub.idkey === routeNameForSubSection
  );
  const MainMenu = PageOrderData?.[0]?.privileges;
  const SubMenu = ForSubDomains?.[0]?.privileges;
  const PageOrderDataOnlyForBatch = userRoles?.selectedRoles?.filter(
    (pre: any) => pre.idkey === "batch_management"
  );
  const MainMenuOnlyForBatch = PageOrderDataOnlyForBatch?.[0]?.privileges;

//   const PageOrderDataNewWithSub: any = userRoles?.selectedRoles?.filter((fil: any) => fil?.privileges?.length > 0 || (fil?.submenus?.length>0 && fil?.submenus?.map((sub:any, index:number)=> console.log(sub?.privileges, index, sub?.idkey, "A_SUB"))));
//   console.log(PageOrderDataNewWithSub, "PageOrderDataNewWithSub");
//   const CheckMainMenuPresent = PageOrderDataNewWithSub?.map((sam:any)=> sam?.idkey)
//   console.log(CheckMainMenuPresent, "CheckMainMenuPresent");

const PageOrderDataNew: any = userRoles?.selectedRoles?.filter(
  (fil: any) => 
    (isValidPrivilege(fil?.privileges)) ||
    (fil?.submenus?.some((sub: any) => isValidPrivilege(sub?.privileges)))
);

  console.log(PageOrderDataNew, "PageOrderData1");
  const ForSubDomainsNew = PageOrderDataNew?.filter(
    (fil: any) => fil?.submenus.length > 0 && fil
  );
  console.log(ForSubDomainsNew, "ForSubDomainsNew", userRoles)
  const mapMainComponent = PageOrderDataNew?.map((main: any) => main?.idkey);
//   console.log(mapMainComponent, "MAIN")
    const mapSubComponent = ForSubDomainsNew?.map((main: any) =>
    main?.submenus
        ?.filter((sub: any) => sub?.privileges?.length > 0 && !sub?.privileges?.includes('not applicable'))
        .map((sub: any) => sub?.idkey)
    );
//   console.log(mapSubComponent, "map_SUB")
  const concatenatedSubArray = mapSubComponent?.flatMap((innerArray: any) =>
    innerArray.flatMap((item: any) => item?.split(", "))
  );
//   console.log(concatenatedSubArray, "SUB")

  const allowAllActions = () => {
    return MainMenu?.includes("all") || MainMenu?.includes("view");
  };

  const allowOnlyActions = (sub: string) => {
    if (MainMenu?.includes("all") || SubMenu?.includes("all")) {
      return true;
    } else if (SubMenu?.includes("view") && SubMenu?.includes(sub)) {
      return true;
    } else {
      return false;
    }
  };

  return {
    MainMenu,
    SubMenu,
    allowAllActions,
    allowOnlyActions,
    mapMainComponent,
    concatenatedSubArray,
    ForSubDomainsNew,
    MainMenuOnlyForBatch,
  };
}

    const isValidPrivilege = (privileges:any) => {
        return privileges.length > 0 && !privileges.includes('not applicable');
    }

// const CheckSampleFunction = () => {
//     const { userRoles } = useAppSelector(({ roles }: any) => roles);
//     const PageOrderData = userRoles?.selectedRoles?.filter((fil:any)=> fil?.privileges || fil?.privileges.length > 0);
//     const ForSubDomains = PageOrderData[0]?.submenus?.filter((fil: any) => fil?.privileges || fil?.privileges.length > 0);
//     const mapMainComponent = PageOrderData?.map((main: any) => main?.idkey)
//     console.log(mapMainComponent, "MAIN")
//     const mapSubComponent = ForSubDomains?.map((main: any) => main?.idkey)
//     console.log(mapSubComponent, "SUB")
// }