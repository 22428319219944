import { useState, useEffect } from "react";
import {
  getTrainerIdBasedStudents,
  getBatchByIndividualID,
} from "../../../functions/admin/Action center/Trainer Payments/trainerPayment";
import { Loader } from "../../Elements/Loader/Loader";
import { ReadData } from "../../Elements/ReadData";
import {
  viewCompanyManagementTableData,
  updateCompanyManagementTableData,
  createTemporaryUser,
  updateHrUserStatus,
  updateHrUserIsAdmin,
} from "../../../functions/admin/HR Management/hr_management";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { createSearchParams } from "react-router-dom";
import { dateFormat } from "../../../Services/CommonFunctions";
import AWS from "aws-sdk";
import toast from "react-hot-toast";

AWS.config.update({
  accessKeyId: "AKIAVCSSLR6C2EQPAO6F",
  secretAccessKey: "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",
  region: "ap-south-1",
});

type CompanyData = {
  id: number;
  created_at: string;
  name: string;
  location: string[];
  salary_range:
    | "> 2LPA"
    | "2 - 4 LPA"
    | "4 - 6LPA"
    | "6 - 8 LPA"
    | "8 - 10 LPA"
    | "< 10LPA";
  company_size:
    | "Larger Sized(1000 Employees)"
    | "Mid Sized(100 to 999 Employees)"
    | "Small SIzed(10 to 99 Employees)"
    | "Startup (< 10 Employees)";
  recruiting_roles: string[];
  hiring_count_per_year: number;
  status: any;
  about_us: string;
  rejection_message?: string; // Optional field for rejection message
  approval_status: "Accept" | "Reject" | "Inprogress";
};

export const ViewProfile = ({ id }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any =
    useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<CompanyData>({} as any);
  const [hr_details, setHr_details] = useState([] as any);
  const [isRejected, setIsRejected] = useState(false); // Tracks if "Reject" was clicked
  const [errors, setErrors] = useState<Record<string, string>>({}); // Tracks field validation errors
  const headers = [
    "S.No",
    "Name",
    "Email",
    "Phone Number",
    // "Location",
    "Status",
    "HR Position",
    "Change Position",
  ];
  const footerStyle = `text-[#686868] 2xl:text-[14px] lg:text-[12px] text-[10px] font-Roboto font-[400] text-center whitespace-nowrap truncate`;
  const currentDate: any = new Date();
  const ses = new AWS.SES({
    apiVersion: dateFormat(currentDate, "YYYY-MM-DD"),
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response: any = await viewCompanyManagementTableData(id);
      // console.log(response, "response")
      if (response) {
        setFormData(response?.CompanyData);
        setHr_details(response?.HrData);
      }
      setIsLoading(false);
    })();
  }, []);

  // Handle input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: keyof CompanyData
  ) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: "", // Clear error for the field being edited
    }));
  };

  // Validate all required fields
  const validateFields = () => {
    const newErrors: Record<string, string> = {};
    Object.keys(formData).forEach((key) => {
      if (
        key !== "rejection_message" &&
        !formData[key as keyof CompanyData]?.toString().trim()
      ) {
        newErrors[key] = `${key.replace(/_/g, " ")} is required`;
      }
    });

    if (isRejected && !formData.rejection_message?.trim()) {
      newErrors.rejection_message = "Rejection message is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const generateApprovalEmail = (tempPassword: any, siteUrl: string) => ({
    Subject: {
      Data: "Your Company Registration is Approved",
    },
    Body: {
      Html: {
        Data: `
          <h2>! Your company registration has been successfully approved. We are excited to welcome you to the <strong>FITA Academy Portal</strong>.</h2>
          <p>Here are your login details:</p>

          <p>Email: <strong>${hr_details?.[0]?.email}</strong></p>
          <p>Temporary Password: <strong>${tempPassword}</strong></p>

          <p>You can access your account here at: ${siteUrl}</p>
          <p><strong>Important:</strong> For security reasons, please change your password after logging in. You can update it anytime from your profile settings.</p>
          <p>If you need any assistance, feel free to contact our <strong>support team</strong>. We’re happy to help!</p>

          <p><strong>Best Regards,</strong></p>
          <p><strong>FITA Academy Team</strong></p>
        `,
      },
    },
  });

  const generateRejectionEmail = (reason: string) => ({
    Subject: {
      Data: "Company Registration Update",
    },
    Body: {
      Text: {
        Data: `
          <h2>We regret to inform you that your company registration request has been <strong>rejected</strong>.</h2>

          <p>Reason: ${reason}</p>

          <p>If you believe this was a mistake or have any questions, please feel free to contact our support team for further clarification.</p>

          <p><strong>Best Regards,</strong></p>
          <p><strong>FITA Academy Team</strong></p>
        `,
      },
    },
  });

  const sendEmail = async (toEmail: any, emailContent: any) => {
    const params: any = {
      Source: "zeftdmmanager@gmail.com",
      Destination: {
        ToAddresses: [toEmail],
      },
      Message: emailContent,
    };

    try {
      await ses.sendEmail(params).promise();
      return true;
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  };

  // Approve action
  const handleApproval = async () => {
    if (!validateFields()) {
      toast.error("Please fill in all required fields correctly.");
      return;
    }
  
    try {
      const { data, tempPassword } = await createTemporaryUser(hr_details?.[0]?.email);
      console.log(data, tempPassword, "1234");
  
      const PathFinder = () => {
        let env;
        switch (true) {
          case document.location.hostname.includes("uat"):
            env = "https://fita-admin-uat.web.app/";
            break;
          case document.location.hostname.includes("test"):
            env = "http://localhost:3000/";
            break;
          case document.location.hostname.includes("oneapp"):
            env = "https://oneapp.fitaacademy.in/";
            break;
          default:
            env = "http://localhost:3000/";
            break;
        }
        return env;
      };
  
      const emailContent = await generateApprovalEmail(tempPassword, PathFinder());
      console.log(emailContent, "TEST --1", hr_details?.[0]?.email);
      
      await sendEmail(hr_details?.[0]?.email, emailContent);
  
      const sendApprovedData = await updateCompanyManagementTableData(
        id,
        { ...formData, approval_status: "Accept" },
        hr_details,
        data?.user?.id
      );
  
      if (sendApprovedData) {
        navigate({
          pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        });
      }
    } catch (error:any) {
      console.error("Approval process failed:", error);
      if (!error.message.includes("already been registered")) {
        toast.error("Failed to approve the user. Please try again.");
      }
    }
  };

  // const handleApproval = async () => {
  //   if (validateFields()) {
  //     const { data, tempPassword } = await createTemporaryUser(
  //       hr_details?.[0]?.email
  //     );
  //     console.log(data, tempPassword, "1234");
  //     const PathFinder = () => {
  //       let env;
  //       switch (true) {
  //         case document.location.hostname.includes("uat"):
  //           env = "https://fita-admin-uat.web.app/";
  //           break;
  //         case document.location.hostname.includes("test"):
  //           env = "http://localhost:3000/";
  //           break;
  //         case document.location.hostname.includes("oneapp"):
  //           env = "https://oneapp.fitaacademy.in/";
  //           break;
  //         default:
  //           env = "http://localhost:3000/";
  //           break;
  //       }
  //       return env;
  //     };
  //     const emailContent = await generateApprovalEmail(
  //       tempPassword,
  //       PathFinder()
  //     );
  //     console.log(emailContent, "TEST --1")
  //     await sendEmail(hr_details?.[0]?.email, emailContent);
  //     const sendApprovedData = await updateCompanyManagementTableData(
  //       id,
  //       { ...formData, approval_status: "Accept" },
  //       hr_details,
  //       data?.user?.id
  //     );
  //     if (sendApprovedData) {
  //       navigate({
  //         pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
  //       });
  //     }
  //   } else console.log("Something went wrong");
  // };

  // Reject action
  
  const handleRejection = () => {
    if (validateFields()) {
      setIsRejected(true);
    }
  };

  // Switch back to Approval
  const handleSwitchToApproval = () => {
    setIsRejected(false);
    setFormData({ ...formData, rejection_message: "" }); // Clear rejection message
  };

  // Submit for rejection
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateFields()) {
      if (formData?.rejection_message && formData?.rejection_message !== "") {
        const sendRejectionEmail = await generateRejectionEmail(
          formData?.rejection_message || ""
        );
        const emailSending = await sendEmail(
          hr_details?.[0]?.email,
          sendRejectionEmail
        );
        if (emailSending) {
          navigate({
            pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
          });
        }
      }
    }
  };

  const handleToggleStatus = async (index: number, user_id: number) => {
    const updatedHrData = [...hr_details];
    const status_hr = !updatedHrData[index].status_hr;
    const pushData = await updateHrUserStatus(user_id, status_hr);
    if (pushData) {
      updatedHrData[index].status_hr = !updatedHrData[index].status_hr;
      setHr_details(updatedHrData);
    }
  };

  // Handle change position dropdown
  const handleChangePosition = async (
    index: number,
    value: string,
    user_id: number
  ) => {
    const is_admin = value === "Make as Admin" ? true : false;
    const pushData = await updateHrUserIsAdmin(user_id, is_admin);
    if (pushData) {
      const updatedHrData = [...hr_details];
      updatedHrData[index].is_admin = value === "Make as Admin";
      setHr_details(updatedHrData);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-11/12"} />
      ) : (
        <div className="bg-white w-11/12 mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6 min-h-[400px] max-h-[500] scroll-smooth overflow-y-scroll scrollbar-hide">
          <div className="flex gap-10 px-6">
            <ReadData
              head={"Profile Created Date"}
              body={formData?.created_at}
            />
          </div>
          <form
            onSubmit={handleSubmit}
            className="p-6 space-y-4 rounded-lg w-[70%]"
          >
            <h2 className="text-xl font-semibold text-gray-800">
              Company Details
            </h2>
            {Object.keys(formData).map((key) => {
              if (
                key === "id" ||
                key === "created_at" ||
                key === "rejection_message" ||
                key === "approval_status"
              ) {
                return null; // Skip these keys
              }

              return (
                <div key={key} className="flex flex-col space-y-1">
                  <label
                    htmlFor={key}
                    className="text-sm font-medium text-gray-700 capitalize"
                  >
                    {key.replace(/_/g, " ")}
                  </label>

                  {key === "status" ? (
                    <select
                      id={key}
                      value={formData.status}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          status: e.target.value,
                        })
                      }
                      className={`w-full px-3 py-2 text-sm border ${
                        errors[key]
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-blue-500"
                      } rounded-lg focus:outline-none`}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  ) : key === "about_us" ? (
                    // Textarea for 'about_us'
                    <textarea
                      id={key}
                      value={formData[key as keyof CompanyData]}
                      onChange={(e) =>
                        handleChange(e, key as keyof CompanyData)
                      }
                      className={`w-full px-3 py-2 text-sm border ${
                        errors[key]
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-blue-500"
                      } rounded-lg focus:outline-none`}
                      rows={4}
                    />
                  ) : (
                    // Default input field
                    <input
                      id={key}
                      type="text"
                      value={formData[key as keyof CompanyData]}
                      onChange={(e) =>
                        handleChange(e, key as keyof CompanyData)
                      }
                      className={`w-full px-3 py-2 text-sm border ${
                        errors[key]
                          ? "border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:ring-blue-500"
                      } rounded-lg focus:outline-none`}
                      disabled={
                        // key === "location" ||
                        key === "recruiting_roles" ||
                        key === "salary_range" ||
                        key === "company_size"
                      }
                    />
                  )}

                  {/* Error Message */}
                  {errors[key] && (
                    <p className="text-sm text-red-500">{errors[key]}</p>
                  )}
                </div>
              );
            })}

            {/* Conditional Rejection Message */}
            {isRejected && (
              <div className="flex flex-col space-y-1">
                <label
                  htmlFor="rejection_message"
                  className="text-sm font-medium text-gray-700"
                >
                  Rejection Message
                </label>
                <textarea
                  id="rejection_message"
                  value={formData.rejection_message || ""}
                  onChange={(e) => handleChange(e, "rejection_message")}
                  className={`w-full px-3 py-2 text-sm border ${
                    errors.rejection_message
                      ? "border-red-500 focus:ring-red-500"
                      : "border-gray-300 focus:ring-blue-500"
                  } rounded-lg focus:outline-none`}
                  rows={4}
                  placeholder="Provide a reason for rejection"
                />
                {errors.rejection_message && (
                  <p className="text-sm text-red-500">
                    {errors.rejection_message}
                  </p>
                )}
              </div>
            )}

            {/* Action Buttons */}
            {formData?.approval_status !== "Accept" && (
              <div className="flex space-x-4">
                {!isRejected ? (
                  <>
                    <button
                      type="button"
                      onClick={handleApproval}
                      className="flex-1 py-2 text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring focus:ring-green-500"
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      onClick={handleRejection}
                      className="flex-1 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring focus:ring-red-500"
                    >
                      Reject
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={handleSwitchToApproval}
                      className="flex-1 py-2 text-white bg-gray-600 rounded-lg hover:bg-gray-700 focus:ring focus:ring-gray-500"
                    >
                      Back to Approve
                    </button>
                    <button
                      type="submit"
                      className="flex-1 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-500"
                    >
                      Submit Rejection
                    </button>
                  </>
                )}
              </div>
            )}
          </form>
          <div className="w-11/12 mx-auto py-6 space-y-6">
            <h1 className="2xl:text-[20px] lg:text-[19px] md:text-[18px] font-[500] text-[#036AD1]">
              HR Details:
            </h1>
            <div className="border border-blue-100 rounded-md shadow-sm overflow-hidden">
              {/* Table */}
              <div className="w-full">
                {/* Table Header */}
                <div className="table w-full">
                  <div className="table-row bg-[#EFF7FF]">
                    {headers.map((head: string, index: number) => (
                      <div
                        key={index}
                        className={`table-cell py-3 text-[#686868] font-[500] text-[11px] lg:text-[13px] 2xl:text-[14px] text-center ${
                          index === 0
                            ? "w-[5%]"
                            : index === 2 || index === headers.length - 3
                            ? "w-[15%]"
                            : index === headers.length - 1
                            ? "w-[20%]"
                            : "w-[12%]"
                        }`}
                      >
                        {head}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="table w-full min-h-[200px] max-h-[400px] overflow-y-auto scrollbar-hide">
                  {hr_details?.length > 0 ? (
                    hr_details.map((data: any, index: number) => (
                      // console.log(data, "data"),
                      <div
                        key={index}
                        className={`table-row ${
                          index % 2 !== 0 ? "bg-[#EFF7FF]" : ""
                        }`}
                      >
                        {/* Index */}
                        <div
                          className={`${footerStyle} table-cell w-[5%] py-2`}
                        >
                          {index + 1}
                        </div>

                        {/* Name */}
                        <div
                          className={`${footerStyle} table-cell w-[12%] py-2`}
                        >
                          {data?.name}
                        </div>

                        {/* Email */}
                        <div
                          className={`${footerStyle} table-cell w-[15%] py-2`}
                        >
                          {data?.email}
                        </div>

                        {/* Phone Number */}
                        <div
                          className={`${footerStyle} table-cell w-[12%] py-2`}
                        >
                          {data?.phone_number}
                        </div>

                        {/* Location */}
                        {/* <div
                          className={`${footerStyle} table-cell w-[12%] py-2`}
                        >
                          {data?.location.join(", ")}
                        </div> */}

                        {/* Toggle Button for Status */}
                        <div className="table-cell w-[15%] py-2 text-center">
                          <button
                            onClick={() => handleToggleStatus(index, data?.id)}
                            className={`px-2 py-1 rounded 2xl:text-[14px] lg:text-[12px] text-[10px] ${
                              data?.status_hr
                                ? "bg-green-500 text-white"
                                : "bg-red-500 text-white"
                            }`}
                          >
                            {data?.status_hr ? "Active" : "Inactive"}
                          </button>
                        </div>
                        <div
                          className={`${footerStyle} table-cell w-[12%] py-2`}
                        >
                          {data?.is_admin ? "Admin" : "Member"}
                        </div>

                        {/* Dropdown for Change Position */}
                        <div className="table-cell w-[20%] py-2 text-center">
                          <select
                            value={
                              data?.is_admin
                                ? "Make as Admin"
                                : "Make as Member"
                            }
                            onChange={(e) =>
                              handleChangePosition(
                                index,
                                e.target.value,
                                data?.id
                              )
                            }
                            className="px-2 py-1 border rounded text-[#686868] text-[11px] lg:text-[12px]"
                          >
                            <option value="Make as Admin">Make as Admin</option>
                            <option value="Make as Member">
                              Make as Member
                            </option>
                          </select>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center min-h-[200px] text-[#686868] 2xl:text-[14px] lg:text-[12px] text-[10px] font-Roboto font-[400]">
                      No data Found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
