import { dateFormat } from "../../../../Services/CommonFunctions"
import { calculatePercentage, errorNotifier } from "../../../commonHelper"
import { TableNames } from "../../../config/Tables"
import { BulkUpdate, FetchData, UpdateData, BulkInsert } from "../../../crud"
import { PaymentComment, TrainerPaymentBatchInterface } from "./interface"
import { UserLogInterface } from "../../User Logs/interface"
import { submitUserLogs } from "../../User Logs/userLogs"

export const getCalculateTrainerPaymentTableData = async (UsersDetails: any) => {
    try {        
        const { data: batches, error } = await FetchData(TableNames.batches, [
            "id",
            "course:courses(course_name)",
            "schedule",
            "status",
            "start_date",
            "batch_payment_status",
            "start_time",
            "city:cities(city_name)",
            "duration",
            "batch_code",
            "batch_progress_in_hrs",
            "trainer:users(first_name, last_name, phone_number)",
            "branch_id",
            "branch:branches(branch_name, ownership)",
            "training:trainings(student:students(id,name),id)",
        ])
            .in("branch_id", UsersDetails?.branches);
        if (error) throw error
        const transformBatchData = (batch: any) => (
          {
            batch_id: batch?.id,
            batch_start_date: dateFormat(batch?.start_date, "DD MMM YYYY"),
            course_name: batch?.course?.course_name,
            batch_code: batch?.batch_code,
            trainer_name: `${batch?.trainer?.first_name || ""} ${
              batch?.trainer?.last_name || ""
            }`.trim(),
            trainer_mobile: batch?.trainer?.phone_number,
            status: batch?.status,
            branchName: batch?.branch?.branch_name,
            ownership: batch?.branch?.ownership,
            cityName: batch?.city?.city_name,
            batch_progress:
              calculatePercentage(
                batch?.batch_progress_in_hrs,
                batch?.duration
              ) || 0,
            batch_payment_status: batch?.batch_payment_status,
            schedule: batch?.schedule,
            student_list:
              batch?.training?.length > 0
                ? batch?.training?.map((tr: any) => tr?.student?.name)
                : [],
          }
        );
        const completedBatches = batches?.filter((batch: any) => batch.status === "Completed")
            .map(transformBatchData);
        const otherBatches = batches?.filter((batch: any) => batch.status !== "Completed")
            .map(transformBatchData);
        const trainingData = [...(completedBatches || []), ...(otherBatches || [])];
        console.log(trainingData, "trainingData")
        return trainingData;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getProcessTrainerPaymentTableData = async (UsersDetails: any) => {
    try {
        const { data: batches, error } = await FetchData(TableNames.batches, ["id", "course:courses(course_name)", "schedule", "status", "start_date", "batch_payment_status", "start_time", "city:cities(city_name)", "duration", "batch_code", "batch_progress_in_hrs", "trainer:users(first_name, last_name, phone_number)", "branch_id", "branch:branches(branch_name, ownership)", "training:trainings(student:students(id,name),id)"]).in("branch_id", UsersDetails?.branches).in("batch_payment_status",["Calculated", "Partially Calculated", "Fully Calculated", "Partially Processed", "Fully Processed"]);
        if (error) throw error
        const trainingData = batches?.map((batch: any) => ({
            batch_id: batch?.id, 
            batch_start_date: dateFormat(batch?.start_date, "DD MMM YYYY"),
            course_name: batch?.course?.course_name,
            batch_code: batch?.batch_code,
            trainer_name:batch?.trainer?.first_name + batch?.trainer?.last_name,
            trainer_mobile: batch?.trainer?.phone_number,
            status: batch?.status,
            branchName: batch?.branch?.branch_name,
            ownership: batch?.branch?.ownership,
            cityName: batch?.city?.city_name,
            batch_progress: calculatePercentage(batch?.batch_progress_in_hrs, batch?.duration) || 0,
            batch_payment_status: batch?.batch_payment_status,
            schedule: batch?.schedule,
            student_list: batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.name)
        }))
        return trainingData
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getProcessTrainerPaymentTableDataForPP = async (
  UsersDetails: any
) => {
  try {
    const { data: batches, error } = await FetchData(TableNames.batches, [
      "id",
      "course:courses(course_name)",
      "schedule",
      "status",
      "start_date",
      "batch_payment_status",
      "start_time",
      "city:cities(city_name)",
      "duration",
      "batch_code",
      "batch_progress_in_hrs",
      "trainer:users(first_name, last_name, phone_number)",
      "branch_id",
      "branch:branches(branch_name, ownership)",
      "training:trainings(student:students(id,name),id)",
    ])
      .in("branch_id", UsersDetails?.branches)
      .in("batch_payment_status", [
        "Calculated",
        "Partially Calculated",
        "Fully Calculated",
        "Partially Processed",
        "Fully Processed",
      ])
      .eq("batch_payment_status", "Partially Processed");
    if (error) throw error;
    const trainingData = batches?.map((batch: any) => ({
      batch_id: batch?.id,
      batch_start_date: dateFormat(batch?.start_date, "DD MMM YYYY"),
      course_name: batch?.course?.course_name,
      batch_code: batch?.batch_code,
      trainer_name: batch?.trainer?.first_name + batch?.trainer?.last_name,
      trainer_mobile: batch?.trainer?.phone_number,
      status: batch?.status,
      branchName: batch?.branch?.branch_name,
      ownership: batch?.branch?.ownership,
      cityName: batch?.city?.city_name,
      batch_progress:
        calculatePercentage(batch?.batch_progress_in_hrs, batch?.duration) || 0,
      batch_payment_status: batch?.batch_payment_status,
      schedule: batch?.schedule,
      student_list:
        batch?.training?.length > 0 &&
        batch?.training?.map((tr: any) => tr?.student?.name),
    }));
    return trainingData;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getProcessTrainerPaymentTableDataForFP = async (UsersDetails: any) => {
    try {
        const { data: batches, error } = await FetchData(TableNames.batches, ["id", "course:courses(course_name)", "schedule", "status", "start_date", "batch_payment_status", "start_time", "city:cities(city_name)", "duration", "batch_code", "batch_progress_in_hrs", "trainer:users(first_name, last_name, phone_number)", "branch_id", "branch:branches(branch_name, ownership)", "training:trainings(student:students(id,name),id)"]).in("branch_id", UsersDetails?.branches).in("batch_payment_status",["Calculated", "Partially Calculated", "Fully Calculated", "Partially Processed", "Fully Processed"]).eq("batch_payment_status", "Fully Processed");
        if (error) throw error
        const trainingData = batches?.map((batch: any) => ({
            batch_id: batch?.id, 
            batch_start_date: dateFormat(batch?.start_date, "DD MMM YYYY"),
            course_name: batch?.course?.course_name,
            batch_code: batch?.batch_code,
            trainer_name:batch?.trainer?.first_name + batch?.trainer?.last_name,
            trainer_mobile: batch?.trainer?.phone_number,
            status: batch?.status,
            branchName: batch?.branch?.branch_name,
            ownership: batch?.branch?.ownership,
            cityName: batch?.city?.city_name,
            batch_progress: calculatePercentage(batch?.batch_progress_in_hrs, batch?.duration) || 0,
            batch_payment_status: batch?.batch_payment_status,
            schedule: batch?.schedule,
            student_list: batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.name)
        }))
        return trainingData

    } catch (error) {
        return errorNotifier(error)
    }
}

export const getBatchByIdForTrainerPay = async (batchId: number) => {
    try {
        const { data: batch, error }: any = await FetchData(TableNames.batches, ["*", "course:courses(id,course_name)", "branch:branches(id,branch_name)", "city:cities(city_name)", "trainer_id", "batch_payment_status", "total_trainer_payment", "total_trainer_payment_comment"]).eq("id", batchId);
        const { data: sessions, error: err } = await FetchData(TableNames.sessions, ["batch:batches(id, course_id, branch_id)", "duration", "attendees"])
        const sessionByBatchId = sessions?.filter((el: any) => (el.batch.id === Number(batchId)))
        const batchData = batch.map((el: any) => ({...el, start_date: el.start_date, planned_date_of_completion: el.planned_date_of_completion}))

        const progress_in_hrs = sessionByBatchId?.reduce((accumulator: any, currentValue: any) => Number(accumulator) + Number(currentValue?.duration), 0)
        const batch_progress_in_percent = calculatePercentage(progress_in_hrs, batch[0].duration)

        const { data: trainer_data, error: trainer_error }: any = await FetchData(TableNames.batch_trainers, ["trainer:users(uid, first_name, last_name, phone_number, email, commercials)"]).eq("batch_id", batchId)
        const updatedTrainerData = trainer_data?.map((el: any) => ({ ...el, current_trainer: trainer_data[trainer_data.length - 1].trainer.uid === el.trainer.uid ? true : false }))

        // const { data: training_data, error: training_error } = await FetchData(TableNames.trainings, ["id", "student:students(uid, name, phoneNumber)", "balance_due", "trainer_payment", "student_payment_status", "trainer_payment_comment", "payment_details", "total_fee", "status", "payments:transactions(paid_amount)"]).eq("batch_id", batchId)
        // const oldBatchSessions: any = sessions?.filter((el: any) => (el.batch.course_id === batch[0].course.id && el.batch.branch_id === batch[0].branch.id && el.batch.id !== batchId))

        // const sessionData = sessions?.filter((el: any) => (el.batch.id === oldBatchSessions[0]?.batch?.id))
        // const student_data: any = training_data?.map((el: any) => {
        //     const paidAmount = el.payments.reduce((acc: number, curr_val: any) => acc + Number(curr_val.paid_amount), 0);
        //     const totalFee = el?.total_fee;
        //     const balanceDue = el?.balance_due
        //     const buffer_hours = sessionData?.filter((ele: any) => ele.attendees.includes(el.student?.uid))
        //         .reduce((acc: number, curr_val: any) => acc + Number(curr_val.duration), 0);
        //     const hours_attended = sessionByBatchId?.filter((ele: any) => ele.attendees.includes(el.student?.uid))
        //         .reduce((acc: number, curr_val: any) => acc + Number(curr_val.duration), 0);
            
        //     return {
        //     ...el,
        //     hours_attended,
        //     buffer_hours,
        //     total_fee: totalFee,
        //     paid_amount: paidAmount,
        //     balance_due: balanceDue,
        //     trainer_payment: el?.trainer_payment,
        //     student_payment_status: el?.student_payment_status,
        // };
        // })

        if (error || err ) throw error  || err;
        return { batch: batchData[0], progress_in_hrs, total_duration: batch[0].duration, batch_progress_in_percent, updatedTrainerData };
    } catch (error) {
        return errorNotifier(error);
    }
}

export const updateTrainerPayment = async (updateTrainerData: TrainerPaymentBatchInterface, batchId: number, routePath: string) => {
    try {
        const { data: batch_data, error: batch_data_error }: any = await FetchData(TableNames.batches, ["trainer_id","course_id", "id"]).eq("id", batchId)
        const { data: batch_trainer }: any = await FetchData(TableNames.batch_trainers).match({ batch_id: batchId, trainer_id: batch_data[0]?.trainer_id })
        
        if (batch_trainer?.length > 0) {
            const filterBatch_Trainer = batch_trainer?.filter((fil:any)=> fil?.batch_id === batchId);
            const trainerPaymentDate = filterBatch_Trainer?.length>0 && filterBatch_Trainer?.[0]?.process_trainer_payment_date.includes(updateTrainerData.process_trainer_payment_date) ? filterBatch_Trainer?.[0]?.process_trainer_payment_date : [...filterBatch_Trainer?.[0]?.process_trainer_payment_date, updateTrainerData.process_trainer_payment_date]
            await UpdateData(TableNames.batch_trainers,
                {
                    total_trainer_payment: updateTrainerData.processed_trainer_payments,
                    ...(routePath==="process_trainer_pay" && {process_trainer_payment_date: trainerPaymentDate}),
                }, { conditionKey: "id", conditionValue: batch_trainer[0]?.id })

            const new_batch_data = {
                ...(routePath==="process_trainer_pay" && {process_trainer_payment_date: trainerPaymentDate}),
                total_trainer_payment: updateTrainerData.processed_trainer_payments,
                total_trainer_payment_comment: updateTrainerData.total_trainer_payment_comment,
                batch_payment_status: updateTrainerData.batch_payment_status
            }

            const { error: batch_error } = await UpdateData(TableNames.batches, new_batch_data, { conditionKey: "id", conditionValue: batchId })

            const { training_data } = updateTrainerData;

            const altered_training_data = training_data.map((innerArray: any) => {
                // Map over each training object in the inner array
                // console.log(innerArray, "innerArray")
                return innerArray?.map((el: any) => ({
                    ...el,
                    batch_id: batch_data[0]?.id,
                    course_id: batch_data[0]?.course_id
                }));
            });

            const flattened_training_data = altered_training_data.flat(); // Flatten the nested array
            // console.log(flattened_training_data, "flattened_training_data")
            
            // const altered_training_data = training_data?.map((el: any) => ({...el, batch_id: batch_data[0]?.id , course_id: batch_data[0]?.course_id}))

            const { error: training_error } = await BulkUpdate(TableNames.trainings, flattened_training_data)

            
        const userlogObj:UserLogInterface={
            user_id: updateTrainerData.updated_by, 
            changes: updateTrainerData.total_trainer_payment_comment +" "+updateTrainerData.batch_payment_status,
            module: "PaymentFollowUp"
        }

         submitUserLogs(userlogObj);


        if (batch_error || training_error) throw batch_error || training_error
            return true
        } 

        else {
            return false
        }
        
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateCommentForTraining = async (trainingComment: PaymentComment, trainingId: number) => {
    try {
        const { data, error } = await UpdateData(TableNames.trainings, trainingComment, { conditionKey: "id", conditionValue: trainingId })
        if (error) throw error
        return true
    } catch (error) {
        return errorNotifier(error)
    }
}

export const trainerTransactionData = async (insertValues: any[]) => {
    try {
        const { data, error } = await BulkInsert(TableNames.trainer_transactions, insertValues);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}


// export const TrainerTransactionTableData = async (UsersDetails: any) => {
//     try {
//         const { data: batchDetails, error:batch_error } = await FetchData(TableNames.batches, ["id", "batch_code", "trainer:users(first_name, last_name, phone_number)", "branch_id", "branch:branches(branch_name)", "total_trainer_payment", "process_trainer_payment_date", "trainer_id", "training:trainings(student:students(id,name),id)"]).in("branch_id", UsersDetails?.branches);
//         const { data: trainerDetails, error } = await FetchData(TableNames.trainings, ["id", "batch_id", "trainers_ids", "student:students(name)"])
//         // console.log(batchDetails, "batchDetails");
//         if (error || batch_error) throw error || batch_error

//         const trainingData = batchDetails?.map((batch: any) => ({
//             id: batch?.id,
//             batch_code: batch?.batch_code,
//             updated_at: batch?.process_trainer_payment_date?.length > 0 ? (batch?.process_trainer_payment_date[batch?.process_trainer_payment_date?.length-1]) : '',
//             batch_start_date: dateFormat(batch?.start_date, "DD MMM YYYY"),
//             trainer_name:batch?.trainer?.first_name + batch?.trainer?.last_name,
//             trainer_number: batch?.trainer?.phone_number,
//             paid_amount: batch?.total_trainer_payment,
//             students: trainerDetails && trainerDetails?.filter((fil:any)=> (fil?.batch_id===batch?.id)&&(fil?.trainers_ids?.includes(batch?.trainer_id))),
//             student_list: batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.name)
//         }))
//         // console.log(trainingData, "trainingData")
//         return trainingData
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }

export const TrainerTransactionTableData = async (UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.trainer_transactions, ["*", "student: students(name, phoneNumber)"]).in("branch_id", UsersDetails?.branches);
        if (error) throw error;

        const trainingData = data?.map((dt: any) => ({
            date: dateFormat(dt?.created_at, "DD-MM-YYYY"),
            id: dt?.batch_id,
            batch_code: dt?.batch_code,
            trainer_name:dt?.trainer_name,
            trainer_number: dt?.trainer_phone_number,
            paid_amount: dt?.total_trainer_payment,
            student_name: `${dt?.student?.name} (${dt?.student?.phoneNumber})`,
            transaction_amount: dt?.trainer_payment
        }))
        return trainingData
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getTrainerIdBasedStudents = async (batch_Id:any) => {
    try {
        const { data: batchDetails, error:batch_error } = await FetchData(TableNames.batches, ["id","trainer_id"]).eq("id", batch_Id);
        const { data: trainerDetails, error } = await FetchData(TableNames.trainings, ["id", "batch_id", "trainers_ids", "student:students(name, phoneNumber)", "trainer_payment", "existing_trainer_payment", "student_payment_status"]).eq("batch_id", batch_Id)
        // console.log(trainerDetails, "Trainer_Details");
        if (error || batch_error) throw error || batch_error

        const trainingData = batchDetails?.map((batch: any) => (
            trainerDetails && trainerDetails?.filter((fil:any)=> fil?.trainers_ids?.includes(batch?.trainer_id))
        ))
        // console.log(trainingData[0], "trainingData");
        return trainingData[0]
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getBatchByIndividualID = async (batch_Id:any) => {
    try {
        const { data: batchDetails, error:batch_error } = await FetchData(TableNames.batches, ["course:courses(course_name)", "start_date", "end_date", "status"]).eq("id", batch_Id);
        if (batch_error) throw batch_error
        return batchDetails[0]
    } catch (error) {
        return errorNotifier(error)
    }
}