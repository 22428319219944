import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { studentFeedbackConcern } from "../../../utils/json/StudentFeedbackConcern";
import { studentFeedbackConcernresolved } from "../../../utils/json/StudentFeedbackConcernResolved";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { FeedbackConcernsInfo } from "./StudentFeedbackInfo/FeedbackConcernsInfo";
import { BackButton } from "../../Elements/BackButton";
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { QueueType } from "../../CommonFunctions/CommonFunc";
import { getStudentConcernTableData, getStudentConcernTableDataForResolved } from "../../../functions/admin/Action center/Session Feedback/sessionFeedback";

export const StudentFeedbackConcern = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "Student Concern",
      len: null,
    },
    {
      approval: "Resolved",
      len: null,
    },
  ] as any);

  useEffect(() => {
    setSearchParams({
      id: "student_concern",
    });
  }, []);

  useEffect(() => {
    (async () => {
      const Response: any = await getStudentConcernTableData();
      const resolvedResponse: any = await getStudentConcernTableDataForResolved();
      const Length: any = Response?.length;
      const resolvedLength: any = resolvedResponse?.length;

      setQueue((prevQueue: any) => [
        {
          ...prevQueue[0],
          len: Length,
        },
        {
          ...prevQueue[1],
          len: resolvedLength,
        },
      ]);
    })();
  }, []);

  return (
    <div>
      {mode === "view" && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"flex items-center justify-end w-[95%] mx-auto"}
          />
          <FeedbackConcernsInfo id={id} mode={mode} />
        </div>
      )}
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
              Student Feedback/Concerns
            </h1>
            {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )}
          </div>
          {id === "student_concern" && (
            <TableComponent
              TABLE_JSON={studentFeedbackConcern}
            />
          )}
          {id === "resolved" && (
            <TableComponent
              TABLE_JSON={studentFeedbackConcernresolved}
            />
          )}
        </div>
      )}
    </div>
  );
};
