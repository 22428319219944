import React from "react";
import { CardDesign } from "./CardDesign";

export const SessionFeedback = ({ DashboardData }: any) => {
  const ProgressCount = (data: any) => {
    return (
      <div className="w-full">
        {data.data.name}
        <div className="flex items-center w-full">
          <div className="bg-gray-200 text-lg rounded-md my-3 w-full">
            <div
              className={`h-5 rounded-md `}
              style={{
                backgroundColor: data.data.COLORS,
                width: data.data.count,
              }}
            ></div>
          </div>
          <p className="ml-2">{data.data.count}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full rounded-[8px]">
      <div className="bg-[#F9FCFF] w-full shadow-[#00000029_0px_3px_8px] rounded-[8px]">
        {DashboardData.map((item: any, index: number) => (
          <div key={index}>
            {/* Title */}
            <h1 className="font-Roboto font-[500] text-[18px] md:text-[24px] bg-[#FBFBFB] py-4 px-4">
              {item.sessionFTitle}
            </h1>

            {/* Responsive Layout */}
            <div className="flex flex-col md:flex-row items-center md:items-start gap-4 p-4 bg-white">
              {/* Left Section: Progress Count */}
              <div className="w-full md:w-[45%]">
                {item.SessionChart.map((data: any, chartIndex: number) => (
                  <ProgressCount key={chartIndex} data={data} />
                ))}
              </div>

              {/* Right Section: Cards Grid */}
              <div className="grid grid-cols-2 md:grid-cols-2 gap-2 w-full md:w-[50%]">
                {item.Sessiondata.map((data: any, cardIndex: number) => (
                  <CardDesign key={cardIndex} data={data} />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
