import { useState } from 'react';
import { ReportTable } from './ReportTable';

export const ReportInSections = ({response}:any) => {
    const [selectedTab, setSelectedTab] = useState("Coursewise Registration")
    const Headers = [
      "Coursewise Registration",
      "Admission Report",
      "Revenue Vs Proforma Invoice",
      "Operations",
      "Batch",
      "Session Feedback",
      "Feedback/Concern",
      "Missed Revenue",
      "Placement",
      "Deviation - Pending Action",
    ];

  return (
    <div className="w-[98%] mx-auto space-y-8">
      <div className="w-11/12 mx-auto flex flex-wrap gap-4">
        {Headers?.map((head: any) => (
          <p onClick={()=>setSelectedTab(head)} className={`${selectedTab===head && 'font-[500] bg-sky-700 text-white'} p-2 border border-blue-400 rounded-md px-4 text-[16px] font-Roboto cursor-pointer hover:font-[500] hover:bg-sky-600 hover:text-white`}>
            {head}
          </p>
        ))}
        </div>
        <ReportTable selectedTab={selectedTab} response={response} />
    </div>
  );
}
