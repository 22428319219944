import {useState, useEffect, useCallback} from "react";
import { Progressbar } from "../Elements/Progressbar";
import { getTrainingInformationById } from "../../functions/admin/Students/students";
import { Loader } from "../Elements/Loader/Loader";
import { Headline } from "../Elements/Headline/Headline";
import { ReadData } from "../Elements/ReadData";
import { paginationConfig } from "../../utils/config/elements";
import { NoDataFound } from "../Elements/Table/TableComponent";
import Pagination from "../Elements/Table/Pagination";
import { useSharedLogic } from "../CommonFunctions/CommonFunc";

export const CourseProgress = () => {
  const { courseId }: any = useSharedLogic(); 
  const isId = Number(courseId);
  const [isLoading, setIsLoading] = useState(false)
  const [studentCourseDetails, setStudentCourseDetails] = useState<any>({})
  const headers = ["Modules", "Video Duration", "Course Progress"];
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const currentPageData = tableData.slice(
      (currentPage - 1) * rowsPerPage,
      (currentPage - 1) * rowsPerPage + rowsPerPage
  );

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const studentCourseProgress = await getTrainingInformationById(isId);
            setStudentCourseDetails(studentCourseProgress);
            setTableData(studentCourseProgress?.course?.videos)
            setIsLoading(false);
        })()
    },[])

    const handlePageChange = useCallback(async (newPage: number) => {
      try {
          setCurrentPage(newPage);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    }, []);
    
    return (
      <>
        {isLoading ? (
          <Loader widthFix={"w-9/12"} />
        ) : (
          <div className="bg-white w-9/12 mx-auto shadow-md shadow-[#00000029] rounded-[6px] pt-6">
            <div className="space-y-2">
              <Headline cardHeading={"Course Progress"} />
              <div className="">
                {studentCourseDetails && (
                  <div className="flex items-center justify-between w-11/12 mx-auto py-8">
                    <div className="space-y-4">
                      <ReadData
                        head={"Student Name"}
                        body={studentCourseDetails?.student?.name}
                      />
                      <ReadData
                        head={"Course"}
                        body={studentCourseDetails?.course?.course_name}
                      />
                    </div>
                    <Progressbar
                      percentage={studentCourseDetails?.overAllProgress}
                      mainDivStyle={"w-[120px] h-[120px]"}
                    />
                  </div>
                )}
                <div className="pb-6">
                  <table className="table table-fixed w-full ">
                    <thead className="bg-[#EFF7FF] text-[#686868] text-[20px]">
                      <tr>
                        <th
                          className={
                            "text-[16px] text-[#686868] w-1/12 text-center px-6"
                          }
                        >
                          #
                        </th>
                        {headers.map((name: any, index: number) => (
                          <th
                            className={`font-bold w-6/12 py-3 text-[16px] text-[#686868] text-center`}
                            key={index}
                          >
                            {name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData.map((row: any, index: number) => (
                        <tr
                          key={index}
                          className={index % 2 !== 0 ? "bg-[#F8FBFF]" : ""}
                        >
                          <td
                            className={`text-[16px] text-[#686868] w-1/12 text-center px-6`}
                          >
                            {(currentPage - 1) * 10 + index + 1}
                          </td>
                          <td className="w-3/12 py-4 text-[16px] text-[#686868] text-center">
                            {row.title}
                          </td>
                          <td className="w-3/12 py-4 text-[16px] text-[#686868] text-center">
                            {row.duration} Mins
                          </td>
                          <td className="w-3/12 py-4 text-[16px] text-[#686868] text-center">
                            {row.progress}%
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {currentPageData.length ? (
                    <Pagination
                      totalPages={totalPages}
                      totalItems={tableData.length}
                      currentPage={currentPage}
                      // paginatorFunc={paginatorFunc}
                      onPageChange={handlePageChange}
                    />
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
};
