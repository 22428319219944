import { dateFormat } from "../../../../Services/CommonFunctions"
import { calculatePercentage, errorNotifier } from "../../../commonHelper"
import { TableNames } from "../../../config/Tables"
import { FetchData, InsertData, UpdateData } from "../../../crud"
import {  AdjustPaymentInterface, RefundInterface } from "./interface"
import { UserLogInterface } from "../../User Logs/interface";
import { submitUserLogs } from "../../User Logs/userLogs";
import { getBranchForGST } from "../../Students/students"

export const getRefundPreloadData = async () => {
    try {
        // const { data: student, error: student_error }: any = await FetchData(
        //   TableNames.students,
        //   ["id", "name", "email", "phoneNumber", "uid", "trainings(id)"]
        // ).eq("type", "Paid Student");
        const { data: training, error: training_error } = await FetchData(TableNames.trainings, ["id", "student:students(id, name, email, phoneNumber, uid, type)", "branch:branches(id, branch_name)"])
        const { data: courses, error: course_error }: any = await FetchData(TableNames.courses, ["id", "course_name"]).eq("type", "Paid")
        const { data: cities, error: city_error } = await FetchData(TableNames.cities, ["id", "city_name"])
        const { data: branches, error: branch_error } = await FetchData(TableNames.branches, ["id", "branch_name", "city", "rooms"])
        if (training_error || course_error || city_error || branch_error) throw training_error || course_error || city_error || branch_error

        const mapTrainings = training?.filter((fil:any)=> fil?.student?.type === "Paid Student").map((ft: any) => {
            return {
                training_id: ft?.id,
                id: ft?.student?.id,
                name: ft?.student?.name,
                email: ft?.student?.email,
                phoneNumber: ft?.student?.phoneNumber,
                uid: ft?.student?.uid,
                branch: ft?.branch?.branch_name,
                branch_id: ft?.branch?.id
            }
        })
        return {student: mapTrainings, courses, cities, branches}
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getStudentTrainingBasedOnBranch = async(student_id:any, course_id:any) => {
    try {
        const { data: training, error: training_error }: any = await FetchData(TableNames.trainings, ["branch_id"]).eq('student_id', student_id).eq('course_id', course_id);
        if (training_error) throw training_error;
        if (training?.length > 0) {
            const { data, error } = await FetchData(TableNames.branches, ["id", "branch_name", "fee_type", "igst", "cgst", "sgst", "course_data:trainings(course_id, student_id, course_fee, discount_percentage)"]).eq('id', training[0]?.branch_id);
            if (error) throw error;
            const mapBranchData = data?.map((dt:any)=>{
                const course_data = dt?.course_data?.filter((ft:any)=>ft?.course_id === course_id && ft?.student_id === student_id);
                return {
                    ...dt,
                    course_fee: course_data?.[0]?.course_fee,
                    discount_percentage: course_data?.[0]?.discount_percentage
                }
            })
            return mapBranchData;
        }
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getCoursesForStudent = async (studentId: string) => {
    console.log(studentId, "student_id");
    try {
        // const { data: student_data, error: student_error }: any = await FetchData(TableNames.students, ["uid", "courses"]).eq("uid", studentId);
        const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["student:students(uid)", "course_id"]);
        if (training_error) throw training_error;
        const filterTraining = training_data?.filter((fil: any) => fil?.student?.uid === studentId);
        console.log(filterTraining, "filterTraining");
        const { data: course_data, error: course_error } = await FetchData(TableNames.courses, ["id","type", "course_name"]).eq("type", "Paid");
        if (course_error) throw course_error;
        const filterCourseBasedOnTraining = course_data?.filter((fil:any) =>
            filterTraining?.some((course:any) => course.course_id === fil.id)
        );
        
        return filterCourseBasedOnTraining;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getTrainingFortheStudent = async (studentId: string, courseId: number, branchId?:number) => {
    try {

        var batchValues:any = []
        var batchStartingDate = ""

        const { data, error }: any = await FetchData(TableNames.trainings, [
          "*",
          "student_id:students(name, email, phoneNumber, uid), city_id:cities(city_name), branch_id:branches(branch_name), course_id:courses(course_name, type)",
        ]).match({
          student_id: studentId,
          course_id: courseId,
          branch_id: branchId,
        });
        
        if (data.length > 0 && data[0]?.batch_id !== null) {
                        
            const { data: student_sessions, error: session_error }: any = await FetchData(TableNames.sessions, ["id", "batch_id", "attendees", "title", "batch:batches(course:courses(course_name), branch:branches(branch_name), duration)", "details", "duration", "session_date"]).eq("batch_id", data[0]?.batch_id).order("id", { ascending: false })

            const sessions = student_sessions?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(data[0].student_id.uid) }))
        
            const filtered_students = sessions?.filter((stu: any) => { return stu?.is_present === true })
            const total_session_time = filtered_students?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)
        
            const { data: batch_data, error: batch_error } = await FetchData(TableNames.batches, ["duration", "batch_code"]).eq("id", data[0].batch_id)
        
            var batchData = batch_data?.map((batch: any) => ({
                ...batch, student_progress_in_hrs: total_session_time, student_progress: calculatePercentage(total_session_time, data[0].duration)
            }))
            batchValues = batchData;
            
            var courseData: any = calculatePercentage(total_session_time, data[0]?.duration)   
            const { data: batch_follow_up_date, error: batch_follow_up_error } : any = await FetchData(TableNames.batch_follow_up, ["follow_up_date"]).in("id", data[0].batch_follow_up).order("id", { ascending: true })

            const batch_start_date = dateFormat(batch_follow_up_date[batch_follow_up_date?.length - 1], "DD MMM YYYY")
            batchStartingDate = batch_start_date;

            if (batch_follow_up_error) throw batch_follow_up_error;
        }

        let { data: paymentDetails, error: PaymentDetailsError } = await FetchData(TableNames.transactions).eq("training_id", data[0].id);
        if (PaymentDetailsError) {
            throw PaymentDetailsError;
        }

        const updatedStudentData = data.map((student: any) => ({ ...student, registration_date: dateFormat(student?.created_at, "DD MMM YYYY") }))
        if (error) throw error;
        const { data: appliedJobsOfStudent, error: AppliedJobsQueryError } = await FetchData(TableNames.student_jobs, ["id", "job:jobs(job_role,company_name,location,inserted_at)", "shortlisted", "placed", "joined", "created_at"]).eq("student_id", data[0].student_id.uid);
        if (AppliedJobsQueryError) throw AppliedJobsQueryError;
        
        return { updatedStudentData: updatedStudentData[0], batch_start_date: batchStartingDate, paymentDetails, course_progress: Math.round(courseData || 0) , batch_data: batchValues || null, appliedJobs: appliedJobsOfStudent, status: true }

    } catch (error) {
        return errorNotifier(error)
    }
}

export const getRefundTableData = async(UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.refunds, ["amount", "training: trainings(student: students(name), total_fee, course: courses(course_name), branch: branches(id, branch_name), status)"]);
        if (error) throw error
        return data?.filter((refund: any) =>  UsersDetails?.branches.includes(refund?.training?.branch.id)).map((tab: any) => ({
            // ...tab,
            branch_name: tab?.training?.branch?.branch_name,
            student_name: tab?.training?.student?.name,
            course_name: tab?.training?.course?.course_name,
            refundAmount: Math.round(Number(tab?.amount)),
            status: tab?.training?.status,
            totalFee: Math.round(Number(tab?.training?.total_fee))
        }));
    } catch (error) {
        return errorNotifier(error)
    }
}


export const getAdjustPaymentTableData = async (UsersDetails: any) => {
  try {
    const { data, error } = await FetchData(TableNames.adjust_payment, [
      "amount",
      "from_branch: from_branch_id(id, branch_name)",
      "to_branch: to_branch_id(id, branch_name)",
      "from_training: from_training_id(student: students(name))",
      "to_training: to_training_id(student: students(name), total_fee, course: courses(course_name), status)"
    ]);
    if (error) throw error;
        return data?.filter((adj: any) =>  UsersDetails?.branches.includes((adj?.from_branch?.id)||(adj?.to_branch?.id))).map((tab: any) => ({
            branch_name: tab?.to_branch?.branch_name,
            from_student_name: tab?.from_training?.student?.name,
            to_student_name: tab?.to_training?.student?.name,
            course_name: tab?.to_training?.course?.course_name,
            refundAmount: Math.round(Number(tab?.amount)),
            status: tab?.to_training?.status,
            totalFee: Math.round(Number(tab?.to_training?.total_fee))
        }));

  } catch (error) {
    return errorNotifier(error);
  }
};

export const checkExistingRefundCall = async (training_id:number, course_id:number) => {
    try {
        const { data, error } = await FetchData(TableNames.refunds, ["trainings(id, courses(id))"]);
        // console.log(data, "data");
        if (error) throw error;
        const dataBasedOnTraining = data?.filter((fil: any) => fil?.training?.id === training_id && fil?.training?.courses?.id === course_id);
        console.log(dataBasedOnTraining, "dataBasedOnTraining", course_id, typeof course_id, training_id, typeof training_id)
        return dataBasedOnTraining?.length ? true : false
    } catch (error) {
        return errorNotifier(error)
    }
}

export const issueRefund = async (refundData: RefundInterface) => {
    try {
      
        const { data, error } = await InsertData(TableNames.refunds, refundData)

        const userlogObj:UserLogInterface={
            user_id: refundData.user_id, 
            // user_id: refundData.issusedBy, 
            changes: refundData.reason,
            module: "PaymentFollowUp"
        }
        submitUserLogs(userlogObj);
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getStudentBasedOnBranch = async (branchId: number) => {
    console.log(branchId, "branchId")
    try {
        const { data, error } = await FetchData(TableNames.students).contains("branches", [branchId])
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

const handleData = (data:any) => {
  if (Array.isArray(data)) {
    return data;
  } else {
    return [data];
  }
}

export const adjustPayment = async (paymentData: AdjustPaymentInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.adjust_payment, paymentData);
        if(data) {
        const { data: reveiver_data_retrive, error: receiver_data_error } =
          await FetchData(TableNames.trainings, [
            "branch_id",
            "balance_due",
            "payment_details",
          ]).eq("id", paymentData.to_training_id);
        // console.log(reveiver_data_retrive, "reveiver_data_retrive")
        if(receiver_data_error) throw receiver_data_error
        if (reveiver_data_retrive) {
            const dataPayment: any = reveiver_data_retrive?.[0];
            const newDate: any = new Date();
            const reveiverDetails = {
              payment_details: [
                ...dataPayment?.payment_details,
                {
                  payment_type: "Installment",
                  paid_amount: Number(paymentData?.amount),
                  mode_of_payment: "Adjusted",
                  payment_date: dateFormat(newDate, "YYYY-MM-DD"),
                  transaction_id: "",
                },
              ],
            };
            // console.log(reveiverDetails, "reveiverDetails");
              await UpdateData(TableNames.trainings, reveiverDetails, {
                conditionKey: "id",
                conditionValue: paymentData.to_training_id,
              });
            const checkGSTwithBranch: any = await getBranchForGST(dataPayment?.branch_id);
            if (checkGSTwithBranch?.length > 0) {
                const paymentInsertionData = {
                    payment_type: ["Installment"],
                    paid_amount: Number(paymentData?.amount),
                    mode_of_payment: "Adjusted",
                    transaction_id: "",
                    training_id: paymentData.to_training_id,
                    balance: Number(dataPayment?.balance_due) - Number(paymentData?.amount),
                    payment_date: new Date(
                        new Date(new Date()).toISOString()
                    ),
                    ...(checkGSTwithBranch[0]?.fee_type === "IGST"
                        ? { igst: Number(checkGSTwithBranch[0]?.igst) }
                        : { igst: null }),
                    ...(checkGSTwithBranch[0]?.fee_type === "SGST"
                        ? { sgst: Number(checkGSTwithBranch[0]?.sgst) }
                        : { sgst: null }),
                    ...(checkGSTwithBranch[0]?.fee_type === "SGST"
                        ? { cgst: Number(checkGSTwithBranch[0]?.cgst) }
                        : { cgst: null }),
                    paid_amount_after_gst:
                        checkGSTwithBranch[0]?.fee_type === "IGST"
                            ? Number(paymentData?.amount) -
                            Number(paymentData?.amount) *
                            (Number(checkGSTwithBranch[0]?.igst) / 100)
                            : Number(paymentData?.amount) -
                            Number(paymentData?.amount) *
                            (Number(checkGSTwithBranch[0]?.sgst) / 100) -
                            Number(paymentData?.amount) *
                            (Number(checkGSTwithBranch[0]?.cgst) / 100),
                };
                // console.log(paymentInsertionData, "paymentInsertionData")
                await InsertData(TableNames.transactions, paymentInsertionData);
            }
        }
        }
        const userlogObj: UserLogInterface = {
            user_id: paymentData.modifiedby, 
            changes: paymentData.reason,
            module: "AdjustPayment"
        }
        submitUserLogs(userlogObj);
        if (error) throw error;
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}  

export const getCities = async (cityIds: number[]) => {
    try {
        const { data, error } = await FetchData(TableNames.cities).in("id", cityIds);
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}