import React, { useState, useEffect } from "react";
import { resume_review_dashboard } from "../../../utils/json/resume_review_dashboard";
import { resume_rejection_logs_dashboard } from "../../../utils/json/resume_rejection_logs";
import { BackButton } from "../../Elements/BackButton";
import {
  useSharedLogic,
  CommonTableComp,
} from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import { useAppSelector } from "../../..";
import { ViewResume } from "./ViewResume";
import { useSearchParams } from "react-router-dom";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { QueueType } from "../../CommonFunctions/CommonFunc";
import { resumeReviewDashboardTableData, trainingLogsBasedTableData } from "../../../functions/admin/HR Management/hr_management";

export const ResumeReviewDashboard = () => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "Training Data",
      len: null,
    },
    {
      approval: "Rejection Logs",
      len: null,
    },
  ] as any);

  useEffect(() => {
    setSearchParams({
      id: "training_data",
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (signInData?.data?.length > 0) {
        const trainingsData: any = await resumeReviewDashboardTableData(
          signInData?.data[0]
        );
        const logsData: any =
          await trainingLogsBasedTableData(signInData?.data[0]);
        const trainingLength: any = trainingsData?.length;
        const logsLength: any = logsData?.length;

        setQueue((prevQueue: any) => [
          {
            ...prevQueue[0],
            len: trainingLength,
          },
          {
            ...prevQueue[1],
            len: logsLength,
          },
        ]);
      }
    })();
  }, []);

  return (
    <div className="w-11/12 mx-auto">
      {/* <CommonTableComp
        id={id}
        head={"Resume Review Dashboard"}
        TABLE_JSON={resume_review_dashboard}
        backEndCallParams={signInData?.data[0]}
      /> */}
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
            Resume Review Dashboard
            </h1>
            {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )}
          </div>
          {id === "training_data" && (
            <TableComponent
              TABLE_JSON={resume_review_dashboard}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "rejection_logs" && (
            <TableComponent
              TABLE_JSON={resume_rejection_logs_dashboard}
              backEndCallParams={signInData?.data[0]}
            />
          )}
        </div>
      )}
      {id && mode === "edit" && allowOnlyActions("edit") && (
        <div className="space-y-2">
          <BackButton
            topDivStyle={"w-[95%] justify-between"}
            header={"Company Profile"}
          />
          <ViewResume id={id} />
        </div>
      )}
    </div>
  );
};
