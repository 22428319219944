// import { useState, useEffect } from "react";
// import {
//   ImageListForSelected,
//   ImageListForUnselected,
// } from "../LeftNavbar/jsonList";
// import { useNavigate, useParams, createSearchParams } from "react-router-dom";
// import openAndCloseNavbar from "../../Assets/openAndCloseNavbar.svg";
// import { useAppDispatch, useAppSelector } from "../../index";
// import { showSideNavStatus } from "../../Redux/Reducers/sideNavPosition";
// import searchIcon from "../../Assets/searchIcon.svg";
// import FITA_Logo from "../../Assets/FITA_Logo.png";
// import dropArrowBlack from "../../Assets/dropArrow.svg";
// import dropArrowWhite from "../../Assets/dropArrowWhite.svg";
// import { getLoggedUserDetails } from "../../functions/admin/User Mangement/userMangement";
// import UserLogs from "../../Assets/UserLogs.svg";
// import { setUserRoles } from "../../Redux/Reducers/userSelectedRoles";
// import { searchMenus } from "../AdminManagement/RoleManagement/helper";
// import { FunctionForPrivileges } from "../CommonFunctions/Privileges";
// import { SUPABASE_CLIENT } from "../../Services/Supabase";
// import Log_Out from "../../Assets/Log_Out.svg";

// export const LeftNavbar = () => {
//   const navigate = useNavigate();
//   const dispatch = useAppDispatch();
//   const params = useParams();
//   const [openTab, setOpenTab] = useState(false);
//   const [dropIndex, setDropIndex] = useState<any>([]); // [localStorage.getItem('arrowDirection')]||
//   const [subSectionIndex, setDropSectionIndex] = useState([] as any);
//   const [menuOptions, setMenuOptions] = useState([] as any);
//   const [searchRole, setSearchRole] = useState("");
//   const [triggerSearch, setTriggerSearch] = useState(false);
//   const { dashboardScreen, subsection }: any = params;
//   const DashboardSubMenus = [
//     "admissions",
//     "invoices&revenue",
//     "operations",
//     "batch",
//     "session_feedback_dashboard",
//     "feedback_concern",
//     "placement",
//   ];
//   const { mapMainComponent, concatenatedSubArray, ForSubDomainsNew }: any =
//     FunctionForPrivileges();
//   const [{ sideNavOpenClose }, { userDetails }, { selectedRoles }] =
//     useAppSelector(({ navStatus, login, roles }: any) => [
//       navStatus,
//       login,
//       roles["userRoles"],
//     ]);
//   const checkSearchDataIsAvailable = menuOptions?.length
//     ? menuOptions
//     : selectedRoles;

//   const matchIdWithRoutes: any = {
//     manage_courses: "by_course",
//     pending_payment: "by_student",
//     daily_transactions: "unverified",
//     student_actions: "students_action_queue",
//     student_actions_approval_queue: "current_approval_queue",
//     issue_refund_adjust_payment: "issue_refund",
//     student_attendance: "absent",
//     cancellation_session: "verified_comments",
//     operational_report: "batch",
//     session_feedback: "all_sessions",
//     // calculate_trainer_payment: "yet_to_calculated",
//     process_trainer_payment: "all_data",
//     student_feedback_concerns: "student_concern",
//     cancelled_session: "yet_to_speak",
//     resume_review_dashboard: "training_data",
//   };

//   useEffect(() => {
//     (async () => {
//       // const storedDirection = localStorage.getItem('arrowDirection');
//       // if (storedDirection) {
//       //   setDropIndex([...dropIndex, storedDirection]);
//       // }
//       setDropIndex([...dropIndex, dashboardScreen]);
//       const response = await searchMenus(selectedRoles, searchRole);
//       setMenuOptions(response);
//       setTriggerSearch(false);
//     })();
//   }, [triggerSearch]);

//   const SelectedDivision = (section: any) => {
//     if (section?.submenus?.length > 0) {
//       const checkSubsection = ForSubDomainsNew?.filter(
//         (sub: any) => sub?.idkey === section.idkey
//       );

//       const filteredSubmenus = checkSubsection?.map((sub: any) => {
//         let foundSub = null;
//         sub?.submenus?.find((fil: any) => {
//           if (concatenatedSubArray.includes(fil?.idkey)) {
//             foundSub = fil?.idkey;
//             return true; // Stop the loop
//           }
//           return false;
//         });
//         return foundSub;
//       });

//       dropMenu(section);
//       const data = filteredSubmenus?.[0];
//       if (matchIdWithRoutes?.hasOwnProperty(data)) {
//         navigate({
//           pathname: `/${section.idkey}/${data}`,
//           search: createSearchParams({
//             id: `${matchIdWithRoutes[data]}`,
//           }).toString(),
//         });
//       } else {
//         navigate(`/${section.idkey}/${data}`);
//       }
//     } else {
//       navigate(`/${section.idkey}`);
//     }
//   };

//   const changeModalWidth = () => {
//     dispatch(showSideNavStatus(!sideNavOpenClose));
//     // setOpenTab(!openTab);
//     setDropIndex([]);
//     setOpenTab(false);
//   };

//   const dropMenu = (section: any) => {
//     if (section?.submenus) {
//       if (dropIndex.includes(section.idkey)) {
//         const removeIndex = [...dropIndex];
//         const checkIsAvailable = removeIndex.filter(
//           (idx: any) => idx !== section.idkey
//         );
//         setDropIndex(checkIsAvailable);
//         // localStorage.setItem('arrowDirection', checkIsAvailable);
//       } else setDropIndex([...dropIndex, section.idkey]);
//     }
//   };

//   const subSectionsRoute = (section: any, subComp: any) => {
//     if (matchIdWithRoutes?.hasOwnProperty(subComp.idkey)) {
//       navigate({
//         pathname: `/${section.idkey}/${subComp.idkey}`,
//         search: createSearchParams({
//           id: `${matchIdWithRoutes[subComp.idkey]}`,
//         }).toString(),
//       });
//     } else {
//       navigate(`/${section.idkey}/${subComp.idkey}`);
//     }
//   };

//   const handleLogout = async () => {
//     try {
//       // Sign out the user
//       await SUPABASE_CLIENT.auth.signOut();
//       window.location.href = "/";
//       // Redirect to the login page or update your app's state as needed
//     } catch (error) {
//       console.error("Error logging out", error);
//     }
//   };

//   return (
//     <div
//       className={`${
//         sideNavOpenClose
//           ? "md:w-[10%] lg:w-[10%] xl:w-[5%]"
//           : "md:w-[35%] lg:w-[30%] xl:w-[22%]"
//       } z-10 relative space-y-6 flex flex-col items-center justify-between shadow-md shadow-[#00000029] mt-6`}
//     >
//       {sideNavOpenClose ? (
//         <div className="w-[140px] h-[75px]"></div>
//       ) : (
//         <img src={FITA_Logo} alt="Logo" className="w-[120px] h-[70px] 2xl:w-[140px] 2xl:h-[75px] select-none" />
//       )}
//       <div className=" w-full flex items-center justify-center">
//         {sideNavOpenClose ? (
//           <img
//             src={searchIcon}
//             alt="Search"
//             className="w-[20px] h-[20px] cursor-pointer"
//           />
//         ) : (
//           <div className="relative flex items-center justify-center w-9/12">
//             <input
//               placeholder="Search..."
//               name="search"
//               value={searchRole}
//               onChange={(e: any) => setSearchRole(e.target.value)}
//               className="border border-[#707070] rounded-2xl h-[35px] w-full px-2"
//               onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
//                 if (event.key === "Enter") {
//                   setTriggerSearch(true);
//                 }
//               }}
//             />
//             <img src={searchIcon} alt="Search" className="absolute right-3" />
//           </div>
//         )}
//         <img
//           src={openAndCloseNavbar}
//           alt="Logo"
//           className={`ml-[125px] absolute -right-5 select-none cursor-pointer ${
//             sideNavOpenClose &&
//             "rotate-180 transition-all duration-300 ease-in-out"
//           }`}
//           onClick={changeModalWidth}
//         />
//       </div>
//       <div
//         className={`${
//           !sideNavOpenClose && "w-[95%]"
//         } mx-auto h-[80vh] overflow-auto snap-y snap-mandatory scrollBar scrollbar-hide scroll-smooth`}
//       >
//         {checkSearchDataIsAvailable &&
//           checkSearchDataIsAvailable?.map((section: any, index: number) => {
//             if (mapMainComponent?.includes(section?.idkey)) {
//               return (
//               <div key={index}>
//                 <div
//                   className={`w-full select-none flex items-center justify-between rounded-[2px] cursor-pointer px-2 py-1.5 ${
//                     section.idkey.includes(dashboardScreen) ||
//                     (!dashboardScreen && section["idkey"] === "dashboard") ||
//                     (section?.submenus &&
//                       section?.submenus?.[index]?.idkey.includes(subsection))
//                       ? "bg-[#036AD1] text-white shadow-md shadow-[#00000029]"
//                       : "text-[#707070]"
//                   }`}
//                   onClick={() => SelectedDivision(section)}
//                 >
//                   <div
//                     className={`flex items-center gap-2 ${
//                       sideNavOpenClose && "p-3"
//                     }`}
//                   >
//                     <img
//                       src={
//                         section.idkey.includes(dashboardScreen)
//                           ? ImageListForUnselected[section.idkey]
//                           : ImageListForSelected[section.idkey]
//                       }
//                       alt={`${section.idkey}`}
//                       className="w-[14px] 2xl:w-[15px]"
//                       onClick={() => dispatch(showSideNavStatus(false))}
//                     />
//                     {!sideNavOpenClose && (
//                       <h1 className="text-[15px] 2xl:text-[16px] font-Roboto font-[400]">
//                         {section.label}
//                       </h1>
//                     )}
//                   </div>
//                   {!sideNavOpenClose && section?.submenus?.length !== 0 && section?.idkey!=="dashboard" && (
//                     <img
//                       src={
//                         section.idkey.includes(dashboardScreen)
//                           ? dropArrowWhite
//                           : dropArrowBlack
//                       }
//                       alt="dropArrow"
//                       className={`${
//                         dropIndex.includes(section?.idkey) && "rotate-90"
//                       }`}
//                       onClick={() => dropMenu(section)}
//                     />
//                   )}
//                 </div>
//                 <div className="">
//                   {dropIndex.includes(section?.idkey) &&
//                     section?.submenus &&
//                       section?.submenus?.map((subComp: any, index: number) => {
//                         if ((concatenatedSubArray.includes(subComp?.idkey))&&(!DashboardSubMenus.includes(subComp?.idkey))) {
//                           return (
//                             <div
//                               key={index}
//                               className={`${
//                                 index === 0 && "mt-1"
//                               } px-4 py-1 w-[90%] mx-auto cursor-pointer rounded-[2px] ${
//                                 (subComp.idkey?.replace(/_/g, "-") ===
//                                   subsection?.replace(/_/g, "-")) ||
//                                 (!subsection &&
//                                   subComp["idkey"] === subComp?.[0]?.["idkey"])
//                                   ? "bg-[#B4D9FF] text-[#000000]"
//                                   : "bg-white text-[#707070]"
//                               }`}
//                               onClick={() => subSectionsRoute(section, subComp)}
//                             >
//                               <p className="w-[15px]"></p>
//                               <p className="font-Roboto font-[400] text-[13px] 2xl:text-[14px] px-2">
//                                 {subComp.label}
//                               </p>
//                             </div>
//                           );
//                           }
//                     })}
//                 </div>
//               </div>
//             );
//             }
//           })}
//       </div>
//       <div className='flex items-center gap-2 w-full p-4 cursor-pointer' onClick={handleLogout}>
//         <img src={Log_Out} alt="Log_Out" className='w-5 h-5' />
//         {!sideNavOpenClose && <h1 className='text-[20px] font-Roboto font-[400] text-red-500'>Logout</h1>}
//       </div>
//     </div>
//   );
// };

// import React, { useState, useEffect } from "react";
// import {
//   ImageListForSelected,
//   ImageListForUnselected,
// } from "../LeftNavbar/jsonList";
// import { useNavigate, useParams, createSearchParams } from "react-router-dom";
// import openAndCloseNavbar from "../../Assets/openAndCloseNavbar.svg";
// import { useAppDispatch, useAppSelector } from "../../index";
// import { showSideNavStatus } from "../../Redux/Reducers/sideNavPosition";
// import searchIcon from "../../Assets/searchIcon.svg";
// import FITA_Logo from "../../Assets/FITA_Logo.png";
// import dropArrowBlack from "../../Assets/dropArrow.svg";
// import Log_Out from "../../Assets/Log_Out.svg";
// import { SUPABASE_CLIENT } from "../../Services/Supabase";
// import { searchMenus } from "../AdminManagement/RoleManagement/helper";
// import { FunctionForPrivileges } from "../CommonFunctions/Privileges";

// export const LeftNavbar = () => {
//   const navigate = useNavigate();
//   const dispatch = useAppDispatch();
//   const params = useParams();
//   const [openTab, setOpenTab] = useState(false);
//   const [dropIndex, setDropIndex] = useState<string[]>([]);
//   const [menuOptions, setMenuOptions] = useState([] as any);
//   const [searchRole, setSearchRole] = useState("");
//   const [triggerSearch, setTriggerSearch] = useState(false);
//   const { dashboardScreen, subsection }: any = params;
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//   const DashboardSubMenus = [
//     "admissions",
//     "invoices&revenue",
//     "operations",
//     "batch",
//     "session_feedback_dashboard",
//     "feedback_concern",
//     "placement",
//   ];

//   const { mapMainComponent, concatenatedSubArray, ForSubDomainsNew }: any =
//     FunctionForPrivileges();

//   const [{ sideNavOpenClose }, { selectedRoles }] = useAppSelector(
//     ({ navStatus, roles }: any) => [navStatus, roles["userRoles"]]
//   );

//   const checkSearchDataIsAvailable = menuOptions?.length
//     ? menuOptions
//     : selectedRoles;

//   const matchIdWithRoutes: any = {
//     manage_courses: "by_course",
//     pending_payment: "by_student",
//     daily_transactions: "unverified",
//     student_actions: "students_action_queue",
//     student_actions_approval_queue: "current_approval_queue",
//     issue_refund_adjust_payment: "issue_refund",
//     student_attendance: "absent",
//     cancellation_session: "verified_comments",
//     operational_report: "batch",
//     session_feedback: "all_sessions",
//     process_trainer_payment: "all_data",
//     student_feedback_concerns: "student_concern",
//     cancelled_session: "yet_to_speak",
//     resume_review_dashboard: "training_data",
//   };

//   useEffect(() => {
//     (async () => {
//       setDropIndex([...dropIndex, dashboardScreen]);
//       const response = await searchMenus(selectedRoles, searchRole);
//       setMenuOptions(response);
//       setTriggerSearch(false);
//     })();
//   }, [triggerSearch]);

//   const SelectedDivision = (section: any) => {
//     if (section?.submenus?.length > 0) {
//       const checkSubsection = ForSubDomainsNew?.filter(
//         (sub: any) => sub?.idkey === section.idkey
//       );

//       const filteredSubmenus = checkSubsection?.map((sub: any) => {
//         let foundSub = null;
//         sub?.submenus?.find((fil: any) => {
//           if (concatenatedSubArray.includes(fil?.idkey)) {
//             foundSub = fil?.idkey;
//             return true;
//           }
//           return false;
//         });
//         return foundSub;
//       });

//       // Close all other sections when opening a new one
//       const updatedDropIndex = dropIndex.includes(section.idkey)
//         ? dropIndex.filter((id) => id !== section.idkey)
//         : [section.idkey];

//       setDropIndex(updatedDropIndex);

//       const data = filteredSubmenus?.[0];
//       if (matchIdWithRoutes?.hasOwnProperty(data)) {
//         navigate({
//           pathname: `/${section.idkey}/${data}`,
//           search: createSearchParams({
//             id: `${matchIdWithRoutes[data]}`,
//           }).toString(),
//         });
//       } else {
//         navigate(`/${section.idkey}/${data}`);
//       }
//     } else {
//       navigate(`/${section.idkey}`);
//     }
//   };

//   const changeModalWidth = () => {
//     dispatch(showSideNavStatus(!sideNavOpenClose));
//     setDropIndex([]);
//     setOpenTab(false);
//   };

//   const subSectionsRoute = (section: any, subComp: any) => {
//     if (matchIdWithRoutes?.hasOwnProperty(subComp.idkey)) {
//       navigate({
//         pathname: `/${section.idkey}/${subComp.idkey}`,
//         search: createSearchParams({
//           id: `${matchIdWithRoutes[subComp.idkey]}`,
//         }).toString(),
//       });
//     } else {
//       navigate(`/${section.idkey}/${subComp.idkey}`);
//     }
//   };

//   const handleLogout = async () => {
//     try {
//       await SUPABASE_CLIENT.auth.signOut();
//       window.location.href = "/";
//     } catch (error) {
//       console.error("Error logging out", error);
//     }
//   };

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   const renderNavigation = (isMobile: boolean) => {
//     return (
//       <div
//         className={`
//           ${
//             !isMobile && sideNavOpenClose
//               ? "w-[80px] md:w-[10%] lg:w-[10%] xl:w-[5%]"
//               : !isMobile
//               ? "w-full md:w-[35%] lg:w-[30%] xl:w-[22%]"
//               : "w-full"
//           }
//           ${isMobile ? "block md:hidden" : "hidden md:flex"}
//           fixed md:relative top-0 left-0 h-full z-40
//           flex flex-col bg-white shadow-md shadow-[#00000029]
//           transition-all duration-300 ease-in-out
//         `}
//       >
//         {/* Header Section */}
//         <div className="flex items-center justify-between p-4 border-b">
//           {!isMobile && sideNavOpenClose ? (
//             <div className="w-[40px] h-[40px]"></div>
//           ) : (
//             <img
//               src={FITA_Logo}
//               alt="Logo"
//               className="w-[120px] h-[40px] object-contain select-none"
//             />
//           )}

//           {!isMobile && (
//             <img
//               src={openAndCloseNavbar}
//               alt="Toggle Navbar"
//               className={`
//                 w-8 h-8 cursor-pointer
//                 ${sideNavOpenClose && "rotate-180"}
//                 transition-transform duration-300
//               `}
//               onClick={changeModalWidth}
//             />
//           )}

//           {isMobile && (
//             <button
//               onClick={toggleMobileMenu}
//               className="text-2xl font-bold"
//             >
//               ✕
//             </button>
//           )}
//         </div>

//         {/* Search Section */}
//         <div className="p-4 border-b">
//           {!isMobile && sideNavOpenClose ? (
//             <img
//               src={searchIcon}
//               alt="Search"
//               className="w-[20px] h-[20px] cursor-pointer mx-auto"
//             />
//           ) : (
//             <div className="relative">
//               <input
//                 placeholder="Search..."
//                 name="search"
//                 value={searchRole}
//                 onChange={(e: any) => setSearchRole(e.target.value)}
//                 className="border border-[#707070] rounded-2xl h-[35px] w-full px-3 pr-10"
//                 onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
//                   if (event.key === "Enter") {
//                     setTriggerSearch(true);
//                     isMobile && toggleMobileMenu();
//                   }
//                 }}
//               />
//               <img
//                 src={searchIcon}
//                 alt="Search"
//                 className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
//               />
//             </div>
//           )}
//         </div>

//         {/* Navigation Menu Section */}
//         <div
//           className={`
//             flex-grow overflow-y-auto
//             ${!isMobile && !sideNavOpenClose ? "px-2" : ""}
//             scrollbar-hide scroll-smooth
//           `}
//         >
//           {checkSearchDataIsAvailable &&
//             checkSearchDataIsAvailable?.map((section: any, index: number) => {
//               if (mapMainComponent?.includes(section?.idkey)) {
//                 return (
//                   <div key={index} className="w-full">
//                     <div
//                       className={`
//                         w-full select-none flex items-center justify-between
//                         rounded-[2px] cursor-pointer px-2 py-3
//                         ${
//                           section.idkey.includes(dashboardScreen) ||
//                           (!dashboardScreen &&
//                             section["idkey"] === "dashboard") ||
//                           (section?.submenus &&
//                             section?.submenus?.[index]?.idkey.includes(
//                               subsection
//                             ))
//                             ? "bg-[#036AD1] text-white"
//                             : "text-[#707070] hover:bg-gray-100"
//                         }
//                       `}
//                       onClick={() => {
//                         SelectedDivision(section);
//                         isMobile && toggleMobileMenu();
//                       }}
//                     >
//                       <div className="flex items-center gap-2">
//                         <img
//                           src={
//                             section.idkey.includes(dashboardScreen)
//                               ? ImageListForUnselected[section.idkey]
//                               : ImageListForSelected[section.idkey]
//                           }
//                           alt={`${section.idkey}`}
//                           className="w-[20px] h-[20px]"
//                         />
//                         {(!isMobile && !sideNavOpenClose) || isMobile ? (
//                           <h1 className="text-[14px] font-medium">
//                             {section.label}
//                           </h1>
//                         ) : null}
//                       </div>
//                       {(!isMobile && !sideNavOpenClose &&
//                         section?.submenus?.length !== 0 &&
//                         section?.idkey !== "dashboard") ||
//                       (isMobile && section?.submenus?.length !== 0 &&
//                         section?.idkey !== "dashboard") ? (
//                         <img
//                           src={dropArrowBlack}
//                           alt="dropArrow"
//                           className={`w-4 h-4 ${
//                             dropIndex.includes(section?.idkey) && "rotate-90"
//                           }`}
//                         />
//                       ) : null}
//                     </div>

//                     {/* Submenu Section - Nested within main navigation */}
//                     {dropIndex.includes(section?.idkey) && section?.submenus && (
//                       <div
//                         className={`
//                           max-h-[200px] overflow-y-auto
//                           border-l-2 border-[#036AD1]
//                           transition-all duration-300 ease-in-out
//                         `}
//                       >
//                         {section?.submenus?.map(
//                           (subComp: any, subIndex: number) => {
//                             if (
//                               concatenatedSubArray.includes(subComp?.idkey) &&
//                               !DashboardSubMenus.includes(subComp?.idkey)
//                             ) {
//                               return (
//                                 <div
//                                   key={subIndex}
//                                   className={`
//                                   px-4 py-2 cursor-pointer
//                                   ${
//                                     subComp.idkey?.replace(/_/g, "-") ===
//                                       subsection?.replace(/_/g, "-") ||
//                                     (!subsection &&
//                                       subComp["idkey"] ===
//                                         subComp?.[0]?.["idkey"])
//                                       ? "bg-[#B4D9FF] text-[#000000]"
//                                       : "bg-white text-[#707070] hover:bg-gray-100"
//                                   }
//                                 `}
//                                   onClick={() => {
//                                     subSectionsRoute(section, subComp);
//                                     isMobile && toggleMobileMenu();
//                                   }}
//                                 >
//                                   <p className="font-Roboto font-[400] text-[13px] 2xl:text-[14px] pl-6">
//                                     {subComp.label}
//                                   </p>
//                                 </div>
//                               );
//                             }
//                             return null;
//                           }
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 );
//               }
//             })}
//         </div>

//         {/* Logout Section */}
//         <div
//           className="
//             flex items-center justify-center md:justify-start
//             p-4 border-t cursor-pointer
//             hover:bg-red-50 transition-colors
//           "
//           onClick={() => {
//             handleLogout();
//             isMobile && toggleMobileMenu();
//           }}
//         >
//           <img src={Log_Out} alt="Log_Out" className="w-5 h-5 mr-2" />
//           {(!isMobile && !sideNavOpenClose) || isMobile ? (
//             <h1 className="text-[14px] font-medium text-red-500">Logout</h1>
//           ) : null}
//         </div>
//       </div>
//     );
//   };

//   return (
//     <>
//       {/* Mobile Hamburger */}
//       <div className="md:hidden fixed top-0 left-0 right-0 z-50 bg-white p-4 flex justify-between items-center">
//         <img
//           src={FITA_Logo}
//           alt="Logo"
//           className="w-[120px] h-[40px] object-contain select-none"
//         />
//         <button
//           onClick={toggleMobileMenu}
//           className="hamburger-menu"
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//             className="w-6 h-6"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth={2}
//               d="M4 6h16M4 12h16M4 18h16"
//             />
//           </svg>
//         </button>
//       </div>

//       {/* Mobile Menu Overlay */}
//       {isMobileMenuOpen && renderNavigation(true)}

//       {/* Desktop Navigation */}
//       {renderNavigation(false)}
//     </>
//   );
// };

import React, { useState, useEffect } from "react";
import {
  ImageListForSelected,
  ImageListForUnselected,
} from "../LeftNavbar/jsonList";
import { useNavigate, useParams, createSearchParams } from "react-router-dom";
import openAndCloseNavbar from "../../Assets/openAndCloseNavbar.svg";
import { useAppDispatch, useAppSelector } from "../../index";
import { showSideNavStatus } from "../../Redux/Reducers/sideNavPosition";
import searchIcon from "../../Assets/searchIcon.svg";
import FITA_Logo from "../../Assets/FITA_Logo.png";
import dropArrowBlack from "../../Assets/dropArrow.svg";
import Log_Out from "../../Assets/Log_Out.svg";
import { SUPABASE_CLIENT } from "../../Services/Supabase";
import { searchMenus } from "../AdminManagement/RoleManagement/helper";
import { FunctionForPrivileges } from "../CommonFunctions/Privileges";
import { LoginUserDetails } from "../Header/LoginUserDetails";

export const LeftNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [openTab, setOpenTab] = useState(false);
  const [dropIndex, setDropIndex] = useState<string[]>([]);
  const [menuOptions, setMenuOptions] = useState([] as any);
  const [searchRole, setSearchRole] = useState("");
  const [searchResults, setSearchResults] = useState([] as any);
  const [isSearching, setIsSearching] = useState(false);
  const { dashboardScreen, subsection }: any = params;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const DashboardSubMenus = [
    "admissions",
    "invoices&revenue",
    "operations",
    "batch",
    "session_feedback_dashboard",
    "feedback_concern",
    "placement",
  ];

  const { mapMainComponent, concatenatedSubArray, ForSubDomainsNew }: any =
    FunctionForPrivileges();

  const [{ sideNavOpenClose }, { selectedRoles }] = useAppSelector(
    ({ navStatus, roles }: any) => [navStatus, roles["userRoles"]]
  );

  const checkSearchDataIsAvailable = searchResults?.length
    ? searchResults
    : menuOptions?.length
    ? menuOptions
    : selectedRoles;

  const matchIdWithRoutes: any = {
    manage_courses: "by_course",
    pending_payment: "by_student",
    daily_transactions: "unverified",
    student_actions: "students_action_queue",
    student_actions_approval_queue: "current_approval_queue",
    issue_refund_adjust_payment: "issue_refund",
    student_attendance: "absent",
    cancellation_session: "verified_comments",
    operational_report: "batch",
    session_feedback: "all_sessions",
    process_trainer_payment: "all_data",
    student_feedback_concerns: "student_concern",
    cancelled_session: "yet_to_speak",
    resume_review_dashboard: "training_data",
  };

  useEffect(() => {
    (async () => {
      setDropIndex([...dropIndex, dashboardScreen]);
      const response = await searchMenus(selectedRoles, searchRole);
      setMenuOptions(response);
    })();
  }, []);

  useEffect(() => {
    const performSearch = async () => {
      if (searchRole.trim() === "") {
        setSearchResults([]);
        setIsSearching(false);
        return;
      }

      setIsSearching(true);
      try {
        const results = await searchMenus(selectedRoles, searchRole);
        setSearchResults(results);
      } catch (error) {
        console.error("Search error:", error);
        setSearchResults([]);
      } finally {
        setIsSearching(false);
      }
    };

    // Debounce search to reduce unnecessary API calls
    const debounceTimer = setTimeout(performSearch, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchRole, selectedRoles]);

  const SelectedDivision = (section: any) => {
    if (section?.submenus?.length > 0) {
      const checkSubsection = ForSubDomainsNew?.filter(
        (sub: any) => sub?.idkey === section.idkey
      );

      const filteredSubmenus = checkSubsection?.map((sub: any) => {
        let foundSub = null;
        sub?.submenus?.find((fil: any) => {
          if (concatenatedSubArray.includes(fil?.idkey)) {
            foundSub = fil?.idkey;
            return true;
          }
          return false;
        });
        return foundSub;
      });

      // Close all other sections when opening a new one
      const updatedDropIndex = dropIndex.includes(section.idkey)
        ? dropIndex.filter((id) => id !== section.idkey)
        : [section.idkey];

      setDropIndex(updatedDropIndex);

      const data = filteredSubmenus?.[0];
      if (matchIdWithRoutes?.hasOwnProperty(data)) {
        navigate({
          pathname: `/${section.idkey}/${data}`,
          search: createSearchParams({
            id: `${matchIdWithRoutes[data]}`,
          }).toString(),
        });
      } else {
        navigate(`/${section.idkey}/${data}`);
      }
    } else {
      navigate(`/${section.idkey}`);
    }
  };

  const changeModalWidth = () => {
    dispatch(showSideNavStatus(!sideNavOpenClose));
    setDropIndex([]);
    setOpenTab(false);
  };

  const subSectionsRoute = (section: any, subComp: any) => {
    if (matchIdWithRoutes?.hasOwnProperty(subComp.idkey)) {
      navigate({
        pathname: `/${section.idkey}/${subComp.idkey}`,
        search: createSearchParams({
          id: `${matchIdWithRoutes[subComp.idkey]}`,
        }).toString(),
      });
    } else {
      navigate(`/${section.idkey}/${subComp.idkey}`);
    }
  };

  const handleLogout = async () => {
    try {
      await SUPABASE_CLIENT.auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.error("Error logging out", error);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const renderNavigation = (isMobile: boolean) => {
    return (
      <div
        className={`
          ${
            !isMobile && sideNavOpenClose
              ? "w-[80px] md:w-[10%] lg:w-[10%] xl:w-[5%]"
              : !isMobile
              ? "w-full md:w-[35%] lg:w-[30%] xl:w-[22%]"
              : "w-full"
          } 
          ${isMobile ? "block md:hidden" : "hidden md:flex"}
          fixed md:relative top-0 left-0 h-full z-40
          flex flex-col bg-white shadow-md shadow-[#00000029]
          transition-all duration-300 ease-in-out
        `}
      >
        {/* Header Section */}
        <div className="flex items-center justify-between p-4 border-b">
          {!isMobile && sideNavOpenClose ? (
            <div className="w-[40px] h-[40px]"></div>
          ) : (
            <img
              src={FITA_Logo}
              alt="Logo"
              className="w-[120px] h-[40px] object-contain select-none"
            />
          )}

          {!isMobile && (
            <img
              src={openAndCloseNavbar}
              alt="Toggle Navbar"
              className={`
                w-8 h-8 cursor-pointer
                ${sideNavOpenClose && "rotate-180"}
                transition-transform duration-300
              `}
              onClick={changeModalWidth}
            />
          )}

          {isMobile && (
            <button onClick={toggleMobileMenu} className="text-2xl font-bold">
              ✕
            </button>
          )}
        </div>

        {/* Search Section */}
        <div className="p-4 border-b">
          {!isMobile && sideNavOpenClose ? (
            <img
              src={searchIcon}
              alt="Search"
              className="w-[20px] h-[20px] cursor-pointer mx-auto"
            />
          ) : (
            <div className="relative">
              <input
                placeholder="Search..."
                name="search"
                value={searchRole}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchRole(e.target.value);
                }}
                className="border border-[#707070] rounded-2xl h-[35px] w-full px-3 pr-10"
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === "Enter") {
                    isMobile && toggleMobileMenu();
                  }
                }}
              />
              {isSearching && (
                <div className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-500">
                  Loading...
                </div>
              )}
              <img
                src={searchIcon}
                alt="Search"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
            </div>
          )}
        </div>

        {/* Navigation Menu Section */}
        <div
          className={`
            flex-grow overflow-y-auto
            ${!isMobile && !sideNavOpenClose ? "px-2" : ""}
            scrollbar-hide scroll-smooth
          `}
        >
          {checkSearchDataIsAvailable &&
            checkSearchDataIsAvailable?.map((section: any, index: number) => {
              if (mapMainComponent?.includes(section?.idkey)) {
                return (
                  <div key={index} className="w-full">
                    <div
                      className={`
                        w-full select-none flex items-center justify-between 
                        rounded-[2px] cursor-pointer px-2 py-3
                        ${
                          section.idkey.includes(dashboardScreen) ||
                          (!dashboardScreen &&
                            section["idkey"] === "dashboard") ||
                          (section?.submenus &&
                            section?.submenus?.[index]?.idkey.includes(
                              subsection
                            ))
                            ? "bg-[#036AD1] text-white"
                            : "text-[#707070] hover:bg-gray-100"
                        }
                      `}
                      onClick={() => {
                        SelectedDivision(section);
                        isMobile && toggleMobileMenu();
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <img
                          src={
                            section.idkey.includes(dashboardScreen)
                              ? ImageListForUnselected[section.idkey]
                              : ImageListForSelected[section.idkey]
                          }
                          alt={`${section.idkey}`}
                          className="w-[20px] h-[20px]"
                        />
                        {(!isMobile && !sideNavOpenClose) || isMobile ? (
                          <h1 className="text-[14px] font-medium">
                            {section.label}
                          </h1>
                        ) : null}
                      </div>
                      {(!isMobile &&
                        !sideNavOpenClose &&
                        section?.submenus?.length !== 0 &&
                        section?.idkey !== "dashboard") ||
                      (isMobile &&
                        section?.submenus?.length !== 0 &&
                        section?.idkey !== "dashboard") ? (
                        <img
                          src={dropArrowBlack}
                          alt="dropArrow"
                          className={`w-4 h-4 ${
                            dropIndex.includes(section?.idkey) && "rotate-90"
                          }`}
                        />
                      ) : null}
                    </div>

                    {/* Submenu Section - Nested within main navigation */}
                    {dropIndex.includes(section?.idkey) &&
                      section?.submenus && (
                        <div
                          className={`
                          max-h-[200px] overflow-y-auto 
                          border-l-2 border-[#036AD1] 
                          transition-all duration-300 ease-in-out
                        `}
                        >
                          {section?.submenus?.map(
                            (subComp: any, subIndex: number) => {
                              if (
                                concatenatedSubArray.includes(subComp?.idkey) &&
                                !DashboardSubMenus.includes(subComp?.idkey)
                              ) {
                                return (
                                  <div
                                    key={subIndex}
                                    className={`
                                  px-4 py-2 cursor-pointer 
                                  ${
                                    subComp.idkey?.replace(/_/g, "-") ===
                                      subsection?.replace(/_/g, "-") ||
                                    (!subsection &&
                                      subComp["idkey"] ===
                                        subComp?.[0]?.["idkey"])
                                      ? "bg-[#B4D9FF] text-[#000000]"
                                      : "bg-white text-[#707070] hover:bg-gray-100"
                                  }
                                `}
                                    onClick={() => {
                                      subSectionsRoute(section, subComp);
                                      isMobile && toggleMobileMenu();
                                    }}
                                  >
                                    <p className="font-Roboto font-[400] text-[13px] 2xl:text-[14px] pl-6">
                                      {subComp.label}
                                    </p>
                                  </div>
                                );
                              }
                              return null;
                            }
                          )}
                        </div>
                      )}
                  </div>
                );
              }
            })}
        </div>

        {/* Logout Section */}
        <div
          className="
            flex items-center justify-center md:justify-start
            p-4 border-t cursor-pointer 
            hover:bg-red-50 transition-colors
          "
          onClick={() => {
            handleLogout();
            isMobile && toggleMobileMenu();
          }}
        >
          <img src={Log_Out} alt="Log_Out" className="w-5 h-5 mr-2" />
          {(!isMobile && !sideNavOpenClose) || isMobile ? (
            <h1 className="text-[14px] font-medium text-red-500">Logout</h1>
          ) : null}
        </div>
      </div>
    );
  };
  
  return (
    <>
      {/* Mobile Hamburger */}
      <div className="md:hidden fixed top-0 left-0 right-0 z-50 bg-white p-4 flex justify-between items-center">
        <img
          src={FITA_Logo}
          alt="Logo"
          className="w-[120px] h-[40px] object-contain select-none"
        />
        <button onClick={toggleMobileMenu} className="hamburger-menu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && renderNavigation(true)}

      {/* Desktop Navigation */}
      {renderNavigation(false)}
    </>
  );
};
