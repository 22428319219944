import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Components } from "./Imports";
import { useAppSelector } from "../..";
import { SelectAllOptionsForUsersByDefault } from "../../functions/admin/SelectAllUsers";
import { PulseLoader } from "react-spinners";

// export const RightSideScreens = () => {
//   const {
//     useParams,
//     AdminPanelDashboard,
//     AdminManagement,
//     AllStudents,
//     ContentManagement,
//     StudentManagement,
//     BatchManagement,
//     CourseProgress,
//     ActionCenter,
//     HrStudentPlacement,
//     Logs,
//     AppEnquiries,
//     Reports,
//   }: any = Components;
//   const params = useParams();
//   const routeName: any = params.dashboardScreen;
//   const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

//   const screens: any = {
//     dashboard: <AdminPanelDashboard />,
//     admin_management: <AdminManagement />,
//     all_students: <AllStudents />,
//     content_management: <ContentManagement />,
//     student_management: <StudentManagement />,
//     batch_management: <BatchManagement />,
//     course_progress: <CourseProgress />,
//     action_center: <ActionCenter />,
//     hr_student_placement: <HrStudentPlacement />,
//     reports: <Reports />,
//     logs: <Logs />,
//     app_enquiries: <AppEnquiries />,
//   };

//   useEffect(() => {
//     (async () => {
//       await SelectAllOptionsForUsersByDefault();
//     })();
//   }, [screens?.admin_management, screens?.content_management]);

//   return (
//     <div className="w-full absolute top-[100px] z-10 pb-10">
//       {signInData?.data?.length > 0 &&
//         (screens[routeName] || <AdminPanelDashboard />)}
//     </div>
//   );
// };

{/* <div className="w-full z-10">
  <Header />
  {screens[routeName] || <AdminPanelDashboard />}
</div>; */}

export const RightSideScreens = () => {
  const {
    AdminPanelDashboard,
    AdminManagement,
    AllStudents,
    ContentManagement,
    StudentManagement,
    BatchManagement,
    CourseProgress,
    ActionCenter,
    HrStudentPlacement,
    Logs,
    AppEnquiries,
    Reports,
  }: any = Components;

  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const routeName: any = params.dashboardScreen;
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  const screens: any = {
    dashboard: <AdminPanelDashboard />,
    admin_management: <AdminManagement />,
    all_students: <AllStudents />,
    content_management: <ContentManagement />,
    student_management: <StudentManagement />,
    batch_management: <BatchManagement />,
    course_progress: <CourseProgress />,
    action_center: <ActionCenter />,
    hr_student_placement: <HrStudentPlacement />,
    reports: <Reports />,
    logs: <Logs />,
    app_enquiries: <AppEnquiries />,
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await SelectAllOptionsForUsersByDefault();
      setIsLoading(false);
    })();
  }, []);

  return (
    <div className="w-full absolute top-[100px] z-10 pb-10">
      {signInData?.data?.length > 0 ? (
        <div className="w-full h-full">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <div className="spinner-container">
                <PulseLoader color="#B4D9FF" size={15} />
              </div>
            </div>
          ) : (
            <div className="p-4 w-full h-full overflow-auto">
              {screens[routeName] || screens.dashboard}
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <p className="text-gray-500">
            Please sign in to access the dashboard
          </p>
        </div>
      )}
    </div>
  );
};