// import React, { useState, useEffect } from "react";
// import { ReportJsonData } from "./ReportJsonData";
// import { renderContent } from "./TableFetchDetails";
// import Pagination from "../../../Components/Elements/Table/Pagination";
// import { tableDataForMISReport } from "../../../functions/admin/Reports/Reports";
// import { useAppSelector } from '../../..';

// export const ReportTable = ({ selectedTab, response }: any) => {
//   const [searchQuery, setSearchQuery] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 5; // Number of rows per page
//   const [storeBranch, setStoreBranch] = useState<string[]>(["S.No", "Course"]);

//   const HeaderStat = ReportJsonData[selectedTab] || [];
//   const body_style = `font-Roboto font-[500] py-4 text-[16px] text-center text-[#000000] border-2 border-blue-100`;
//   const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

//   useEffect(()=>{
//     (async()=>{
//       const response:any = await tableDataForMISReport(signInData?.data[0]); // API call
//       const branches = response?.branch?.map((item: any) => item.branch_name) || []; // Extract branch names
//       setStoreBranch(["S.No", "Course", ...branches]);
//       console.log(["S.No", "Course", ...branches], selectedTab)
//     })()
//   },[])

//   // Render content based on search query and selected tab
//   const allContent = renderContent(selectedTab, response, body_style, searchQuery);

//   if (!Array.isArray(allContent)) {
//     console.error("renderContent did not return an array. Please check the implementation.");
//     return null;
//   }

//   // Calculate total pages
//   const totalPages = Math.ceil(allContent.length / itemsPerPage);

//   // Paginate the content
//   const paginatedContent = allContent.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

//   // Handle pagination
//   const handlePageChange = (newPage: number) => {
//     setCurrentPage(newPage);
//   };

//   const generateTableData = () => {
//     const uniqueCourses = new Set<string>();

//     // 1️⃣ Extract unique course names from all branches
//     Object.values(response?.coursewise_registration).forEach((branch: any) => {
//       branch.course_name_list.forEach((course: string) => uniqueCourses.add(course));
//     });

//     // 2️⃣ Convert set to array
//     const coursesArray = Array.from(uniqueCourses);

//     // 3️⃣ Generate table rows
//     return coursesArray.map((course, rowIndex) => {
//       return (
//         <tr key={rowIndex} className="text-center border-b border-blue-200">
//           {/* 1st Column: S.No */}
//           <td className="py-2 px-4">{rowIndex + 1}</td>

//           {/* 2nd Column: Course Name */}
//           <td className="py-2 px-4">{course}</td>

//           {/* Dynamic Branch Columns */}
//           {storeBranch.slice(2).map((branch, colIndex) => {
//             // 4️⃣ Check if this branch contains the course
//             const branchData = response?.coursewise_registration[branch];
//             const traineeCount = branchData?.course_name_list.includes(course)
//               ? branchData.course_name_list.filter((c: string) => c === course).length // Counting occurrences (if needed)
//               : 0;

//             return (
//               <td key={colIndex} className="py-2 px-4">
//                 {traineeCount > 0 ? traineeCount : "-"}
//               </td>
//             );
//           })}
//         </tr>
//       );
//     });
//   };

//   return (
//     <div className="w-full max-w-7xl mx-auto p-4 relative overflow-x-auto">
//       {/* Search Input */}
//       <div className="py-4 flex justify-between items-center">
//         <input
//           type="text"
//           placeholder="Search..."
//           className="border p-2 rounded w-full max-w-md focus:ring-2 focus:ring-blue-500"
//           value={searchQuery}
//           onChange={(e) => {
//             setSearchQuery(e.target.value);
//           }}
//         />
//       </div>
//       {/* Table */}
//       <div className="overflow-x-auto bg-white shadow-md rounded-lg">
//         <table className="w-full min-w-max">
//           <thead>
//             <tr className="bg-blue-100 text-center text-gray-700">
//               {(selectedTab==="Coursewise Registration" ? storeBranch : HeaderStat)?.map((stat:any, index:number) => (
//                 <th
//                   className="font-medium py-3 px-4 border-b border-blue-200"
//                   key={index}
//                 >
//                   {stat}
//                 </th>
//               ))}
//             </tr>
//           </thead>
//           <tbody className="bg-blue-50 text-gray-700 text-sm md:text-base">
//             {selectedTab==="Coursewise Registration" ? generateTableData() : paginatedContent}
//           </tbody>
//         </table>
//       </div>
//       {/* Pagination */}
//       <div className="flex justify-center mt-4">
//         <Pagination
//           totalPages={totalPages}
//           totalItems={allContent.length}
//           currentPage={currentPage}
//           onPageChange={handlePageChange}
//         />
//       </div>
//     </div>
//   );
// };

import React, { useState, useEffect } from "react";
import { ReportJsonData } from "./ReportJsonData";
import { renderContent } from "./TableFetchDetails";
import Pagination from "../../../Components/Elements/Table/Pagination";
import { tableDataForMISReport } from "../../../functions/admin/Reports/Reports";
import { useAppSelector } from "../../..";

export const ReportTable = ({
  selectedTab,
  response = {},
}: {
  selectedTab: string;
  response?: any;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [storeBranch, setStoreBranch] = useState<string[]>(["S.No", "Course"]);
  const [filteredCourses, setFilteredCourses] = useState<any[]>([]);

  const HeaderStat = ReportJsonData[selectedTab] || [];
  const body_style = `font-Roboto font-[500] py-4 text-[16px] text-center text-[#000000] border-2 border-blue-100`;
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  /** 🔹 Fetch Branch Names on Mount */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: any = await tableDataForMISReport(signInData?.data[0]);
        if (res?.branch) {
          setStoreBranch([
            "S.No",
            "Course",
            ...res.branch.map((item: any) => item.branch_name),
          ]);
        }
      } catch (error) {
        console.error("Error fetching branch data:", error);
      }
    };
    fetchData();
  }, [signInData]);

  /** 🔹 Generate Course-wise Registration Table */
  useEffect(() => {
    if (
      selectedTab !== "Coursewise Registration" ||
      !response?.coursewise_registration
    )
      return;

    const uniqueCourses = new Set<string>();

    // Extract unique course names from all branches
    Object.values(response.coursewise_registration).forEach((branch: any) => {
      branch.course_name_list?.forEach((course: any) =>
        uniqueCourses.add(course.course_name)
      );
    });

    let coursesArray = Array.from(uniqueCourses);

    // 🔹 Apply Search Filter (for Coursewise Registration)
    if (searchQuery.trim() !== "") {
      coursesArray = coursesArray.filter((course) =>
        course.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    const tableRows = coursesArray.map((course, rowIndex) => ({
      id: rowIndex + 1,
      courseName: course,
      branchData: storeBranch.slice(2).map((branch) => {
        const branchData = response.coursewise_registration[branch];

        const courseData = branchData?.course_name_list?.find(
          (c: any) => c.course_name === course
        );
        return courseData ? courseData.trainee_count : "-";
      }),
    }));

    setFilteredCourses(tableRows);
  }, [response, selectedTab, storeBranch, searchQuery]); // Added searchQuery dependency

  // Handle pagination
  const totalPages = Math.ceil(filteredCourses.length / itemsPerPage);
  const paginatedCourses = filteredCourses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage: number) => setCurrentPage(newPage);

  // Safe response handling
  const safeResponse = response ?? {};
  const allContent = safeResponse
    ? renderContent(selectedTab, safeResponse, body_style, searchQuery) || []
    : [];

  return (
    <div className="w-full max-w-7xl mx-auto p-4 relative overflow-x-auto">
      {/* Search Input */}
      <div className="py-4 flex justify-between items-center">
        <input
          type="text"
          placeholder={
            selectedTab === "Coursewise Registration"
              ? "Search Course..."
              : "Search..."
          }
          className="border p-2 rounded w-full max-w-md focus:ring-2 focus:ring-blue-500"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Table */}
      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="w-full min-w-max">
          <thead>
            <tr className="bg-blue-100 text-center text-gray-700">
              {(selectedTab === "Coursewise Registration"
                ? storeBranch
                : HeaderStat
              ).map((stat: any, index: number) => (
                <th
                  key={index}
                  className="font-medium py-3 px-4 border-b border-blue-200"
                >
                  {stat}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-blue-50 text-gray-700 text-sm md:text-base">
            {selectedTab === "Coursewise Registration"
              ? paginatedCourses.map((row, index) => (
                  <tr
                    key={index}
                    className="text-center border-b border-blue-200"
                  >
                    <td className="py-2 px-4">{row.id}</td>
                    <td className="py-2 px-4">{row.courseName}</td>
                    {row.branchData.map((count: any, colIndex: number) => (
                      <td key={colIndex} className="py-2 px-4">
                        {count}
                      </td>
                    ))}
                  </tr>
                ))
              : allContent
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((content, index) => (
                    <React.Fragment key={index}>{content}</React.Fragment>
                  ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-end mt-4">
        <Pagination
          totalPages={totalPages}
          totalItems={filteredCourses.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
