import { useState } from "react";
import OpenEye from "../../Assets/OpenEye.svg";
import CloseEye from "../../Assets/CloseEye.svg";

// export const CommonInput = ({ onPaste,onCopy,openEyeStyle, closeEyeStyle, label, type, placeholderName, labelStyle, inputStyle, isTouched, error, ...rest }: any) => {
//     const isError = isTouched && error;
//     const [passwordType, setPasswordType] = useState(false);
//     return (
//       <div className="w-11/12 relative">
//         <div className="w-full flex flex-col space-y-1">
//           <label className={`${labelStyle} font-Roboto font-medium`}>
//             {label}
//           </label>
//           <div className="relative">
//             <input
//               type={passwordType ? "text" : type}
//               placeholder={placeholderName}
//               onCopy={type === "password" && onCopy}
//               onPaste={type === "password" && onPaste}
//               {...rest}
//               className={`${inputStyle} w-full rounded-[3px] px-2 outline-none`}
//             />
//             {type === "password" &&
//               (passwordType ? (
//                 <img
//                   src={OpenEye}
//                   alt="OpenEye"
//                   className={`w-7 h-7 absolute right-4 ${
//                     openEyeStyle ? openEyeStyle : "top-2"
//                   } cursor-pointer`}
//                   onClick={() => setPasswordType(false)}
//                 />
//               ) : (
//                 <img
//                   src={CloseEye}
//                   alt="OpenEye"
//                   className={`w-7 h-7 absolute right-4 ${
//                     closeEyeStyle ? closeEyeStyle : "top-2"
//                   } cursor-pointer`}
//                   onClick={() => setPasswordType(true)}
//                 />
//               ))}
//           </div>
//         </div>
//         {isError && (
//           <p className="text-red-500 text-[12px] absolute right-2 bottom-[-10]">
//             {error}
//           </p>
//         )}
//       </div>
//     );
// }

export const CommonInput = ({
  onPaste,
  onCopy,
  openEyeStyle,
  closeEyeStyle,
  label,
  type,
  placeholderName,
  labelStyle,
  inputStyle,
  isTouched,
  error,
  ...rest
}: any) => {
  const isError = isTouched && error;
  const [passwordType, setPasswordType] = useState(false);
  
  return (
    <div className="w-11/12 relative">
      <div className="w-full flex flex-col space-y-1">
        <label className={`${labelStyle} font-Roboto font-medium text-gray-700`}>{label}</label>
        <div className="relative">
          <input
            type={passwordType ? "text" : type}
            placeholder={placeholderName}
            onCopy={type === "password" && onCopy}
            onPaste={type === "password" && onPaste}
            {...rest}
            className={`${inputStyle} w-full rounded-md px-3 py-2 outline-none border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-300 transition-all pr-10`}
          />
          {type === "password" && (
            <img
              src={passwordType ? OpenEye : CloseEye}
              alt={passwordType ? "OpenEye" : "CloseEye"}
              className={`w-6 h-6 absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer opacity-80 hover:opacity-100 transition-opacity`}
              onClick={() => setPasswordType(!passwordType)}
            />
          )}
        </div>
      </div>
      {isError && (
        <p className="text-red-500 text-[12px] absolute right-2 -bottom-4">{error}</p>
      )}
    </div>
  );
};