import { TableNames } from "../../config/Tables";
import { FetchData } from "../../crud";
import {
  errorNotifier,
  discountBasedOnRange,
  calculateDayDifference,
} from "../../commonHelper";
import { dateFormat } from "../../../Services/CommonFunctions";

function isDateGreaterThanOrEqualToCurrent(givenDateStr: any) {
  const givenDate = new Date(givenDateStr);
  const currentDate = new Date();
  return givenDate >= currentDate;
}

export const tableDataForMISReport = async (UsersDetails: any) => {
  try {
    const { data: branch_data, error: branch_error } = await FetchData(
      TableNames.branches,
      ["id", "branch_name"]
    );
    const { data: coursewise_reg, error: coursewise_reg_error } =
      await FetchData(TableNames.trainings, [
        "branch:branches(id,branch_name,ownership)",
        "course:courses(id, course_name)",
      ]);
    const { data: admissions, error: ad_error } = await FetchData(
      TableNames.trainings,
      ["branch:branches(id,branch_name,ownership)", "student:students(type)"]
    );
    console.log(admissions, "admissions");
    const { data: transactionsData, error: transactionError }: any =
      await FetchData(TableNames.transactions, [
        "id",
        "training_id",
        "created_at",
        "paid_amount",
        "training:trainings(mode, total_fee, branch:branches(id,branch_name,ownership))",
      ]).in("trainings.branch_id", UsersDetails?.branches);
    const { data: operations, error: op_error } = await FetchData(
      TableNames.trainings,
      ["id", "status", "branch:branches(id,branch_name,ownership)"]
    );
    const { data: batch, error: batch_error } = await FetchData(
      TableNames.batches,
      ["id", "status", "branch:branches(id,branch_name,ownership)"]
    );
    const { data: session_feedback, error: session_feedback_error } =
      await FetchData(TableNames.feedbacks, [
        "id",
        "response",
        "training:trainings(branch:branches(id,branch_name,ownership))",
      ]);
    const {
      data: studentConcern_feedback,
      error: studentConcern_feedback_error,
    } = await FetchData(TableNames.student_concern_feedback, [
      "id",
      "status",
      "training:trainings(branch:branches(id,branch_name,ownership))",
    ]);
    // const { data: missed_revenue, error: missed_revenue_error } = await FetchData(TableNames.)
    const { data: refunds, error: refund_error } = await FetchData(
      TableNames.refunds,
      [
        "amount",
        "training:trainings(branch:branches(id,branch_name,ownership))",
      ]
    );
    const { data: nullify, error: nullify_error } = await FetchData(
      TableNames.approval_queue,
      [
        "status",
        "approvalStatus",
        "training:trainings(transaction:transactions(paid_amount), total_fee, branch:branches(id, branch_name, ownership))",
      ]
    )
      .eq("status", "Nullify Payment")
      .not("approved_date", "is", null);
    const { data: drop_students, error: drop_students_error } = await FetchData(
      TableNames.approval_queue,
      [
        "status",
        "approvalStatus",
        "training:trainings(balance_due, branch:branches(id, branch_name, ownership))",
      ]
    )
      .eq("status", "Drop")
      .not("approved_date", "is", null);
    const { data: placements, error: placements_error } = await FetchData(
      TableNames.student_jobs,
      [
        "training:trainings(branch:branches(id,branch_name,ownership))",
        "job:jobs(no_of_openings, job_role)",
        "shortlisted",
        "placed",
        "joined",
        "status",
      ]
    );

    // const { data: unallocated_students, error: unallocated_students_error } =
    //   await FetchData(TableNames.trainings, [
    //     "created_at",
    //     "status",
    //     "batch_id",
    //     "branch:branches(id, branch_name, ownership)",
    //   ]).eq("status", "Unallocated");

    // const { data: batchInfo, error: batchInfo_error } = await FetchData(
    //   TableNames.batches,
    //   [
    //     "planned_date_of_completion",
    //     "session:sessions(*)",
    //     "branch:branches(id, branch_name, ownership)",
    //   ]
    // );
    // const { data: cancellation, error: cancellation_error } = await FetchData(
    //   TableNames.cancelled_sessions,
    //   [
    //     "comments: cancellation_followup(comment)",
    //     "batch:batches(branch:branches(id, branch_name, ownership))",
    //   ]
    // );
    // const { data: student_absent, error: student_absent_error } =
    //   await FetchData(TableNames.trainings, [
    //     "comments: student_absent_followups(comment)",
    //     "branch:branches(id, branch_name, ownership)",
    //   ]);
    // const { data: pendingPayment, error: pendingPayment_error } = await FetchData(TableNames.trainings, ["student:students(logs:user_logs(changes, module))"]);
    // const { data: paymentOverdue, error: paymentOverdue_error } =
    //   await FetchData(TableNames.trainings, [
    //     "total_fee",
    //     "duration",
    //     "balance_due",
    //     "branch:branches(id, branch_name, ownership)",
    //   ]);
    const { data: deviation_report, error: deviation_report_error } =
      await FetchData(TableNames.trainings, [
        "*",
        "id",
        "mode",
        "created_at",
        "city_id",
        "branch_id",
        "course_id",
        "total_fee",
        "duration",
        "balance_due",
        "branch:branches(id, branch_name, ownership)",
        "status",
        "batch:batches(status, cancelled_sessions(id), feedbacks(status))",
        "student_concern_feedback(status)",
      ]);

    const { data: users, error: userError } = await FetchData(
      TableNames.users,
      ["first_name", "last_name", "courses", "cities", "branches", "user_type"]
    );

    const processDeviationReport = (deviation_report: any, users: any) => {
      const result = deviation_report.reduce((acc: any, fil: any) => {
        const hourly_rate = fil?.total_fee / fil?.duration;
        const paid_amount = fil?.total_fee - fil?.balance_due;
        const attended_hours = paid_amount / hourly_rate;
        const calculationForPaidAmount = hourly_rate * attended_hours * 1.25;

        // Filter logic for payment overdue
        const isPaymentOverdue = calculationForPaidAmount >= paid_amount;

        // Find all matching users
        const matchingUsers = users?.filter(
          (dt: any) =>
            dt?.cities?.includes(fil?.city_id) &&
            dt?.branches?.includes(fil?.branch_id) &&
            dt?.courses?.includes(fil?.course_id) &&
            dt?.user_type === "BC"
        );

        matchingUsers?.forEach((matchingUser: any) => {
          const branchName = fil?.branch?.branch_name;
          const userName = `${matchingUser?.first_name} ${matchingUser?.last_name}`;
          const key = `${branchName}_${userName}`;

          if (!acc[key]) {
            acc[key] = {
              branchName,
              userName,
              count: 0,
              overdueCount: 0,
              unallocated: 0,
              batch_not_started: 0,
              cancelled_sessions: 0,
              pending_payment: 0,
              student_feedback: 0,
              session_feedback: 0,
            };
          }

          acc[key].count += 1;
          if (isPaymentOverdue) acc[key].overdueCount += 1;
          if (fil?.status === "Unallocated") acc[key].unallocated += 1;
          if (fil?.batch?.status === "Upcoming")
            acc[key].batch_not_started += 1;
          acc[key].cancelled_sessions +=
            fil?.batch?.cancelled_sessions?.length || 0;
          if (Math.round(Number(fil?.balance_due)) !== 0)
            acc[key].pending_payment += 1;
          acc[key].student_feedback +=
            fil?.student_concern_feedback?.filter(
              (feedback: any) => feedback?.status !== "Resolved"
            )?.length || 0;
          acc[key].session_feedback +=
            fil?.batch?.feedbacks?.filter((fb: any) => fb?.status !== "Absent")
              ?.length || 0;
        });

        return acc;
      }, {});

      // Convert the object into an array for structured output
      return Object.values(result);
    };

    const processedData = processDeviationReport(deviation_report, users);
    // console.log(processedData, users, "users");

    if (
      branch_error ||
      coursewise_reg_error ||
      ad_error ||
      transactionError ||
      op_error ||
      batch_error ||
      session_feedback_error ||
      studentConcern_feedback_error ||
      refund_error ||
      nullify_error ||
      drop_students_error ||
      placements_error ||
      deviation_report_error ||
      userError
    )
      throw (
        branch_error ||
        coursewise_reg_error ||
        ad_error ||
        transactionError ||
        op_error ||
        batch_error ||
        session_feedback_error ||
        studentConcern_feedback_error ||
        refund_error ||
        nullify_error ||
        drop_students_error ||
        placements_error ||
        deviation_report_error ||
        userError
      );

    const coursewise_registration = coursewise_reg?.reduce(
      (acc: any, item: any) => {
        const {
          branch: { branch_name, ownership },
          course: { course_name },
        } = item;

        if (!acc[branch_name]) {
          acc[branch_name] = {
            branch_name,
            ownership,
            course_name_list: [], // ✅ Store course_name & trainee_count per course
          };
        }

        // ✅ Find if the course already exists in course_name_list
        const existingCourse = acc[branch_name].course_name_list.find(
          (course: any) => course.course_name === course_name
        );

        if (existingCourse) {
          existingCourse.trainee_count++; // ✅ Increment trainee_count if course exists
        } else {
          acc[branch_name].course_name_list.push({
            course_name,
            trainee_count: 1, // ✅ Start trainee_count from 1
          });
        }

        return acc;
      },
      {}
    );

    // const filterStudentsForUnallocatedData = unallocated_students?.filter(
    //   (fil: any) => {
    //     const targetDate = dateFormat(fil?.created_at, "DD-MM-YYYY");
    //     const fourteenDaysLater = new Date(targetDate);
    //     fourteenDaysLater.setDate(fourteenDaysLater.getDate() + 14);
    //     const hasCrossedFourteenDays = new Date() > fourteenDaysLater;
    //     if (hasCrossedFourteenDays && fil?.batch_id === null) return fil;
    //   }
    // );

    // const filterBatchesBasedOnExpectations = batchInfo?.filter((fil: any) => {
    //   const targetDate = dateFormat(
    //     fil?.planned_date_of_completion,
    //     "DD-MM-YYYY"
    //   );
    //   const isGreaterThanOrEqualToCurrent =
    //     isDateGreaterThanOrEqualToCurrent(targetDate);
    //   if (isGreaterThanOrEqualToCurrent && fil?.session?.length === 0)
    //     return fil;
    // });

    // const filterCommentBasedCancellation = cancellation?.filter((fil: any) => {
    //   if (!fil?.comments?.comment) return fil;
    // });

    // const filterCommentBasedAbsent = student_absent?.filter((fil: any) => {
    //   if (!fil?.comments?.comment) return fil;
    // });

    // const filterCommentBasedPendingPayment = pendingPayment?.filter((fil:any)=> {
    //   if (!fil?.student?.logs?.changes && fil?.student?.logs?.module==="PaymentFollowUp") return fil;
    // })

    // const filterPaymentOverdue = paymentOverdue?.filter((fil: any) => {
    //   const hourly_rate = fil?.total_fee / fil?.duration;
    //   const paid_amount = fil?.total_fee - fil?.balance_due;
    //   const attended_hours = paid_amount / hourly_rate;
    //   const calculationForPaidAmount = hourly_rate * attended_hours * 1.25;
    //   if (calculationForPaidAmount >= paid_amount) return fil;
    // });

    const DataMapping = admissions?.map((dt: any) => {
      if (UsersDetails?.branches?.includes(dt?.branch?.id)) {
        return {
          type: dt?.student?.type,
          branch_name: dt?.branch?.branch_name,
          ownership: dt?.branch?.ownership,
        };
      }
    });

    const outputDataRevenueVsProforma = transactionsData?.reduce(
      (acc: any, item: any) => {
        const {
          paid_amount,
          training: {
            total_fee,
            mode,
            branch: { branch_name, ownership },
          },
        } = item;

        if (!acc[branch_name]) {
          acc[branch_name] = {
            branch_name,
            ownership,
            total_revenue: 0,
            total_invoice: 0,
            classroom_revenue: 0,
            classroom_invoice: 0,
            online_revenue: 0,
            online_invoice: 0,
            hybrid_revenue: 0,
            hybrid_invoice: 0,
            pending_revenue: 0,
          };
        }

        acc[branch_name].total_revenue += parseFloat(total_fee);
        acc[branch_name].total_invoice += parseFloat(paid_amount);

        if (mode === "Classroom") {
          acc[branch_name].classroom_revenue += parseFloat(total_fee);
          acc[branch_name].classroom_invoice += parseFloat(paid_amount);
        } else if (mode === "Online") {
          acc[branch_name].online_revenue += parseFloat(total_fee);
          acc[branch_name].online_invoice += parseFloat(paid_amount);
        } else if (mode === "Hybrid") {
          acc[branch_name].hybrid_revenue += parseFloat(total_fee);
          acc[branch_name].hybrid_invoice += parseFloat(paid_amount);
        }

        acc[branch_name].pending_revenue +=
          parseFloat(item.paid_amount) / parseFloat(total_fee);

        return acc;
      },
      {}
    );
    Object.values(outputDataRevenueVsProforma).forEach((branchData: any) => {
      Object.keys(branchData).forEach((key) => {
        if (typeof branchData[key] === "number") {
          branchData[key] = parseFloat(branchData[key].toFixed(2));
        }
      });
    });

    const outputDataOperations = operations?.reduce((acc: any, item: any) => {
      const {
        status,
        branch: { branch_name, ownership },
      } = item;

      if (!acc[branch_name]) {
        acc[branch_name] = {
          branch_name,
          ownership,
          Unallocated: 0,
          Hold: 0,
          Drop: 0,
          Allocated: 0,
          Completed: 0,
        };
      }
      acc[branch_name][status]++;
      return acc;
    }, {});

    const outputDataBatch = batch?.reduce((acc: any, item: any) => {
      const {
        status,
        branch: { branch_name, ownership },
      } = item;

      if (!acc[branch_name]) {
        acc[branch_name] = {
          branch_name,
          ownership,
          Ongoing: 0,
          Upcoming: 0,
          Completed: 0,
          Review: 0,
          Hold: 0,
          Drop: 0,
        };
      }

      acc[branch_name][status]++;

      return acc;
    }, {});

    const outputDataSessionFeedback = session_feedback?.reduce(
      (acc: any, item: any) => {
        const {
          response,
          training: {
            branch: { branch_name, ownership },
          },
        } = item;

        if (!acc[branch_name]) {
          acc[branch_name] = {
            branch_name,
            ownership,
            Poor: 0,
            Absent: 0,
            Average: 0,
            Good: 0,
            Excellent: 0,
          };
        }

        acc[branch_name][response]++;

        return acc;
      },
      {}
    );

    const outputDataStudentconcernFeedback = studentConcern_feedback?.reduce(
      (acc: any, item: any) => {
        const {
          status,
          training: {
            branch: { branch_name, ownership },
          },
        } = item;

        if (!acc[branch_name]) {
          acc[branch_name] = {
            branch_name,
            ownership,
            Resolved: 0,
            Open: 0,
            "In-progress": 0,
            ReOpen: 0,
          };
        }

        acc[branch_name][status]++;

        return acc;
      },
      {}
    );

    // const refundData = refunds?.reduce((acc: any, item: any) => {
    //   const {
    //     amount,
    //     training: {
    //       branch: { branch_name, ownership },
    //     },
    //   } = item;

    //   if (!acc[branch_name]) {
    //     acc[branch_name] = {
    //       branch_name,
    //       ownership,
    //       refund_count: 0,
    //       refund_amount: 0,
    //     };
    //   }

    //   acc[branch_name].refund_count++;
    //   acc[branch_name].refund_amount += Math.round(amount);

    //   return acc;
    // }, {});

    // const dropData = drop_students?.reduce((acc: any, item: any) => {
    //   const {
    //     training: {
    //       balance_due,
    //       branch: { branch_name, ownership },
    //     },
    //   } = item;

    //   if (!acc[branch_name]) {
    //     acc[branch_name] = {
    //       branch_name,
    //       ownership,
    //       drop_count: 0,
    //       drop_amount: 0,
    //     };
    //   }

    //   acc[branch_name].drop_count++;
    //   acc[branch_name].drop_amount += Math.round(balance_due);

    //   return acc;
    // }, {});

    // const nullifiedData = nullify?.reduce((acc: any, item: any) => {
    //   const {
    //     training: {
    //       total_fee,
    //       branch: { branch_name, ownership },
    //     },
    //   } = item;

    //   if (!acc[branch_name]) {
    //     acc[branch_name] = {
    //       branch_name,
    //       ownership,
    //       nullify_count: 0,
    //       nullify_amount: 0,
    //     };
    //   }

    //   const mapPaidAmount = item?.training?.transaction.map((amt: any) =>
    //     Number(amt?.paid_amount)
    //   );
    //   const nullify_amount =
    //     total_fee -
    //     mapPaidAmount?.reduce(
    //       (accumulator: any, currentValue: any) =>
    //         Number(accumulator) + Number(currentValue),
    //       0
    //     );

    //   acc[branch_name].nullify_count++;
    //   acc[branch_name].nullify_amount += Math.round(nullify_amount);

    //   return acc;
    // }, {});

    const mergedData = [
      ...(refunds || []),
      ...(drop_students || []),
      ...(nullify || []),
    ].reduce((acc: any, item: any) => {
      const {
        training: {
          branch: { branch_name, ownership },
        },
      } = item;

      if (!acc[branch_name]) {
        acc[branch_name] = {
          branch_name,
          ownership,
          refund_count: 0,
          refund_amount: 0,
          drop_count: 0,
          drop_amount: 0,
          nullify_count: 0,
          nullify_amount: 0,
          total_missed_revenue: 0, // New field to store the total missed revenue
        };
      }

      if ("amount" in item) {
        acc[branch_name].refund_count++;
        acc[branch_name].refund_amount += Math.round(item.amount);
      }

      if ("training" in item && "balance_due" in item.training) {
        acc[branch_name].drop_count++;
        acc[branch_name].drop_amount += Math.round(item.training.balance_due);
      }

      if ("training" in item && "total_fee" in item.training) {
        const total_fee = item.training.total_fee;
        const mapPaidAmount = item?.training?.transaction?.map((amt: any) =>
          Number(amt?.paid_amount)
        );
        const nullify_amount =
          total_fee -
          (mapPaidAmount?.reduce(
            (accumulator: any, currentValue: any) =>
              Number(accumulator) + Number(currentValue),
            0
          ) || 0);

        acc[branch_name].nullify_count++;
        acc[branch_name].nullify_amount += Math.round(nullify_amount);
      }

      // Calculate total missed revenue
      acc[branch_name].total_missed_revenue =
        acc[branch_name].refund_amount +
        acc[branch_name].drop_amount +
        acc[branch_name].nullify_amount;

      return acc;
    }, {});

    const outputDataForPlacements = placements?.reduce(
      (acc: any, item: any) => {
        const {
          training: {
            branch: { branch_name, ownership },
          },
          job: { no_of_openings, job_role },
          shortlisted,
          placed,
          joined,
          status,
        } = item;

        if (!acc[branch_name]) {
          acc[branch_name] = {
            branch_name,
            ownership,
            total_companies: 0,
            jobs: 0,
            openings: 0,
            applied: 0,
            shortlisted: 0,
            placed: 0,
            joined_company: 0,
            uniqueJobs: new Set(), // Track unique job roles
          };
        }

        acc[branch_name].total_companies++;

        // Increase jobs count only if job_role is unique
        if (!acc[branch_name].uniqueJobs.has(job_role)) {
          acc[branch_name].uniqueJobs.add(job_role);
          acc[branch_name].jobs++; // Increment jobs count
        }

        acc[branch_name].openings += no_of_openings;

        // Increase applied count only if status is "applied"
        if (status === "applied") {
          acc[branch_name].applied++;
        }

        acc[branch_name].shortlisted += shortlisted;
        acc[branch_name].placed += placed;
        acc[branch_name].joined_company += joined;

        return acc;
      },
      {}
    );

    return {
      branch: branch_data,
      coursewise_registration: coursewise_registration,
      admissions: DataMapping,
      revenueVsproforma: outputDataRevenueVsProforma,
      operations: outputDataOperations,
      batch: outputDataBatch,
      session_feedback: outputDataSessionFeedback,
      studentConcern_feedback: outputDataStudentconcernFeedback,
      // refunds: refundData,
      // nullify: nullifiedData,
      // drop_students: dropData,
      missed_revenue: mergedData,
      placements: outputDataForPlacements,
      deviation: processedData
      // deviation: {
        // unAllocated_Students: filterStudentsForUnallocatedData,
        // batches: filterBatchesBasedOnExpectations,
        // cancellation: filterCommentBasedCancellation,
        // absent: filterCommentBasedAbsent,
        // pending_payment: filterCommentBasedPendingPayment,
        // payment_overdue: filterPaymentOverdue,
      // },
    };
  } catch (error) {
    return errorNotifier(error);
  }
};

export const operationalReportsForDiscountTable = async (UsersDetails: any) => {
  try {
    const { data: discountData, error: discountError } = await FetchData(
      TableNames.trainings,
      [
        "created_at",
        "cd_comment",
        "student:students(name, phoneNumber)",
        "city:cities(id, city_name)",
        "branch:branches(id, branch_name, ownership)",
        "course:courses(id, course_name)",
        "discount_percentage",
      ]
    ).neq("cd_comment", "");

    const discount_Status =
      discountData?.map((dt: any) => {
        if (UsersDetails?.branches?.includes(dt?.branch?.id)) {
          return {
            ...dt,
            created_at: dateFormat(dt?.created_at, "DD MMM YYYY"),
            branch_name: dt?.branch?.branch_name,
            branch_ownership: dt?.branch?.ownership,
            city_name: dt?.city?.city_name,
            course_name: dt?.course?.course_name,
            discount_range:
              dt?.discount_percentage === null
                ? "0-1%"
                : discountBasedOnRange(dt?.discount_percentage),
            student_name: dt?.student?.name,
            student_phone_number: dt?.student?.phoneNumber,
            reason: dt?.cd_comment,
            discount_percentage:
              dt?.discount_percentage === 0 ? "0" : dt?.discount_percentage,
          };
        }
      }) || [];

    if (discountError) throw discountError;
    return discount_Status;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const operationalReportsForRefundTable = async (UsersDetails: any) => {
  try {
    const { data, error } = await FetchData(TableNames.refunds, [
      "created_at",
      "reason",
      "amount",
      "training:trainings(student:students(name, phoneNumber), city:cities(id, city_name), branch:branches(id, branch_name), course:courses(id, course_name))",
    ]);

    const report_Status =
      data?.map((dt: any) => {
        if (UsersDetails?.branches?.includes(dt?.training?.branch?.id)) {
          return {
            ...dt,
            created_at: dateFormat(dt?.created_at, "DD MMM YYYY"),
            branch_name: dt?.training?.branch?.branch_name,
            city_name: dt?.training?.city?.city_name,
            course_name: dt?.training?.course?.course_name,
            refund_amount: dt?.amount,
            student_name: dt?.training?.student?.name,
            student_phone_number: dt?.training?.student?.phoneNumber,
            reason: dt?.reason,
          };
        }
      }) || [];

    if (error) throw error;
    return report_Status;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const operationalReportsForBatchTable = async (UsersDetails: any) => {
  try {
    const { data: batchData, error: batchError } = await FetchData(
      TableNames.batches,
      [
        "batch_code",
        "planned_date_of_completion",
        "batch_progress_in_hrs",
        "course:courses(id, course_name)",
        "trainer:users(first_name, last_name)",
        "mode",
        "schedule",
        "status",
        "schedule_days",
        "city:cities(id, city_name)",
        "branch:branches(id, branch_name, ownership)",
      ]
    );

    const batch_Status =
      batchData?.map((dt: any) => {
        if (UsersDetails?.branches?.includes(dt?.branch?.id)) {
          return {
            ...dt,
            branch_name: dt?.branch?.branch_name,
            branch_ownership: dt?.branch?.ownership,
            city_name: dt?.city?.city_name,
            course_name: dt?.course?.course_name,
            completion_age: calculateDayDifference(
              dt?.planned_date_of_completion,
              new Date().toISOString().slice(0, 10),
              dt?.schedule_days
            ),
            trainer_name: dt?.trainer?.first_name + "" + dt?.trainer?.last_name,
          };
        }
      }) || [];

    if (batchError) throw batchError;
    return batch_Status;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const operationalReportsForNullifyTable = async (UsersDetails: any) => {
  try {
    const { data, error } = await FetchData(TableNames.approval_queue, [
      "created_at",
      "status",
      "approvalStatus",
      "reason",
      "training:trainings(transaction:transactions(paid_amount), total_fee, student:students(name, phoneNumber), city:cities(id, city_name), branch:branches(id, branch_name), course:courses(id, course_name), discount_percentage)",
    ]).eq("status", "Nullify Payment");

    const filterData = data?.filter(
      (fil: any) => fil.approvalStatus === "accept"
    );

    const report_Status =
      filterData?.map((dt: any) => {
        if (UsersDetails?.branches?.includes(dt?.training?.branch?.id)) {
          const mapPaidAmount = dt?.training?.transaction.map((amt: any) =>
            Number(amt?.paid_amount)
          );
          const nullify_amount =
            dt?.training?.total_fee -
            mapPaidAmount?.reduce(
              (accumulator: any, currentValue: any) =>
                Number(accumulator) + Number(currentValue),
              0
            );
          return {
            ...dt,
            created_at: dateFormat(dt?.created_at, "DD MMM YYYY"),
            branch_name: dt?.training?.branch?.branch_name,
            city_name: dt?.training?.city?.city_name,
            course_name: dt?.training?.course?.course_name,
            student_name: dt?.training?.student?.name,
            student_phone_number: dt?.training?.student?.phoneNumber,
            reason: dt?.reason,
            nullify_amount: Math.round(nullify_amount),
          };
        }
      }) || [];
    // console.log(report_Status, "data");

    if (error) throw error;
    return report_Status;
  } catch (error) {
    return errorNotifier(error);
  }
};
