import { TableComponent } from "../../Elements/Table/TableComponent";
import { calculateTrainerPayment } from "../../../utils/json/CalculateTrainerPayment";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { useAppSelector } from "../../..";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const CalculateTrainerPayment = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  return (
    <div>
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
              Calculate Trainer Payment
            </h1>
          </div>
          <TableComponent
            TABLE_JSON={calculateTrainerPayment}
            backEndCallParams={signInData?.data[0]}
          />
        </div>
      )}
    </div>
  );
};
