import React from "react";
import { CardDesign } from "./CardDesign";
import { Doughnut } from "react-chartjs-2";

export const Invoice = ({ DashboardData }: any) => {
  const dougnetoptions = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  const dougnetdata = {
    labels: (DashboardData[0].data || [])?.map((d: any) => d.name),
    datasets: [
      {
        label: "Count",
        backgroundColor: (DashboardData[0].data || [])?.map((d: any) => d.fill),
        data: (DashboardData[0].data || [])?.map((d: any) => d.value),
      },
    ],
  };
  return (
    <div className="w-full rounded-[8px]">
      {DashboardData?.map((item: any, index: number) => (
        <div key={index} className="flex flex-col md:flex-row gap-4">
          {/* Left Section: Doughnut Chart */}
          <div className="w-full md:w-[30%] shadow-[#00000029_0px_3px_8px]">
            <div className="flex items-center justify-between p-4 bg-[#FBFBFB]">
              <p className="font-[Roboto] font-medium text-[16px]">
                {item.invoiceAnalysis}
              </p>
              <select className="font-[Roboto] text-[#707070] bg-[#FBFBFB] font-medium text-[16px] outline-none">
                <option value="">Invoices</option>
              </select>
            </div>
            <div className="flex items-center justify-center w-8/12 mx-auto py-2">
              <Doughnut
                options={dougnetoptions}
                data={dougnetdata}
                width={"50px"}
              />
            </div>
          </div>

          {/* Right Section: Title + Cards */}
          <div className="w-full md:w-[70%] flex flex-col space-y-2">
            <h1 className="font-Roboto font-[500] text-[18px] md:text-[24px]">
              {item.invoiceTitle}
            </h1>

            {/* Cards - Responsive Grid */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 w-full">
              {item.invoice?.map((data: any, cardIndex: number) => (
                <CardDesign key={cardIndex} data={data} />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
