import { trainingLogsBasedTableData } from "../../functions/admin/HR Management/hr_management";

export const resume_rejection_logs_dashboard = {
    headers: [
        {
            name: "Date",
            apiKey: "created_at",
            headerClass: "w-[100px] text-center",
            className: "text-center",
        },
        {
            name: "Name",
            apiKey: "student_name",
            headerClass: "w-[150px] text-start px-2",
            className: "w-[150px] text-start break-words px-2",
        },
        {
            name: "Email",
            apiKey: "student_email",
            headerClass: "w-[140px] text-center px-2",
            className: "w-[140px] text-center break-words px-2",
        },
        {
            name: "Course",
            apiKey: "course",
            headerClass: "w-[100px] text-center",
            className: "w-[100px] text-center break-words",
        },
        {
            name: "Rejection Comment",
            apiKey: "rejection_comments",
            headerClass: "w-[150px] text-center",
            className: "w-[100px] text-center break-words",
        },
    ],
    BackendFunctionCall:(params: any) => {
        return trainingLogsBasedTableData(params);
    },
    filters: {
        dateFilters: [
            {
                name: "Date",
                apiKey: "date",
                isSearchNeeded: true,
                className: "w-[180px] ",
            },
        ],
        checkBox: [
            {
                name: "Course",
                apiKey: "course",
                isSearchNeeded: true,
                className: "w-[160px]",
            },
        ],
        searchBox: [
            {
                matchFields: ["student_name", "student_email"],
                searchKey: "student_names",
                name: "Search...",
                searchStyle: "w-[200px]",
            }
        ]
    },
};