import { LoginUserDetails } from "./LoginUserDetails";

// export const Header = () => {
//   return (
//     <div className="h-[344px] bg-[#036AD1]">
//       <div className="h-[172px] flex items-center justify-center mt-4 w-[95%] mx-auto relative">
//         <p className="font-poppins font-bold text-white opacity-[0.03] text-[180px]">
//           ONE APP
//         </p>
//         <LoginUserDetails />
//       </div>
//     </div>
//   );
// }

export const Header = () => {
  return (
    <div className="h-[200px] md:h-[344px] bg-[#036AD1] overflow-hidden">
      <div className="flex items-center justify-center mt-4 w-[95%] mx-auto relative">
        <p
          className="
            absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2
            font-poppins font-bold text-white opacity-[0.03] 
            text-[80px] md:text-[180px] text-center
            whitespace-nowrap
          "
        >
          ONE APP
        </p>
        {/* Hide on mobile screens */}
        <div className="hidden md:block">
          <LoginUserDetails />
        </div>
      </div>
    </div>
  );
};
