export const cities_in_india = [
    "Delhi", "Mumbai", "Kolkāta", "Bangalore", "Chennai", "Hyderābād", "Pune", 
    "Ahmedabad", "Sūrat", "Lucknow", "Jaipur", "Kanpur", "Mirzāpur", "Nāgpur", 
    "Ghāziābād", "Supaul", "Vadodara", "Rājkot", "Vishākhapatnam", "Indore", 
    "Thāne", "Bhopāl", "Pimpri-Chinchwad", "Patna", "Bilāspur", "Ludhiāna", 
    "Āgra", "Madurai", "Jamshedpur", "Prayagraj", "Nāsik", "Farīdābād", "Meerut", 
    "Jabalpur", "Kalyān", "Vasai-Virar", "Najafgarh", "Vārānasi", "Srīnagar", 
    "Aurangābād", "Dhanbād", "Amritsar", "Alīgarh", "Guwāhāti", "Hāora", "Rānchi", 
    "Gwalior", "Chandīgarh", "Haldwāni", "Vijayavāda", "Jodhpur", "Raipur", "Kota", 
    "Bhayandar", "Loni", "Ambattūr", "Salt Lake City", "Bhātpāra", "Kūkatpalli", 
    "Dāsarhalli", "Muzaffarpur", "Oulgaret", "New Delhi", "Tiruvottiyūr", 
    "Puducherry", "Byatarayanpur", "Pallāvaram", "Secunderābād", "Shimla", "Puri", 
    "Murtazābād", "Shrīrāmpur", "Chandannagar", "Sultānpur Mazra", "Krishnanagar", 
    "Bārākpur", "Bhālswa Jahangirpur", "Nāngloi Jāt", "Balasore", "Dalūpura", 
    "Yelahanka", "Titāgarh", "Dam Dam", "Bānsbāria", "Madhavaram", "Abbigeri", 
    "Baj Baj", "Garhi", "Mīrpeta", "Nerkunram", "Kendrāparha", "Sijua", "Manali", 
    "Kānkuria", "Chakapara", "Pāppākurichchi", "Herohalli", "Madipakkam", 
    "Sabalpur", "Bāuria", "Salua", "Chik Bānavar", "Jālhalli", "Chinnasekkadu", 
    "Jethuli", "Nagtala", "Pakri", "Hunasamaranhalli", "Hesarghatta", 
    "Bommayapālaiyam", "Gundūr", "Punādih", "Harilādih", "Alāwalpur", 
    "Mādnāikanhalli", "Bāgalūr", "Kādiganahalli", "Khānpur Zabti", "Mahuli", 
    "Zeyādah Kot", "Arshakunti", "Mirchi", "Sonudih", "Bayandhalli", "Sondekoppa", 
    "Babura", "Mādavar", "Kadabgeri", "Nanmangalam", "Taliganja", "Tarchha", 
    "Belgharia", "Kammanhalli", "Ambāpuram", "Sonnappanhalli", "Kedihāti", 
    "Doddajīvanhalli", "Simli Murārpur", "Sonāwān", "Devanandapur", "Tribeni", 
    "Huttanhalli", "Nathupur", "Bāli", "Vājarhalli", "Alija Kotla", "Saino", 
    "Shekhpura", "Cāchohalli", "Andheri", "Nārāyanpur Kola", "Gyan Chak", 
    "Kasgatpur", "Kitanelli", "Harchandi", "Santoshpur", "Bendravādi", 
    "Kodagihalli", "Harna Buzurg", "Mailanhalli", "Sultānpur", "Adakimāranhalli"
  ]
  