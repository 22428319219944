import { dateFormat } from "../../../Services/CommonFunctions";
import { convertTimeTo12HourFormat } from "../../../Components/CommonFunctions/CommonFunc";
import { calculatePercentage, errorNotifier, percentageBasedOnRange } from "../../commonHelper";
import { TableNames } from "../../config/Tables"
import { BulkUpdate, FetchData, FetchDataById, InsertData, UpdateData } from "../../crud"
import { BatchInterface, NewTrainerInterface, StudentTrainingUpdateInterface } from "./interface";

export const getBatchTableData = async (UsersDetails: any) => {
    try {
        const { data: batches, error } = await FetchData(TableNames.batches, ["id", "mode", "course:courses(id, course_name, user_type)", "schedule", "status", "branch_id", "city_id", "start_date", "start_time", "end_time", "city:cities(city_name)", "duration", "batch_code", "batch_progress_in_hrs", "trainer:users(first_name, last_name, phone_number)", "branch:branches(branch_name)", "training:trainings(student:students(id,name,phoneNumber),id)"]).neq("training.batch_id", 1).in("branch_id", UsersDetails?.branches);

        if (error) throw error;

        const orderedStatus = ["Review", "Upcoming", "Ongoing", "Hold", "Dropped", "Completed"];

        const sortedBatches = batches?.sort((batch1:any, batch2:any) => {
            const statusOrder1 = orderedStatus.indexOf(batch1.status);
            const statusOrder2 = orderedStatus.indexOf(batch2.status);
            return statusOrder1 - statusOrder2;
        });
        console.log(sortedBatches, "sortedBatches")

        const { data: users, error: userError } = await FetchData(TableNames.users, ["first_name", "last_name", "courses", "cities", "branches", "user_type"])
        if (userError) throw userError;

        const check_CRM_User = sortedBatches?.map((bt: any) => {
            const matchingUser:any = users?.find((dt: any) => 
                dt?.cities?.includes(bt?.city_id) && dt?.branches?.includes(bt?.branch_id) && dt?.courses?.includes(bt?.course?.id) && dt?.user_type === "BC" 
            );
            return matchingUser && {
                id: bt?.id,
                name: `${matchingUser?.first_name} ${matchingUser?.last_name}`
            };
        });
        
        // console.log(sortedBatches, "sortedBatches")

        if (UsersDetails?.roles?.includes('Trainer')) {
            const filterBatches = sortedBatches?.filter((batch: any) => (UsersDetails?.name) === `${batch?.trainer?.first_name} ${batch?.trainer?.last_name}`);
            // console.log(filterBatches, "filterBatches")
            const batchData = filterBatches?.map((batch: any) => {
            const crmUserResponse = check_CRM_User?.length>0 ? check_CRM_User?.filter((user:any)=> user?.id === batch?.id)?.map((filteredUser: any) => filteredUser.name) : '';
            // console.log(crmUserResponse, "crmUserResponse")
            const studentNames = batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.name);
            const studentPhoneNumbers = batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.phoneNumber);
            return {
              ...batch,
              batch_progress_in_percent: (batch?.batch_progress_in_hrs!=="0" ? 
                calculatePercentage(
                  batch?.batch_progress_in_hrs,
                  batch?.duration
                ) : "0"),
              course_name: batch?.course?.course_name,
              branch_name: batch?.branch?.branch_name,
              city: batch?.city?.city_name,
              start_date: dateFormat(batch?.start_date, "DD MMM YYYY"),
              trainer_name: `${batch?.trainer?.first_name} ${batch?.trainer?.last_name}`,
              trainer_mobile: batch?.trainer?.phone_number,
              check_CRM_User: crmUserResponse?.[0],
              student_list: studentNames,
              studentPhoneNumbers: studentPhoneNumbers,
              progress_range: percentageBasedOnRange(batch?.batch_progress_in_hrs!=="0" ? 
                calculatePercentage(
                  batch?.batch_progress_in_hrs,
                  batch?.duration
                ) : "0"),
              time_range: `${convertTimeTo12HourFormat(batch?.start_time)} - ${convertTimeTo12HourFormat(batch?.end_time)}`
            };})
            // console.log(batchData, "batchData")
            return batchData;
        } else {
            const batchData = sortedBatches?.map((batch: any) => {
            const crmUserResponse = check_CRM_User?.length>0 ? check_CRM_User?.filter((user:any)=> user?.id === batch?.id)?.map((filteredUser: any) => filteredUser.name) : '';
            // console.log(crmUserResponse, "crmUserResponse")
            const studentNames = batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.name);
            const studentPhoneNumbers = batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.phoneNumber);
            console.log(batch?.batch_progress_in_hrs, batch?.duration, "1234")
            return {
              ...batch,
              batch_progress_in_percent: (batch?.batch_progress_in_hrs!=="0" ? 
                calculatePercentage(
                    batch?.batch_progress_in_hrs,
                    batch?.duration
                ): "0"),
              course_name: batch?.course?.course_name,
              branch_name: batch?.branch?.branch_name,
              city: batch?.city?.city_name,
              start_date: dateFormat(batch?.start_date, "DD MMM YYYY"),
              trainer_name: `${batch?.trainer?.first_name} ${batch?.trainer?.last_name}`,
              trainer_mobile: batch?.trainer?.phone_number,
              crm_name: batch?.course?.user_type,
              check_CRM_User: crmUserResponse?.[0],
              student_list: studentNames,
              student_Phone_Numbers: studentPhoneNumbers,
              progress_range: percentageBasedOnRange(batch?.batch_progress_in_hrs!=="0" ? 
                calculatePercentage(
                  batch?.batch_progress_in_hrs,
                  batch?.duration
                  ) : "0"),
              time_range: `${convertTimeTo12HourFormat(batch?.start_time)} - ${convertTimeTo12HourFormat(batch?.end_time)}`
            };})
            // console.log(batchData, "batchData")
            return batchData;
        }

    } catch (error) {
        return errorNotifier(error);
    }
}

export const getAllBatches = async (UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.batches, ["batch_code", "branch_id"]).in("branch_id", UsersDetails?.branches).order("batch_code", { ascending: true });
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCourseBasedStudentsList = async (course_id:any, UsersDetails?:any) => {
    try {
        const { data, error} = await FetchData(TableNames.course_mapping, ["courses"]);
        if (error) throw error
        const filterCourseMatchingData = data?.filter((ft:any)=> ft?.courses?.includes(course_id))
        const flatCourses = Array.from(new Set(filterCourseMatchingData.flatMap((item: any) => item.courses)));
        // console.log(flatCourses, "flatCourses")
        const { data: student_data, error: TrainingQueryError } = await FetchData(TableNames.trainings, ["id", "transactions:transactions(*)", "student:students(uid, name, phoneNumber, email)", "course:courses(id, type)", "payment_details", "total_fee", "balance_due", "branch_id"]).or("status.eq.Unallocated, status.eq.Hold").in("branch_id", UsersDetails?.branches)
        // console.log(student_data, "student_data")
        const filterStudentsBasedOnCourses = await student_data?.filter((ft:any) => flatCourses?.includes(ft?.course?.id))
        // console.log(filterStudentsBasedOnCourses, "filterStudentsBasedOnCourses")
        return filterStudentsBasedOnCourses;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getBatchPreloadData = async (UsersDetails: any) => {
    try {
        const { data: student_data, error: TrainingQueryError } = await FetchData(TableNames.trainings, ["id", "mode", "transactions:transactions(*)", "student:students(uid, name, phoneNumber, email)", "course:courses(id, course_name, type)", "payment_details", "total_fee", "balance_due", "branch_id"]).or("status.eq.Unallocated, status.eq.Hold").in("branch_id", UsersDetails?.branches)
        
        const { data: courses, error: course_error } = await FetchData(TableNames.courses, ["id", "schedule", "course_name", "duration", "type", "mode", "city_based_course_fee"]).eq("type", "Paid");

        const { data: cities, error: city_error } = await FetchData(TableNames.cities, ["id", "city_name"]).in("id", UsersDetails?.cities)

        const { data: branches, error: branch_error } = await FetchData(TableNames.branches, ["id", "branch_name", "city", "rooms"]).in("id", UsersDetails?.branches)

        if (TrainingQueryError || course_error || city_error || branch_error) throw TrainingQueryError || course_error || city_error || branch_error
        
        const student = student_data?.map((data:any)=>{
            const payment_details = data?.payment_details?.map((dt:any)=> dt?.paid_amount);
            const sum = payment_details?.reduce((accumulator:any, currentValue:any) => accumulator + currentValue, 0);
            return {
                ...data,
                paid_amount:sum
            }
        })
        
        return {
            student_data: student,
            courses,
            cities,
            branches
        }
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getPastStudentsPreloadData = async(UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.trainings, ["id", "mode", "transactions:transactions(*)", "batch:batches(batch_code)" ,"student:students(uid, name, phoneNumber, email)", "course:courses(id, course_name, type)", "payment_details", "total_fee", "balance_due", "branch_id", "status"]).eq("status", "Hold").in("branch_id", UsersDetails?.branches)
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getTrainerBasedOnBranch = async (branch_id: number) => {
    try {
        const { data, error }: any = await FetchData(TableNames.roles).eq("role", "Trainer")
        const { data: trainer, error: trainer_error } = await FetchData(TableNames.users, ["uid", "first_name", "last_name", "email", "phone_number"]).contains("roles_array", [data[0].id]).contains("branches", [branch_id])
        
        const updatedTrainerData = trainer?.map((el: any) => ({...el, name: `${el.first_name} ${el.last_name}`}))
        
        if (error || trainer_error) throw error || trainer_error
        return updatedTrainerData
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getTrainerBasedOnBatchTime = async (starttime: string,endtime:string,branchid:number) => {
    try {
        const { data, error } = await FetchData(TableNames.batches, ["trainer_id", "start_time","end_time"])
            .eq("branch_id",branchid)
            // .lte("start_time",starttime).gte("end_time",endtime)
        var Trainerdetails:any = await getTrainerBasedOnBranch(branchid)
        // const batchTrainerUids=data?.map((batchdata:any)=>batchdata?.trainer_id);
        // const difference  = Trainerdetails.filter( (x:any) => !batchTrainerUids?.includes(x?.uid));
        // console.log(Trainerdetails, "Trainerdetails")
        if (error) throw error
        return Trainerdetails
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getBatchById = async (batchId: number) => {
    try {

        // const { data: student_data, error: TrainingQueryError } = await FetchData(TableNames.trainings, ["id", "transactions:transactions(*)", "student:students(uid, name, phoneNumber, email)", "course:courses(type)", "payment_details", "total_fee", "balance_due", "branch_id"]).or("status.eq.Unallocated, status.eq.Hold")

        const { data: batch, error }: any = await FetchData(TableNames.batches,
             ["*", "course:courses(id,course_name)", "branch:branches(id,branch_name)", "city:cities(city_name)"]).eq("id", batchId);
        const { data: sessions, error: err } = await FetchData(TableNames.sessions, ["batch:batches(id, course_id, branch_id)", "duration", "attendees"])
        
        const sessionByBatchId = sessions?.filter((el: any) => (el.batch.id === Number(batchId)))
        // console.log(sessions, sessionByBatchId, "sessionByBatchId", batchId, typeof batchId, "batchId")
        const batchData = batch.map((el: any) => ({...el, start_date: el.start_date, planned_date_of_completion: el.planned_date_of_completion}))

        const { data: trainer_data, error: trainer_error }: any =
          await FetchData(TableNames.batch_trainers, [
            "trainer:users(uid, first_name, last_name, phone_number, email)",
          ])
            .eq("batch_id", batchId)
            .order("id", { ascending: true });
        const updatedTrainerData = trainer_data?.map((el: any) => ({ ...el, current_trainer: trainer_data[trainer_data.length - 1].trainer.uid === el.trainer.uid ? true : false }))
        
        const progress_in_hrs = sessionByBatchId?.reduce((accumulator: any, currentValue: any) => Number(accumulator) + Number(currentValue?.duration), 0)
        // console.log(batchId, typeof batchId, "batchId",sessions, "sessions", sessionByBatchId, "sessionByBatchId", progress_in_hrs, "progress_in_hrs")
        const batch_progress_in_percent = calculatePercentage(progress_in_hrs, batch[0].duration)

        const { data: training_data, error: training_error } = await FetchData(TableNames.trainings, ["id", "trainers_ids", "student:students(uid, name, phoneNumber)", "balance_due", "total_fee", "status", "payments:transactions(*)", "student_action_request_pending"]).eq("batch_id", batchId).or("status.eq.Allocated, status.eq.Completed")
        
        const oldBatchSessions: any = sessions?.filter((el: any) => (el.batch.course_id === batch[0].course.id && el.batch.branch_id === batch[0].branch.id && el.batch.id !== batchId))

        const sessionData = sessions?.filter((el: any) => (el.batch.id === oldBatchSessions[0]?.batch?.id))
        const student_data: any = training_data?.map((el: any) => {
            const totalPaidAmount = el.payments.reduce((acc: number, curr_val: any) => acc + Number(curr_val.paid_amount), 0);
            const paidAmountPercentage = (totalPaidAmount * 18) / 100;
            const totalFee = el.total_fee;
            const paidAmount = totalPaidAmount ? (totalPaidAmount) : '';
            // const paidAmount = paidAmountPercentage && totalPaidAmount ? (paidAmountPercentage + totalPaidAmount) : '';
            const balanceDue = totalFee - paidAmount;    
            const buffer_hours = sessionData?.filter((ele: any) => ele.attendees.includes(el.student?.uid))
            .reduce((acc: number, curr_val: any) => acc + Number(curr_val.duration), 0);
            const hours_attended = sessionByBatchId?.filter((ele: any) => ele.attendees.includes(el.student?.uid))
            .reduce((acc: number, curr_val: any) => acc + Number(curr_val.duration), 0);

            return {
            ...el,
            hours_attended,
            buffer_hours,
            total_fee: totalFee,
            paid_amount: paidAmount,
            // balance_due: balanceDue,
            trainers_ids: el?.trainers_ids
        };

        })

        if (error || err || trainer_error) throw error  || err || trainer_error;

        return { batch: batchData[0], progress_in_hrs, total_duration: batch[0].duration, batch_progress_in_percent, student_data, updatedTrainerData };
    } catch (error) {

        return errorNotifier(error);

    }

}

export function incrementValue(value: string): string {
  const numericPart = parseInt(value.substring(1), 10);
  const incrementedValue = numericPart + 1;
  const paddedValue = incrementedValue.toString().padStart(7, '0');
  return `B${paddedValue}`;
}

// export const addCountForBatchTrainers = async(batch_id:any) => {
//     const { data: batch_trainer, error } = await FetchData(TableNames.batch_trainers, ["batch_id", "batch_trainer_count"]);
//     if (error) throw error;

//     const insertCount = batch_trainer?.filter((bt: any) => bt.batch_id === 68);
//     console.log(insertCount, "insertCount)")
// }

export const createBatch = async (batchData: BatchInterface) => {

    // console.log("create batch init")

    try {
        const { data: batches, error: batch_error }: any = await FetchData(TableNames.batches).order("id", { ascending: true })
        let initialValue = "B0000001";

        if (batches && batches.length > 0) {
            let previousRecord = batches[batches.length - 1].batch_code
            let new_batch_code = incrementValue(previousRecord)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            initialValue = new_batch_code
        }
        let { students, batch_type, ...rest } = batchData;

        const updatedBatchData = {
            ...rest,
            batch_code: initialValue,
            batch_type
        }
        const { data, error }: any = await InsertData(TableNames.batches, updatedBatchData);

        const batch_trainer_data = {
            batch_id: data[0].id,
            trainer_id: batchData.trainer_id,
            start_date: batchData.start_date
        }
        // console.log(batch_trainer_data, "batch_trainer_data")
        const { data: batch_trainer, error: batch_trainer_error } = await InsertData(TableNames.batch_trainers, batch_trainer_data);
        if (batch_trainer_error) throw batch_trainer_error
        if (students) {
            students = students.map((student) => ({ ...student, batch_id: data[0].id, course_id: batchData.course_id }));
            const { error } = await BulkUpdate(TableNames.trainings, students);
            if (error) throw error;
        }
        if (error) throw error
        return data;
    } catch (error) {
        return errorNotifier(error)
    }

    
}

export const updateBatch = async (batchData: Partial<BatchInterface>, batchId: number) => {
    try {
        const { students, ...rest } = batchData;
        const { data: trainer_check, error: trainer_check_error } = await FetchData(TableNames.batch_trainers).match({batch_id: batchId, trainer_id: rest.trainer_id})
        if (trainer_check?.length === 0) {
            const date = new Date()
                
            const newTrainerData = {
                trainer_id: rest.trainer_id,
                batch_id: batchId,
                start_date: date
            }

            const { data: batch_trainer, error: trainer_error }: any = await InsertData(TableNames.batch_trainers, newTrainerData)
            
            const { data: trainer, error: batch_trainer_error }: any = await FetchData(TableNames.batch_trainers).eq("batch_id", batchId).order("id")
            
            const trainer_update_data = {
                end_date: date
            }

            await UpdateData(TableNames.batch_trainers, trainer_update_data, {conditionKey: "id", conditionValue: trainer[trainer?.length - 2].id})

            const newBatchData = {
                ...rest,
                trainer_id: batch_trainer[0].trainer_id
            }

            const { data, error } = await UpdateData(TableNames.batches, newBatchData, { conditionKey: "id", conditionValue: batchId })
            if (error) throw error
        } else {
            const { data, error } = await UpdateData(TableNames.batches, rest, { conditionKey: "id", conditionValue: batchId })
            if (error) throw error
            // return true
        }
        const { data: StudentUpdateResponse,error:StudentUpdateError }=await BulkUpdate(TableNames.trainings, students);
        if (StudentUpdateError) throw StudentUpdateError;
        return true
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateIndividualStudentInTraining = async(batchData: Partial<BatchInterface>, batchId: number) => {
        try {
        const { students } = batchData;
        const { data, error:StudentUpdateError }=await BulkUpdate(TableNames.trainings, students);
        if (StudentUpdateError) throw StudentUpdateError;
        return true
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateStudentTraining = async (trainingData: StudentTrainingUpdateInterface,trainingId: number | string) => {
    try {
        const { data, error } = await UpdateData(TableNames.trainings,trainingData, { conditionKey: "id", conditionValue: trainingId })
        if(error) throw error
        return true
    } catch (error) {
        return errorNotifier(error)
    }
}

function mergeArraysAndRemoveDuplicates(arrays: any) {
  const mergedSet = new Set();
  arrays.forEach((array: any) => {
    array.forEach((record: any) => {
      mergedSet.add(record);
    });
  });

  const mergedArray: any = Array.from(mergedSet);

  return mergedArray;
}

export const countStudentsBasedOnStatus = (studentData: any) => {
    let student_data: any = { Allocated: [], Dropped: [], Hold: [] }
    
    studentData.map((el: any) => {
        student_data[el.status].push(el)
    })

    return student_data
}

export const getStudentBasedOnTrainerAndSession = async(batchId:number, trainerId:string) => {
    // console.log(batchId, trainerId, "?>")
    try {
        const { data: batch, error }: any = await FetchData(TableNames.batches, ["*", "course:courses(id,course_name)", "branch:branches(id,branch_name)", "city:cities(city_name)", "trainer_id", "batch_payment_status", "total_trainer_payment", "total_trainer_payment_comment"]).eq("id", batchId);

        const { data: sessions, error: err }: any = await FetchData(TableNames.sessions, ["trainer:users(uid,first_name, last_name, email, phone_number)", "attendees", "duration", "batch:batches(id, course_id, branch_id)"])
        const sessionByBatchId = sessions?.filter((el: any) => (el.batch.id === batchId));
        // console.log(sessions, "sessions", sessionByBatchId, "sessionByBatch")

        const { data: training_data, error: training_error } = await FetchData(TableNames.trainings, ["id", "city_id", "course:courses(city_based_course_fee)", "batch:batches(id, batch_code, batch_type, branch_id, total_trainer_payment, trainer: users(first_name, last_name, phone_number))", "trainers_ids", "student:students(uid, name, phoneNumber)", "balance_due", "trainer_payment", "existing_trainer_payment", "student_payment_status", "trainer_payment_comment", "payment_details", "total_fee", "status", "payments:transactions(paid_amount)"]).eq("batch_id", batchId)
            // console.log(training_data, "training_data")
        const oldBatchSessions: any = sessions?.filter((el: any) => (el.batch.course_id === batch[0].course.id && el.batch.branch_id === batch[0].branch.id && el.batch.id !== batchId))

        const sessionData = sessions?.filter((el: any) => (el.batch.id === oldBatchSessions[0]?.batch?.id));

        const filteredTrainerBasedData = training_data && training_data?.filter((item:any)=> item?.trainers_ids.includes(trainerId));

        // console.log(filteredTrainerBasedData, "filteredTrainerBasedData")
        
        const student_data: any = filteredTrainerBasedData?.map((el: any) => {
                const paidAmount = el.payments.reduce((acc: number, curr_val: any) => acc + Number(curr_val.paid_amount), 0);
                const totalFee = el?.total_fee;
                const balanceDue = el?.balance_due
                const buffer_hours = (sessionData?.length > 0) ? (sessionData?.filter((ele: any) => ele.attendees.includes(el.student?.uid))
                    .reduce((acc: number, curr_val: any) => acc + Number(curr_val.duration), 0)):0;
                const hours_attended = (sessionByBatchId?.length>0)?(sessionByBatchId?.filter((ele: any) => ele.attendees.includes(el.student?.uid))
                    .reduce((acc: number, curr_val: any) => acc + Number(curr_val.duration), 0)):0;
                const discountedFeeMatchesResult = el?.course?.city_based_course_fee?.find((item:any) => item?.city_id.includes(el?.city_id));
                const discountedFee = parseFloat(discountedFeeMatchesResult?.discounted_fee);
                const reducedFee = discountedFee - discountedFee * 0.1; // Reduce by 10%
                const dataByBatch_Type = el?.batch?.batch_type === "One to One" ? (reducedFee * 0.3) : (reducedFee * 0.25);
                
                return {
                ...el,
                hours_attended,
                buffer_hours,
                total_fee: totalFee,
                paid_amount: paidAmount,
                balance_due: balanceDue,
                trainer_payment: el?.trainer_payment,
                existing_trainer_payment: el?.existing_trainer_payment,
                student_payment_status: el?.student_payment_status,
                payment_limit: dataByBatch_Type
            };
            })

            if (error || err ) throw error  || err ;
            return student_data

        } catch (error) {
            return errorNotifier(error)
        }
}

export const getStudentBasedOnTrainer = async (batchId: number, trainerId: string) => {
    try {

        const { data, error }: any = await FetchData(TableNames.sessions, ["trainer:users(uid,first_name, last_name, email, phone_number)", "attendees", "duration", "batch:batches(id, course_id, branch_id)"])
        const newSession = data.filter((el:any) => (el.batch.id === batchId))
        const updatedData = newSession?.filter((el: any) => (el.trainer.uid === trainerId))
        
        const studentUids = updatedData?.map((id: any) => (id.attendees))
        
        const newArray = mergeArraysAndRemoveDuplicates(studentUids)
        
        const oldBatchSessions: any = data?.filter((el: any) => (el.batch.course_id === newSession[0]?.batch.course_id && el.batch.branch_id === newSession[0]?.batch.branch_id && el.batch.id !== batchId))
        
        const sessionData = data.filter((el: any) => (el.batch.id === oldBatchSessions[0]?.batch?.id))
        // console.log(data, "data", newSession, "newSession", updatedData, "updatedData", studentUids, "studentUids", newArray, "newArray", oldBatchSessions, "oldBatchSessions", sessionData, "sessionData");

        const {data: training_data, error: training_error} = await FetchData(TableNames.trainings, ["student:students(uid, name, phoneNumber)", "payment_details", "total_fee", "status", "trainers_ids", "batch:batches(session:sessions(*), duration)"]).in("student_id", newArray).eq("batch_id", batchId).or("status.eq.Allocated, status.eq.Dropped, status.eq.Hold")
        // console.log(training_data, "training_data")
        const student_data: any = training_data?.map((el: any) => {

            const totalPaidAmount = el.payment_details.reduce((acc: number, curr_val: any) => acc + Number(curr_val.paid_amount), 0);

            const paidAmountPercentage = (totalPaidAmount * 18) / 100;

            const totalFee = el.total_fee;

            const paidAmount = paidAmountPercentage + totalPaidAmount;

            const balanceDue = totalFee - paidAmount;    

            const buffer_hours = sessionData?.filter((ele: any) => ele.attendees.includes(el.student.uid))
            .reduce((acc: number, curr_val: any) => acc + Number(curr_val.duration), 0);

            const hours_attended = newSession?.filter((ele: any) => ele.attendees.includes(el.student.uid))
                .reduce((acc: number, curr_val: any) => acc + Number(curr_val.duration), 0);

            return {

            ...el,

            hours_attended,

            buffer_hours,

            total_fee: totalFee,

            paid_amount: paidAmount,

            balance_due: balanceDue,
            
            students_based_on_trainer: trainerId

        };

        })
        // console.log(newSession, "newSession");
        const new_student_data = countStudentsBasedOnStatus(student_data)

        if (error) throw error

        return new_student_data

        } catch (error) {
            return errorNotifier(error)
        }
}

export const getCompletedBatches = async (UsersDetails: any) => {
    try {
        const { data: batches, error }: any = await FetchData(TableNames.batches, ["id", "branch_id", "course:courses(course_name)", "schedule", "status", "start_date", "start_time", "branch:branches(branch_name, ownership)", "city:cities(city_name)", "user:users(first_name, last_name, phone_number)","duration", "batch_code", "batch_progress_in_hrs", "training:trainings(student:students(id,name,phoneNumber),id)"]).in("branch_id", UsersDetails?.branches).or("status.eq.Completed, status.eq.Review, status.eq.Dropped")
        
         const batchData = batches?.map((batch: any) => ({
            ...batch,
            batch_progress_in_percent: calculatePercentage(batch?.batch_progress_in_hrs, batch?.duration),
            course_name: batch.course.course_name, 
            trainer_name: batch.user===null?"":(batch.user.first_name + batch.user.last_name), 
            trainer_mobile: batch.user===null?"":(batch.user.phone_number), 
            batch_start_date: dateFormat(batch.start_date, "DD MMM YYYY"), 
            branch: batch.branch.branch_name, 
            ownership: batch.branch.ownership, 
            city: batch.city.city_name,
            student_list: batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.name),
            student_Phone_Numbers: batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.phoneNumber)
         }))
        
        if (error) throw error
        return batchData
    }
    catch(error) {  
        return errorNotifier(error)
    }
}

export const matchBatchCode = async(batchcode:string) => {
    try {
        const { data, error }:any = await FetchData(TableNames.batches, ["id", "batch_code"]).eq('batch_code', batchcode);
        if(error) throw error
        const matchData = data?data[0]?.id:data
        return matchData
    } catch (error) {
        return errorNotifier(error);
    }
}

export const updateBatchStatus = async (status:string, batchId:number) => {
    try {
        const { data, error }: any = await UpdateData(TableNames.batches, { status: status }, { conditionKey: "id", conditionValue: batchId })
        if (error) throw error;
        return true
    } catch (error) {
        return errorNotifier(error);
    }
}
