import React from "react";
import { CardDesign } from "./CardDesign";
import { Doughnut } from "react-chartjs-2";

export const Operations = ({ DashboardData }: any) => {
  const dougnetoptions = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  const dougnetdata = {
    labels: (DashboardData[0].dataOp || []).map((d: any) => d.name),
    datasets: [
      {
        // label: "Count",
        backgroundColor: (DashboardData[0].dataOp || []).map(
          (d: any) => d.fill
        ),
        data: (DashboardData[0].dataOp || []).map((d: any) => d.value),
      },
    ],
  };

  return (
    <div className="w-full">
      <div className="bg-[#F9FCFF] w-full shadow-[#00000029_0px_3px_8px] rounded-[8px]">
        {DashboardData.map((item: any, index: number) => (
          <div key={index}>
            {/* Title */}
            <h1 className="font-Roboto font-[500] text-[18px] md:text-[24px] py-4 px-4">
              {item.operationTitle}
            </h1>

            {/* Responsive Layout */}
            <div className="flex flex-col md:flex-row items-center gap-4 p-4 bg-white">
              {/* Left Section: Doughnut Chart */}
              <div className="w-full md:w-[30%] flex items-center justify-center">
                <div className="w-8/12 mx-auto py-2">
                  <Doughnut
                    options={dougnetoptions}
                    data={dougnetdata}
                    width={"50px"}
                  />
                </div>
              </div>

              {/* Right Section: Cards Grid */}
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-2 w-full md:w-[70%]">
                {item.operations.map((data: any, cardIndex: number) => (
                  <CardDesign key={cardIndex} data={data} />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
