export const renderContent = (
  selectedTab: string,
  response: any,
  body_style: string,
  searchQuery: string
) => {
  const lowerCaseSearchQuery = searchQuery.toLowerCase();

  switch (selectedTab) {
    case "Admission Report":
      const groupedData = response?.admissions?.reduce(
        (acc: any, curr: any) => {
          const branchId = curr.branch_name || 0;

          if (!acc[branchId]) {
            acc[branchId] = {
              allStudents: 0,
              paidStudents: 0,
              freeStudents: 0,
              appStudents: 0,
            };
          }

          acc[branchId].allStudents++;
          if (curr.type === "Paid Student") {
            acc[branchId].paidStudents++;
          } else if (curr.type === "Free Student") {
            acc[branchId].freeStudents++;
          } else if (curr.type === "App Student") {
            acc[branchId].appStudents++;
          }

          return acc;
        },
        {}
      );

      let totalAllStudents = 0;
      let totalPaidStudents = 0;
      let totalFreeStudents = 0;
      let totalAppStudents = 0;

      // CSS Styling
      const AtableBodyStyle = `${body_style} text-sm`;
      const AtotalsStyle = `font-bold ${body_style} text-sm`;

      const content = Object.entries(groupedData || {})
        .filter(([branch]) =>
          branch.toLowerCase().includes(lowerCaseSearchQuery)
        )
        .map(([branch, counts]: any, index: number) => {
          totalAllStudents += counts.allStudents;
          totalPaidStudents += counts.paidStudents;
          totalFreeStudents += counts.freeStudents;
          totalAppStudents += counts.appStudents;

          return (
            <tr key={branch} className="px-10">
              <td className={`${AtableBodyStyle}`}>{index + 1}.</td>
              <td className={`${AtableBodyStyle}`}>{branch}</td>
              <td className={`${AtableBodyStyle}`}>{counts.allStudents}</td>
              <td className={`${AtableBodyStyle}`}>{counts.paidStudents}</td>
              <td className={`${AtableBodyStyle}`}>{counts.freeStudents}</td>
              <td className={`${AtableBodyStyle}`}>{counts.appStudents}</td>
            </tr>
          );
        });

      // Add totals row
      content.push(
        <tr key="totals" className="px-10 font-bold">
          <td className={`${AtotalsStyle}`}></td>
          <td className={`${AtotalsStyle}`}>Total</td>
          <td className={`${AtotalsStyle}`}>{totalAllStudents}</td>
          <td className={`${AtotalsStyle}`}>{totalPaidStudents}</td>
          <td className={`${AtotalsStyle}`}>{totalFreeStudents}</td>
          <td className={`${AtotalsStyle}`}>{totalAppStudents}</td>
        </tr>
      );

      return content;

    case "Batch":
      const batchData = response.batch || {};
      let totalOngoing = 0;
      let totalUpcoming = 0;
      let totalReview = 0;
      let totalCompleted = 0;
      let totalHold = 0;
      let totalDrop = 0;

      const BtableBodyStyle = `${body_style} text-sm`;
      const BtotalsStyle = `font-bold ${body_style} text-sm`;

      const batchContent = Object.entries(batchData || {})
        .filter(([branch]) =>
          branch.toLowerCase().includes(lowerCaseSearchQuery)
        )
        .map(([branch, counts]: any, index: number) => {
          totalOngoing += counts.Ongoing || 0;
          totalUpcoming += counts.Upcoming || 0;
          totalReview += counts.Review || 0;
          totalCompleted += counts.Completed || 0;
          totalHold += counts.Hold || 0;
          totalDrop += counts.Drop || 0;

          return (
            <tr key={branch} className="px-10">
              <td className={`${BtableBodyStyle}`}>{index + 1}.</td>
              <td className={`${BtableBodyStyle}`}>{branch}</td>
              <td className={`${BtableBodyStyle}`}>{counts.Ongoing}</td>
              <td className={`${BtableBodyStyle}`}>{counts.Upcoming}</td>
              <td className={`${BtableBodyStyle}`}>{counts.Review}</td>
              <td className={`${BtableBodyStyle}`}>{counts.Completed}</td>
              <td className={`${BtableBodyStyle}`}>{counts.Hold}</td>
              <td className={`${BtableBodyStyle}`}>{counts.Drop}</td>
            </tr>
          );
        });

      // Add totals row
      batchContent.push(
        <tr key="totals" className="px-10 font-bold">
          <td className={`${BtotalsStyle}`}></td>
          <td className={`${BtotalsStyle}`}>Total</td>
          <td className={`${BtotalsStyle}`}>{totalOngoing}</td>
          <td className={`${BtotalsStyle}`}>{totalUpcoming}</td>
          <td className={`${BtotalsStyle}`}>{totalReview}</td>
          <td className={`${BtotalsStyle}`}>{totalCompleted}</td>
          <td className={`${BtotalsStyle}`}>{totalHold}</td>
          <td className={`${BtotalsStyle}`}>{totalDrop}</td>
        </tr>
      );

      return batchContent;

    case "Feedback/Concern":
      const feedbackData = response.studentConcern_feedback || {};
      let totalOpen = 0;
      let totalInprogress = 0;
      let totalReOpen = 0;
      let totalResolved = 0;

      //CSS Styling
      const FtableBodyStyle = `${body_style} text-sm`;
      const FtotalsStyle = `font-bold ${body_style} text-sm`;

      const feedbackContent = Object.entries(feedbackData)
        .filter(([branch]) =>
          branch.toLowerCase().includes(lowerCaseSearchQuery)
        )
        .map(([branch, counts]: any, index: number) => {
          // Aggregate totals
          totalOpen += counts.Open || 0;
          totalInprogress += counts.Inprogress || 0;
          totalReOpen += counts.ReOpen || 0;
          totalResolved += counts.Resolved || 0;

          return (
            <tr key={branch} className="px-10">
              <td className={`${FtableBodyStyle}`}>{index + 1}.</td>
              <td className={`${FtableBodyStyle}`}>{branch}</td>
              <td className={`${FtableBodyStyle}`}>{counts.Open || 0}</td>
              <td className={`${FtableBodyStyle}`}>{counts.Inprogress || 0}</td>
              <td className={`${FtableBodyStyle}`}>{counts.ReOpen || 0}</td>
              <td className={`${FtableBodyStyle}`}>{counts.Resolved || 0}</td>
            </tr>
          );
        });

      // Add totals row
      feedbackContent.push(
        <tr key="totals" className="px-10 font-bold">
          <td className={`${FtotalsStyle}`}></td>
          <td className={`${FtotalsStyle}`}>Total</td>
          <td className={`${FtotalsStyle}`}>{totalOpen}</td>
          <td className={`${FtotalsStyle}`}>{totalInprogress}</td>
          <td className={`${FtotalsStyle}`}>{totalReOpen}</td>
          <td className={`${FtotalsStyle}`}>{totalResolved}</td>
        </tr>
      );

      return feedbackContent;

    case "Operations":
      const operationsData = response.operations || {};
      let totalUnallocated = 0;
      let totalHoldOp = 0;
      let totalDropOp = 0;
      let totalAllocated = 0;
      let totalCompletedOp = 0;

      // CSS Styling
      const opBodyStyle = `${body_style} text-sm`;
      const optotalsStyle = `font-bold ${body_style} text-sm`;

      const operationsContent = Object.entries(operationsData || {})
        .filter(([branch]) =>
          branch.toLowerCase().includes(lowerCaseSearchQuery)
        )
        .map(([branch, counts]: any, index: number) => {
          totalUnallocated += counts.Unallocated || 0;
          totalHoldOp += counts.Hold || 0;
          totalDropOp += counts.Drop || 0;
          totalAllocated += counts.Allocated || 0;
          totalCompletedOp += counts.Completed || 0;

          return (
            <tr key={branch} className="px-10">
              <td className={`${opBodyStyle}`}>{index + 1}.</td>
              <td className={`${opBodyStyle}`}>{branch}</td>
              <td className={`${opBodyStyle}`}>{counts.Unallocated || 0}</td>
              <td className={`${opBodyStyle}`}>{counts.Hold || 0}</td>
              <td className={`${opBodyStyle}`}>{counts.Drop || 0}</td>
              <td className={`${opBodyStyle}`}>{counts.Allocated || 0}</td>
              <td className={`${opBodyStyle}`}>{counts.Completed || 0}</td>
            </tr>
          );
        });

      // Add totals row
      operationsContent.push(
        <tr key="totals" className="px-10 font-bold">
          <td className={`${optotalsStyle}`}></td>
          <td className={`${optotalsStyle}`}>Total</td>
          <td className={`${optotalsStyle}`}>{totalUnallocated}</td>
          <td className={`${optotalsStyle}`}>{totalHoldOp}</td>
          <td className={`${optotalsStyle}`}>{totalDropOp}</td>
          <td className={`${optotalsStyle}`}>{totalAllocated}</td>
          <td className={`${optotalsStyle}`}>{totalCompletedOp}</td>
        </tr>
      );

      return operationsContent;

    case "Revenue Vs Proforma Invoice":
      const revenueData = response.revenueVsproforma || {};

      let totalClassroomRevenue = 0;
      let totalClassroomInvoice = 0;
      let totalOnlineRevenue = 0;
      let totalOnlineInvoice = 0;
      let totalHybridRevenue = 0;
      let totalHybridInvoice = 0;
      let totalRevenue = 0;
      let totalInvoice = 0;
      let totalPendingRevenue = 0;

      // Function to format amounts with commas
      const formatAmount = (amount: number) => amount.toLocaleString("en-IN");

      // CSS Styling
      const bodyStyle = `${body_style} text-sm`;
      const totalStyle = `font-bold ${body_style} text-sm`;

      const revenueContent = Object.entries(revenueData || {})
        .filter(([branch]) =>
          branch.toLowerCase().includes(lowerCaseSearchQuery)
        )
        .map(([branch, counts]: any, index: number) => {
          totalClassroomRevenue += counts.classroom_revenue || 0;
          totalClassroomInvoice += counts.classroom_invoice || 0;
          totalOnlineRevenue += counts.online_revenue || 0;
          totalOnlineInvoice += counts.online_invoice || 0;
          totalHybridRevenue += counts.hybrid_revenue || 0;
          totalHybridInvoice += counts.hybrid_invoice || 0;
          totalRevenue += counts.total_revenue || 0;
          totalInvoice += counts.total_invoice || 0;
          totalPendingRevenue += counts.pending_revenue || 0;

          return (
            <tr key={branch} className="px-10">
              <td className={bodyStyle}>{index + 1}.</td>
              <td className={bodyStyle}>{branch}</td>
              <td className={bodyStyle}>
                ₹ {formatAmount(counts.classroom_revenue || 0)} /
                <br />₹ {formatAmount(counts.classroom_invoice || 0)}
              </td>
              <td className={bodyStyle}>
                ₹ {formatAmount(counts.online_revenue || 0)} /
                <br />₹ {formatAmount(counts.online_invoice || 0)}
              </td>
              <td className={bodyStyle}>
                ₹ {formatAmount(counts.hybrid_revenue || 0)} /
                <br />₹ {formatAmount(counts.hybrid_invoice || 0)}
              </td>
              <td className={bodyStyle}>
                ₹ {formatAmount(counts.total_revenue || 0)} /
                <br />₹ {formatAmount(counts.total_invoice || 0)}
              </td>
              <td className={bodyStyle}>
                ₹ {formatAmount(counts.pending_revenue || 0)}
              </td>
            </tr>
          );
        });

      // Add totals row
      revenueContent.push(
        <tr key="totals" className="px-10 font-bold">
          <td className={totalStyle}></td>
          <td className={totalStyle}>Total</td>
          <td className={totalStyle}>
            ₹ {formatAmount(totalClassroomRevenue)} /
            <br />₹ {formatAmount(totalClassroomInvoice)}
          </td>
          <td className={totalStyle}>
            ₹ {formatAmount(totalOnlineRevenue)} /
            <br />₹ {formatAmount(totalOnlineInvoice)}
          </td>
          <td className={totalStyle}>
            ₹ {formatAmount(totalHybridRevenue)} /
            <br />₹ {formatAmount(totalHybridInvoice)}
          </td>
          <td className={totalStyle}>
            ₹ {formatAmount(totalRevenue)} /
            <br />₹ {formatAmount(totalInvoice)}
          </td>
          <td className={totalStyle}>₹ {formatAmount(totalPendingRevenue)}</td>
        </tr>
      );

      return revenueContent;

    case "Session Feedback":
      const sessionData = response.session_feedback || {};
      let totalPoor = 0;
      let totalAverage = 0;
      let totalGood = 0;
      let totalExcellent = 0;
      let totalAbsent = 0;

      // Log sessionData to debug
      console.log("Session Data:", sessionData);

      // CSS Styling
      const tableBodyStyle = `${body_style} text-sm`;
      const totalsStyle = `font-bold ${body_style} text-sm`; // Apply font bold and smaller text size for totals

      const sessionContent = Object.entries(sessionData)
        .filter(([branch]) =>
          branch.toLowerCase().includes(lowerCaseSearchQuery)
        )
        .map(([branch, counts]: any, index: number) => {
          // Aggregate totals
          totalPoor += counts.Poor || 0;
          totalAverage += counts.Average || 0;
          totalGood += counts.Good || 0;
          totalExcellent += counts.Excellent || 0;
          totalAbsent += counts.Absent || 0;

          return (
            <tr key={branch} className="px-10">
              <td className={tableBodyStyle}>{index + 1}.</td>
              <td className={tableBodyStyle}>{branch}</td>
              <td className={tableBodyStyle}>{counts.Poor || 0}</td>
              <td className={tableBodyStyle}>{counts.Average || 0}</td>
              <td className={tableBodyStyle}>{counts.Good || 0}</td>
              <td className={tableBodyStyle}>{counts.Excellent || 0}</td>
              <td className={tableBodyStyle}>{counts.Absent || 0}</td>
            </tr>
          );
        });

      // Add totals row
      sessionContent.push(
        <tr key="totals" className="px-10">
          <td className={totalsStyle}></td>
          <td className={totalsStyle}>Total</td>
          <td className={totalsStyle}>{totalPoor}</td>
          <td className={totalsStyle}>{totalAverage}</td>
          <td className={totalsStyle}>{totalGood}</td>
          <td className={totalsStyle}>{totalExcellent}</td>
          <td className={totalsStyle}>{totalAbsent}</td>
        </tr>
      );

      return sessionContent;

    case "Missed Revenue":
      const allData = response.missed_revenue || {};
      let refundCount = 0;
      let refundAmount = 0;
      let nullifyCount = 0;
      let nullifyAmount = 0;
      let dropCount = 0;
      let dropAmount = 0;
      let totalMissedRevenue = 0;

      // CSS Styling
      const mrBodyStyle = `${body_style} text-sm`;
      const mrtotalsStyle = `font-bold ${body_style} text-sm`; // Apply font bold and smaller text size for totals

      const missedRevenueContent = Object.entries(allData)
        .filter(([branch]) =>
          branch.toLowerCase().includes(lowerCaseSearchQuery)
        )
        .map(([branch, counts]: any, index: number) => {
          // Aggregate totals
          refundCount += counts.refund_count || 0;
          refundAmount += counts.refund_amount || 0;
          nullifyCount += counts.nullify_count || 0;
          nullifyAmount += counts.nullify_amount || 0;
          dropCount += counts.drop_count || 0;
          dropAmount += counts.drop_amount || 0;
          totalMissedRevenue += counts.total_missed_revenue || 0;

          return (
            <tr key={branch} className="px-10">
              <td className={mrBodyStyle}>{index + 1}.</td>
              <td className={mrBodyStyle}>{branch}</td>
              <td className={mrBodyStyle}>{counts.refund_count || 0}</td>
              <td className={mrBodyStyle}>{counts.refund_amount || 0}</td>
              <td className={mrBodyStyle}>{counts.nullify_count || 0}</td>
              <td className={mrBodyStyle}>{counts.nullify_amount || 0}</td>
              <td className={mrBodyStyle}>{counts.drop_count || 0}</td>
              <td className={mrBodyStyle}>{counts.drop_amount || 0}</td>
              <td className={mrBodyStyle}>
                {counts.total_missed_revenue || 0}
              </td>
            </tr>
          );
        });

      // Add totals row
      missedRevenueContent.push(
        <tr key="totals" className="px-10">
          <td className={mrtotalsStyle}></td>
          <td className={mrtotalsStyle}>Total</td>
          <td className={mrtotalsStyle}>{refundCount}</td>
          <td className={mrtotalsStyle}>{refundAmount}</td>
          <td className={mrtotalsStyle}>{nullifyCount}</td>
          <td className={mrtotalsStyle}>{nullifyAmount}</td>
          <td className={mrtotalsStyle}>{dropCount}</td>
          <td className={mrtotalsStyle}>{dropAmount}</td>
          <td className={mrtotalsStyle}>{totalMissedRevenue}</td>
        </tr>
      );

      return missedRevenueContent;

    case "Placement":
      const placementsData = response.placements || {};
      let total_companies = 0;
      let jobs = 0;
      let openings = 0;
      let applied = 0;
      let shortlisted = 0;
      let placed = 0;
      let joined_company = 0;

      //   // CSS Styling
      const placementBodyStyle = `${body_style} text-sm`;
      const placementtotalsStyle = `font-bold ${body_style} text-sm`; // Apply font bold and smaller text size for totals

      const placementRevenueContent = Object.entries(placementsData)
        .filter(([branch]) =>
          branch.toLowerCase().includes(lowerCaseSearchQuery)
        )
        .map(([branch, counts]: any, index: number) => {
          // Aggregate totals
          total_companies += counts.total_companies || 0;
          jobs += counts.jobs || 0;
          openings += counts.openings || 0;
          applied += counts.applied || 0;
          shortlisted += counts.shortlisted || 0;
          placed += counts.placed || 0;
          joined_company += counts.joined_company || 0;

          return (
            <tr key={branch} className="px-10">
              <td className={placementBodyStyle}>{index + 1}.</td>
              <td className={placementBodyStyle}>{branch}</td>
              <td className={placementBodyStyle}>
                {counts.total_companies || 0}
              </td>
              <td className={placementBodyStyle}>{counts.jobs || 0}</td>
              <td className={placementBodyStyle}>{counts.openings || 0}</td>
              <td className={placementBodyStyle}>{counts.applied || 0}</td>
              <td className={placementBodyStyle}>{counts.shortlisted || 0}</td>
              <td className={placementBodyStyle}>{counts.placed || 0}</td>
              <td className={placementBodyStyle}>
                {counts.joined_company || 0}
              </td>
            </tr>
          );
        });

      // Add totals row
      placementRevenueContent.push(
        <tr key="totals" className="px-10">
          <td className={placementtotalsStyle}></td>
          <td className={placementtotalsStyle}>Total</td>
          <td className={placementtotalsStyle}>{total_companies}</td>
          <td className={placementtotalsStyle}>{jobs}</td>
          <td className={placementtotalsStyle}>{openings}</td>
          <td className={placementtotalsStyle}>{applied}</td>
          <td className={placementtotalsStyle}>{shortlisted}</td>
          <td className={placementtotalsStyle}>{placed}</td>
          <td className={placementtotalsStyle}>{joined_company}</td>
        </tr>
      );

      return placementRevenueContent;

    case "Deviation - Pending Action":
      const unAllocatedDeviationData = response?.deviation || {};
      let total_count = 0;
      let total_overdue = 0;
      let total_unallocated = 0;
      let total_batch_not_started = 0;
      let total_cancelled_sessions = 0;
      let total_pending_payment = 0;
      let total_student_feedback = 0;
      let total_session_feedback = 0;

      const deviationBodyStyle = `${body_style} text-sm`;
      const deviationTotalsStyle = `font-bold ${body_style} text-sm`;

      const deviationContent = Object.entries(unAllocatedDeviationData).map(
        ([_, counts]: any, index: number) => {
          total_count += counts.count || 0;
          total_overdue += counts.overdueCount || 0;
          total_unallocated += counts.unallocated || 0;
          total_batch_not_started += counts.batch_not_started || 0;
          total_cancelled_sessions += counts.cancelled_sessions || 0;
          total_pending_payment += counts.pending_payment || 0;
          total_student_feedback += counts.student_feedback || 0;
          total_session_feedback += counts.session_feedback || 0;

          return (
            <tr
              key={`${counts.branchName}_${counts.userName}`}
              className="px-10"
            >
              <td className={deviationBodyStyle}>{index + 1}.</td>
              <td className={deviationBodyStyle}>{counts.branchName}</td>
              <td className={`${deviationBodyStyle} ${'w-[12%]'}`}>{counts.userName}</td>
              <td className={deviationBodyStyle}>{counts.count || 0}</td>
              <td className={deviationBodyStyle}>{counts.overdueCount || 0}</td>
              <td className={deviationBodyStyle}>{counts.unallocated || 0}</td>
              <td className={deviationBodyStyle}>
                {counts.batch_not_started || 0}
              </td>
              <td className={deviationBodyStyle}>
                {counts.cancelled_sessions || 0}
              </td>
              <td className={deviationBodyStyle}>
                {counts.pending_payment || 0}
              </td>
              <td className={deviationBodyStyle}>
                {counts.student_feedback || 0}
              </td>
              <td className={deviationBodyStyle}>
                {counts.session_feedback || 0}
              </td>
            </tr>
          );
        }
      );

      deviationContent.push(
        <tr key="totals" className="px-10">
          <td className={deviationTotalsStyle}></td>
          <td className={deviationTotalsStyle}>Total</td>
          <td className={deviationTotalsStyle}></td>
          <td className={deviationTotalsStyle}>{total_count}</td>
          <td className={deviationTotalsStyle}>{total_overdue}</td>
          <td className={deviationTotalsStyle}>{total_unallocated}</td>
          <td className={deviationTotalsStyle}>{total_batch_not_started}</td>
          <td className={deviationTotalsStyle}>{total_cancelled_sessions}</td>
          <td className={deviationTotalsStyle}>{total_pending_payment}</td>
          <td className={deviationTotalsStyle}>{total_student_feedback}</td>
          <td className={deviationTotalsStyle}>{total_session_feedback}</td>
        </tr>
      );

      return deviationContent;

    default:
      return null;
  }
};
