import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { CardDesign } from "./CardDesign";

export const Placement = ({ DashboardData }: any) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
      },

      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  const placement = {
    labels: (DashboardData[0].placementChart || []).map((d: any) => d.name),
    datasets: [
      {
        label: "Count",
        backgroundColor: "#036AD1",
        data: (DashboardData[0].placementChart || []).map((d: any) => d.count),
      },
    ],
  };

  return (
    <div className="bg-[#F9FCFF] w-full shadow-md shadow-[#00000029_0px_3px_8px] rounded-[8px] p-4">
      {DashboardData.map((item: any, index: number) => (
        <div key={index}>
          {/* Title */}
          <h1 className="font-Roboto font-[500] text-[18px] md:text-[24px] bg-[#FBFBFB] py-4 px-4">
            {item.placementTitle}
          </h1>

          {/* Content */}
          <div className="p-4 space-y-4">
            {/* Bar Chart */}
            <div className="w-full md:w-11/12" style={{ height: "300px" }}>
              <Bar data={placement} options={options} />
            </div>

            {/* Cards Grid */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 w-full md:w-[94%]">
              {item.placement.map((data: any, cardIndex: number) => (
                <CardDesign key={cardIndex} data={data} />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
