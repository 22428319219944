import React, { useCallback, useEffect, useState } from 'react'
import { paginationConfig } from '../../../utils/config/elements';
import { filterWithMultipleRecords, getOptionsFromData, insertOptionsForFiltersFromAvailableData, makeArraySpecificKeyAsObject, filterBasedOnDateRange } from '../../../utils/functions/tableFilters';
import Pagination from './Pagination'
import Table from "./Table";
import TableFilters from './TableFilters';
import _ from 'lodash';
import { SummaryForDT } from '../../Action Center/Daily Transactions/SummaryForDT';
import { PaymentTotalsForPendingPayment } from '../../Action Center/Pending Payment/PaymentTotalsForPendingPayment';
import { toggleBulkUpdate } from '../../../Redux/Reducers/courseProgressModal';
import { toggleBulkUpdateCancellation } from '../../../Redux/Reducers/cancellationModal';
import { useAppDispatch } from '../../..';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';

interface TableComponentProps {
  // BackendFunctionCall: any,
  TABLE_JSON: any;
  indexStyle?: string;
  cellStyle?: string;
  cellActionHeaderStyle?: string;
  cellTableHeaderStyle?: string;
  wholeTableWidth?: string;
  backEndCallParams?: any;
  backEndCallParamsStatus?: any;
  allowTabs?:any;
}

export const TableComponent = ({
  TABLE_JSON,
  indexStyle,
  cellStyle,
  cellActionHeaderStyle,
  cellTableHeaderStyle,
  wholeTableWidth,
  backEndCallParams,
  backEndCallParamsStatus,
  allowTabs
}: TableComponentProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTableData, setTotalTableData] = useState(
    TABLE_JSON?.bodyData || []
  );
  const { id: paramsID }: any = useSharedLogic();
  const [tableData, setTableData] = useState(TABLE_JSON?.bodyData || []);
  const rowsPerPage = 10;
  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const currentPageData = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    (currentPage - 1) * rowsPerPage + rowsPerPage
  );
  const [checkBoxFilters, setCheckBoxFilters] = useState([]);
  const [dateRangeFilters, setDateRangeFilters] = useState<any>({});
  const [filters, setFilters] = useState({});
  const [searchFilters, setSearchFilters] = useState(
    makeArraySpecificKeyAsObject(TABLE_JSON?.filters?.searchBox || [])
  );
  const [isLoading, setIsLoading] = useState(false);
  const [changedFilterKey, setChangedFilterKey] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (TABLE_JSON.BackendFunctionCall) {
      (async () => {
        setIsLoading(true);
        const data = await TABLE_JSON.BackendFunctionCall(backEndCallParams, backEndCallParamsStatus);
        // console.log(data, "backendTableFUnction data");
        if (data) {
          setTotalTableData(data);
          setTableData(data);
        }
        setIsLoading(false);
      })();
    }
    // setState of table values
  }, []);

  useEffect(() => {
    let originalData = totalTableData;
    const { startDate, endDate, apiKey } = dateRangeFilters;
    if (!_.isEmpty(apiKey)) {
      originalData = filterBasedOnDateRange(startDate, endDate, totalTableData, apiKey);
    }
    const newCheckBoxFilters =  getOptionsFromData(TABLE_JSON?.filters?.checkBox || [], originalData, tableData, filters, checkBoxFilters, changedFilterKey, dateRangeFilters)
    // const checkBoxFilters = insertOptionsForFiltersFromAvailableData(
    //   TABLE_JSON?.filters?.checkBox || [],
    //   tableData,
    //   filters
    // );
    // console.log(newCheckBoxFilters, "newCheckBoxFilters 112")
    setCheckBoxFilters(newCheckBoxFilters);
  }, [totalTableData, tableData]);

  const filterData = (searchFilters: any = {}) => {
    let originalData = totalTableData;
    const { startDate, endDate, apiKey } = dateRangeFilters;
    if (!_.isEmpty(apiKey)) {
      originalData = filterBasedOnDateRange(startDate, endDate, totalTableData, apiKey);
    }
    const resultData = filterWithMultipleRecords(
      filters,
      originalData,
      searchFilters
    );
    setTableData(resultData);
    setCurrentPage(1);
  };

  const filterDataBasedOnDateRange = (startDate: any, endDate: any, apiKey: string) => {
    const dateFilteredData = filterBasedOnDateRange(startDate, endDate, totalTableData, apiKey);
    setDateRangeFilters(Object.assign(dateRangeFilters, {apiKey, startDate, endDate}))
    setTableData(dateFilteredData);
  };

  useEffect(() => {
    // set
    if (totalTableData.length) {
      filterData(searchFilters);
    }
  }, [JSON.stringify(filters)]);

  const paginatorFunc = useCallback(
    async (callerType: string) => {
      let pageForAPICall = 1;
      switch (callerType) {
        case paginationConfig.firstPage:
          pageForAPICall = 1;
          break;
        case paginationConfig.lastPage:
          pageForAPICall = totalPages;
          break;
        case paginationConfig.previousPage:
          pageForAPICall = currentPage - 1;
          break;
        default:
          pageForAPICall = currentPage + 1;
          break;
      }
      setCurrentPage(pageForAPICall);
    },
    [currentPage]
  );

  // Changes here
  // const resetFilter = () => {
  //   setTableData(totalTableData);
  //   setFilters({});
  // }

    const handlePageChange = useCallback(async (newPage: number) => {
      try {
          setCurrentPage(newPage);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    }, []);

  const renderComponent = (componentName:string) => {
    switch (componentName) {
      case 'SummaryForDT':
        return <SummaryForDT />;
      case 'PendingPaymentTotals':
        return <PaymentTotalsForPendingPayment bodyData={tableData} />;
      // Add other cases for different components if needed
      default:
        return null; // Handle the case where the component name is not recognized
    }
  };

  return (
    <div className={`flex flex-col ${wholeTableWidth ? wholeTableWidth : 'w-[96%]'} mx-auto`}>
      <TableFilters
        filters={filters}
        setFilters={setFilters}
        checkBoxFilter={checkBoxFilters}
        dateRangeFilters={TABLE_JSON?.filters?.dateFilters}
        filterBasedOnDateRange={filterDataBasedOnDateRange}
        searchFilterJSON={TABLE_JSON?.filters?.searchBox}
        key={"tableFilter"} 
        filterFunc={filterData}
        searchFilterState={{ searchFilters, setSearchFilters }}
        // resetFilter={resetFilter}
        setChangedFilterKey={setChangedFilterKey}
        tableData={totalTableData}
        allowTabs={allowTabs}
      />
      <div className="bg-white rounded-lg py-2 shadow-lg">
        {TABLE_JSON.component === 'bulkBatch' &&
          <div className='pl-2 py-4'>
            <button type='button' className='bg-blue-500 text-white px-2 py-2 rounded-md' onClick={() => dispatch(toggleBulkUpdate(true))}>Time Based Search Update</button>
          </div>}
        {TABLE_JSON.component === 'bulkCancellation' && (paramsID==="yet_to_speak" || paramsID === "continuous_two_days") &&
          <div className='pl-2 py-4'>
            <button type='button' className='bg-blue-500 text-white px-2 py-2 rounded-md' onClick={() => dispatch(toggleBulkUpdateCancellation(true))}>Bulk Cancel</button>
          </div>}
        <Table
          cellTableHeaderStyle={cellTableHeaderStyle}
          cellActionHeaderStyle={cellActionHeaderStyle}
          bodyData={currentPageData}
          headers={TABLE_JSON.headers}
          cellStyle={cellStyle || ""}
          currentPage={currentPage}
          indexStyle={indexStyle || ""}
          ActionComponent={TABLE_JSON.ActionComponent}
          isLoading={isLoading}
        />
        {TABLE_JSON.component && 
          <div className='py-10'>{renderComponent(TABLE_JSON.component)}</div>
        }
        {currentPageData.length ? (
          <Pagination
            totalPages={totalPages}
            totalItems={tableData.length}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
};


export const NoDataFound = () => {
    return (
        <div className='h-[30vh] flex justify-center items-center text-[#686868]'>
            No Records Found
        </div>
    )
}